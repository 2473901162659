import type { MutationHookOptions } from "@kv/apollo-client";
import { useMutation } from "@kv/apollo-client";
import { useMemo, useCallback, useRef } from "react";
import type {
  GraphqlNode,
  StandardMutationVars,
  MutationHookState,
  MutationFunc,
} from "~/types";



export type MutationWithStateTuple<
  MutationResultsData,
  MutationArgs extends unknown[],
> = [
  MutationFunc<MutationResultsData, MutationArgs>,
  MutationHookState<MutationResultsData>,
];

export interface MutationWithStateArgs<
  MutationResultsData,
  MutationVars,
  MutationArgs extends unknown[],
> {
  mutationNode: GraphqlNode<MutationResultsData, MutationVars>;
  options?: MutationHookOptions<MutationResultsData, MutationVars>;
  getMutationVars: (...args: MutationArgs) => MutationVars;
}

export const useMutationWithState = <
  MutationResultsData,
  MutationVars,
  MutationArgs extends unknown[],
>({
  mutationNode,
  options,
  getMutationVars,
}: MutationWithStateArgs<MutationResultsData, MutationVars, MutationArgs>) => {
  const state = useRef<MutationHookState<MutationResultsData>>({
    called: false,
    loading: false,
    data: undefined,
    error: undefined,
  });

  const [mutation] = useMutation<MutationResultsData, MutationVars>(
    mutationNode,
    {
      ...options,
      onCompleted: data => {
        state.current.data = data;
      },
      onError: error => {
        state.current.error = error;
      },
    },
  );

  const mutationWithState = useCallback(
    async (...args: MutationArgs) => {
      state.current.data = undefined;
      state.current.error = undefined;
      state.current.loading = true;
      state.current.called = true;

      try {
        await mutation({
          variables: getMutationVars(...args),
        });
      } finally {
        state.current.loading = false;
      }

      return state.current;
    },
    [mutation, getMutationVars],
  );

  const hookResult = useMemo<
    MutationWithStateTuple<MutationResultsData, MutationArgs>
  >(() => [mutationWithState, state.current], [mutationWithState]);

  return hookResult;
};

export const makeStandardMutationHook = <
  MutationResultsData,
  MutationInput = undefined,
>(
  mutationNode: GraphqlNode<
    MutationResultsData,
    StandardMutationVars<MutationInput>
  >,
) => {
  const useStandardMutation = (
    options?: MutationHookOptions<
      MutationResultsData,
      StandardMutationVars<MutationInput>
    >,
  ) => {
    const getMutationVars = useCallback(
      (input?: MutationInput) => ({ input }),
      [],
    );

    const mutationWithStateTuple = useMutationWithState<
      MutationResultsData,
      StandardMutationVars<MutationInput>,
      MutationInput extends undefined ? [] : [MutationInput]
    >({
      mutationNode,
      options,
      getMutationVars,
    });

    return mutationWithStateTuple;
  };

  return useStandardMutation;
};
