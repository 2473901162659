import {
  GetFiberPartBlendedCompositionMaterial,
  GetFiberPartBlendedCompositionMaterials,
  CreateFiberPartBlendedCompositionMaterial,
  UpdateFiberPartBlendedCompositionMaterial,
  DeleteFiberPartBlendedCompositionMaterial,
  CreateManyFiberPartBlendedCompositionMaterial,
  DeleteFiberPartBlendedCompositionMaterials,
} from "~/graphql";
import type {
  CreateFiberPartBlendedCompositionMaterialInput,
  CreateFiberPartBlendedCompositionMaterialMutationResultData,
  CreateManyFiberPartBlendedCompsoitionMaterialMutationResultData,
  DeleteFiberPartBlendedCompositionMaterialMutationResultData,
  DeleteFiberPartBlendedCompositionMaterialsMutationResultData,
  FiberPartBlendedCompositionMaterialQueryResultData,
  FiberPartBlendedCompositionMaterialsQueryResultData,
  UpdateFiberPartBlendedCompositionMaterialInput,
  UpdateFiberPartBlendedCompositionMaterialMutationResultData,
} from "~/types";

import {
  makeCreateEntityMutationHook,
  makeCreateManyEntityMutationHook,
  makeDeleteEntitiesMutationHook,
  makeDeleteEntityMutationHook,
  makeGetEntitiesQueryHook,
  makeGetEntityByIdQueryHook,
  makeUpdateEntityMutationHook } from "../utils";






export const useGetFiberPartBlendedCompositionMaterialQuery =
  makeGetEntityByIdQueryHook<
    FiberPartBlendedCompositionMaterialQueryResultData>(GetFiberPartBlendedCompositionMaterial);

export const useGetFiberPartBlendedCompositionMaterialsQuery =
  makeGetEntitiesQueryHook<
    FiberPartBlendedCompositionMaterialsQueryResultData>(GetFiberPartBlendedCompositionMaterials);


export const useCreateFiberPartBlendedCompositionMaterialMutation = makeCreateEntityMutationHook<
  CreateFiberPartBlendedCompositionMaterialMutationResultData,
  CreateFiberPartBlendedCompositionMaterialInput
>(CreateFiberPartBlendedCompositionMaterial);

export const useUpdateFiberPartBlendedCompositionMaterialMutation = makeUpdateEntityMutationHook<
  UpdateFiberPartBlendedCompositionMaterialMutationResultData,
  UpdateFiberPartBlendedCompositionMaterialInput
>(UpdateFiberPartBlendedCompositionMaterial);

export const useDeleteFiberPartBlendedCompositionMaterialMutation =
  makeDeleteEntityMutationHook<DeleteFiberPartBlendedCompositionMaterialMutationResultData>(
    DeleteFiberPartBlendedCompositionMaterial,
  );

export const useDeleteFiberPartBlendedCompositionMaterialsMutation =
makeDeleteEntitiesMutationHook<DeleteFiberPartBlendedCompositionMaterialsMutationResultData>(
  DeleteFiberPartBlendedCompositionMaterials);




export const useCreateManyFiberPartBlendedCompositionMaterialMutation =
  makeCreateManyEntityMutationHook<CreateManyFiberPartBlendedCompsoitionMaterialMutationResultData,
  CreateFiberPartBlendedCompositionMaterialInput>(CreateManyFiberPartBlendedCompositionMaterial);











