import { mapValues } from "lodash";
import type { Moment } from "moment";
import moment from "moment";



export function momentToString(value: Moment): string {
  if (moment.isMoment(value)) { return value.toISOString().split("T")[0]; }

  return value;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function changeMomentsToDateStrings(item: any): any {
  if (item === null) {
    return null;
  }

  let newItem;
  switch (typeof item) {
    case "object":
      newItem = Array.isArray(item)
        ? item.map(insideItem => changeDateStringsToMoment(insideItem))
        : // eslint-disable-next-line unicorn/no-nested-ternary
        moment.isMoment(item)
          ? momentToString(item)
          : mapValues(item, value => changeDateStringsToMoment(value));
      break;
    default:
      newItem = item;
      break;
  }

  return newItem;
}

const DATE_REGEX = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3,6}Z?$/;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function changeDateStringsToMoment(item: any): any {
  if (item === null) {
    return null;
  }

  let newItem;
  switch (typeof item) {
    case "string":
      newItem = DATE_REGEX.test(item) ? moment(item) : item;
      break;
    case "object":
      newItem = Array.isArray(item)
        ? item.map(insideItem => changeDateStringsToMoment(insideItem))
        : mapValues(item, value => changeDateStringsToMoment(value));
      break;
    default:
      newItem = item;
      break;
  }

  return newItem;
}
