import {
  GetResins,
  CreateResin,
  DeleteResin,
  UpdateResin,
  GetResin,
  GetResinsForDashboard,
  GetResinsForRRA,
  GetResinAllData,
} from "~/graphql";
import type {
  ResinsQueryResultData,
  CreateResinInput,
  CreateResinMutationResultData,
  DeleteResinMutationResultData,
  UpdateResinMutationResultData,
  UpdateResinInput,
  ResinQueryResultData,
  ResinsForDashboardQueryResultData,
  ResinsForRraQueryResultData,
  ResinAllDataQueryResultData,
} from "~/types";

import {
  makeCreateEntityMutationHook,
  makeGetEntitiesQueryHook,
  makeDeleteEntityMutationHook,
  makeUpdateEntityMutationHook,
  makeGetEntityByIdQueryHook,
} from "../utils";




export const useGetResinQuery =
  makeGetEntityByIdQueryHook<ResinQueryResultData>(GetResin);

export const useGetResinAllDataQuery =
  makeGetEntityByIdQueryHook<ResinAllDataQueryResultData>(GetResinAllData);

export const useGetResinsQuery =
  makeGetEntitiesQueryHook<ResinsQueryResultData>(GetResins);

export const useGetResinsForDashboardQuery =
  makeGetEntitiesQueryHook<ResinsForDashboardQueryResultData>(GetResinsForDashboard);

export const useGetResinsForRraQuery =
  makeGetEntitiesQueryHook<ResinsForRraQueryResultData>(GetResinsForRRA);

export const useCreateResinMutation = makeCreateEntityMutationHook<
  CreateResinMutationResultData,
  CreateResinInput
>(CreateResin);

export const useUpdateResinMutation = makeUpdateEntityMutationHook<
  UpdateResinMutationResultData,
  UpdateResinInput
>(UpdateResin);

export const useDeleteResinMutation =
  makeDeleteEntityMutationHook<DeleteResinMutationResultData>(DeleteResin);
