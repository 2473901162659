import { gql } from "@kv/apollo-client";



const PulpFiberTdsFragment = gql`
  fragment PulpFiberTdsFragment on PulpFiberTds {
      id
      pulpFiberId
      pulpFiber {
        tradeName
      }
      biomass
      biomassDetail
      bleachingMethod
      pulpingMethod
      grade
      pulpCharacteristics
      recyclability
      absorptionCapacity
      absorptionTime
      airPermeability
      airResistance
      apparentDensity
      ashContentWeightPercentage
      brightness
      bulk
      burstMullen
      coarseness
      cobb60
      density
      drainageResistance
      elongationCd
      elongationDryCd
      elongationDryMd
      elongationMd
      elongationWetCd
      elongationWetMd
      extractives
      fiberLength
      fiberPopulation
      fiberWidth
      freeness
      grammage
      lcaCarbonFootprint
      lightScatteringCoefficient
      moisture
      nominalBasisWeight
      permeabilityGurleyPorosity
      permeabilitySheffieldBottom
      permeabilitySheffieldTop
      receptivityCobb120Bottom
      receptivityCobb120Top
      refiningPfi
      shreddingEnergy
      stiffnessTaberCd
      stiffnessTaberMd
      teaCd
      teaMd
      teaIndexCd
      teaIndexMd
      tearIndex
      tearIndexCd
      tearIndexMd
      tearMd
      tearCd
      tearStrengthCd
      tearStrengthMd
      tensileIndex
      tensileIndexCd
      tensileIndexMd
      tensileIndexDryCd
      tensileIndexDryMd
      tensileIndexWetCd
      tensileIndexWetMd
      tensileStiffnessIndex
      tensileStrengthCd
      tensileStrengthMd
      tensileStrengthDryCd
      tensileStrengthDryMd
      tensileStrengthWetCd
      tensileStrengthWetMd
      thickness
      treated
      viscosity
      zdt
    }
`;

export const GetPulpFiberTds = gql`
  query GetPulpFiberTds($id: ID!) {
    pulpFiberTds(id: $id) {
      ...PulpFiberTdsFragment
    }
  }
  ${PulpFiberTdsFragment}
`;

export const GetPulpFiberTdses = gql`
  query GetPulpFiberTdses($filters: PulpFiberTdsFilter) {
    pulpFiberTdses(filters: $filters) {
      ...PulpFiberTdsFragment
    }
  }
  ${PulpFiberTdsFragment}
`;

export const CreatePulpFiberTds = gql`
  mutation CreatePulpFiberTds($input: PulpFiberTdsInput!) {
    createPulpFiberTds(input: $input) {
      ...PulpFiberTdsFragment
    }
  }
  ${PulpFiberTdsFragment}
`;

export const UpdatePulpFiberTds = gql`
  mutation UpdatePulpFiberTds($id: ID!, $input: PulpFiberTdsInput!) {
    updatePulpFiberTds(id: $id, input: $input) {
      ...PulpFiberTdsFragment
    }
  }
  ${PulpFiberTdsFragment}
`;

export const DeletePulpFiberTds = gql`
  mutation DeletePulpFiberTds($id: ID!) {
    deletePulpFiberTds(id: $id) {
      id
    }
  }
`;
