import { GetQuestionnaires } from "~/graphql";
import type { QuestionnairesQueryResultData } from "~/types";

import { makeGetEntitiesQueryHook } from "../utils";




export const useGetQuestionnairesQuery =
  makeGetEntitiesQueryHook<QuestionnairesQueryResultData>(GetQuestionnaires);
