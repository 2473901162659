import { gql } from "@kv/apollo-client";



const ResinDataPointFragment = gql`
  fragment ResinDataPointFragment on ResinDataPoint {
    id
    category
    name
    testMethod
    unit
    strValue
    value
    resinLotId
    resinFilmId
  }
`;

export const GetResinDataPoints = gql`
  query GetResinDataPoints($filters: ResinDataPointFilter) {
    resinDataPoints(filters: $filters) {
      ...ResinDataPointFragment
    }
  }
  ${ResinDataPointFragment}
`;

export const CreateResinDataPoint = gql`
  mutation CreateResinDataPoint($input: ResinDataPointInput!) {
    createResinDataPoint(input: $input) {
      ...ResinDataPointFragment
    }
  }
  ${ResinDataPointFragment}
`;

export const UpdateResinDataPoint = gql`
  mutation UpdateResinDataPoint($id: ID!, $input: ResinDataPointInput!) {
    updateResinDataPoint(id: $id, input: $input) {
      ...ResinDataPointFragment
    }
  }
  ${ResinDataPointFragment}
`;

export const DeleteResinDataPoint = gql`
  mutation DeleteResinDataPoint($id: ID!) {
    deleteResinDataPoint(id: $id) {
      id
    }
  }
`;
