import { gql } from "@kv/apollo-client";



export const FiberPartBlendedCompositionMaterialFragment = gql`
  fragment FiberPartBlendedCompositionMaterialFragment on FiberPartBlendedCompositionMaterial {
    id
    createdAt
    compositionId
    materialType
    materialReferenceNumber
    percentComposition
    basisWeight
    lotNumber
    supplier
    tradeName
  }
`;

export const GetFiberPartBlendedCompositionMaterial = gql`
  query GetFiberPartBlendedCompositionMaterial($id: ID!) {
    fiberPartBlendedCompositionMaterial(id: $id) {
      ...FiberPartBlendedCompositionMaterialFragment
    }
  }
  ${FiberPartBlendedCompositionMaterialFragment}
`;

export const GetFiberPartBlendedCompositionMaterials = gql`
  query GetFiberPartBlendedCompositionMaterials(
    $filters: FiberPartBlendedCompositionMaterialFilter
    $sort: [String]
    $pagination: PaginationInput
  ) {
    fiberPartBlendedCompositionMaterials(filters: $filters, sort: $sort, pagination: $pagination) {
      ...FiberPartBlendedCompositionMaterialFragment
    }
    fiberPartBlendedCompositionMaterialsCount(filters: $filters)
  }
  ${FiberPartBlendedCompositionMaterialFragment}
`;

export const CreateFiberPartBlendedCompositionMaterial = gql`
  mutation CreateFiberPartBlendedCompositionMaterial($input: FiberPartBlendedCompositionMaterialInput!) {
    createFiberPartBlendedCompositionMaterial(input: $input) {
        ...FiberPartBlendedCompositionMaterialFragment
    }
  }
  ${FiberPartBlendedCompositionMaterialFragment}
`;

export const CreateManyFiberPartBlendedCompositionMaterial = gql`
  mutation CreateManyFiberPartBlendedCompositionMaterial($input: [FiberPartBlendedCompositionMaterialInput!]!) {
    createManyFiberPartBlendedCompositionMaterial(input: $input) {
      output{
        ...FiberPartBlendedCompositionMaterialFragment
      }
    }
  }
  ${FiberPartBlendedCompositionMaterialFragment}
`;


export const UpdateFiberPartBlendedCompositionMaterial = gql`
  mutation UpdateFiberPartBlendedCompositionMaterial($id: ID!, $input: FiberPartBlendedCompositionMaterialInput!) {
    updateFiberPartBlendedCompositionMaterial(id: $id, input: $input) {
      ...FiberPartBlendedCompositionMaterialFragment
    }
  }
  ${FiberPartBlendedCompositionMaterialFragment}
`;

export const DeleteFiberPartBlendedCompositionMaterial = gql`
  mutation DeleteFiberPartBlendedCompositionMaterial($id: ID!) {
    deleteFiberPartBlendedCompositionMaterial(id: $id) {
      id
    }
  }
`;

export const DeleteFiberPartBlendedCompositionMaterials = gql`
  mutation DeleteFiberPartBlendedCompositionMaterials($ids: [ID!]!){
    deleteFiberPartBlendedCompositionMaterials(ids: $ids){
      ids
    }
  }
`;
