import { gql } from "@kv/apollo-client";



const QuestionnaireFragment = gql`
  fragment QuestionnaireFragment on Questionnaire {
    id
    questionnaireItems {
      id
      section
      questionNumber
      questionLetter
      questionValue
      answerValue
      mainQuestionValue
      score
      weight
      weightedScore
    }
  }
`;

export const GetQuestionnaires = gql`
  query GetQuestionnaires($filters: QuestionnaireFilter) {
    questionnaires(filters: $filters) {
      ...QuestionnaireFragment
    }
  }
  ${QuestionnaireFragment}
`;
