/* eslint-disable @typescript-eslint/no-magic-numbers */

export const calculateComparisonValue = (
  comparisonProperty: string,
  aValue: number,
  bValue: number,
) => {
  switch (comparisonProperty) {
    case "fnct":
      return Math.min(bValue, 100);
    case "density":
      return Math.abs(aValue - bValue);

    case "dieSwell":
      const result = Math.abs(aValue - bValue) / Math.abs(aValue);

      return Number.isNaN(result) ? 0 : result;

    case "meltSag":
      return bValue;

    case "meltFractureStress":
      return aValue - bValue;

    case "mfr":
      return Math.abs(aValue - bValue);

    case "isBimodal":
      return (bValue + 1 === 2 ? 0 : 1) + (aValue + 1 === 2 ? 0 : 1) === 2
        ? 0
        : 1;
  }
};

export const calculateLevel = (
  comparisonProperty: string,
  aValue: number,
  bValue: number,
) => {
  const comparedToValue = bValue;
  let comparisonResult;
  switch (comparisonProperty) {
    case "fnct":
      comparisonResult = Math.min(bValue, 100);
      if (comparisonResult >= 50) { return "Low"; } else if (comparisonResult >= 21 && comparisonResult < 50) { return "Medium"; } else { return "High"; }

    case "density":
      comparisonResult = Math.abs(aValue - bValue);
      if (
        comparisonResult <= 0.004 &&
        comparedToValue > 0.94 &&
        comparedToValue < 0.962
      ) { return "Low"; } else if (
        comparisonResult > 0.004 &&
        comparedToValue > 0.94 &&
        comparedToValue < 0.962
      ) { return "Medium"; } else { return "High"; }

    case "dieSwell":
      comparisonResult = Math.abs(aValue - bValue) / Math.abs(aValue);
      if (comparisonResult <= 0.07) { return "Low"; } else if (comparisonResult > 0.07 && comparisonResult <= 0.15) { return "Medium"; } else { return "High"; }

    case "meltSag":
      comparisonResult = bValue;
      if (comparisonResult > -0.0026 && comparisonResult <= -0.0016) { return "Low"; } else if (comparisonResult > -0.0035 && comparisonResult <= -0.0026) { return "Medium"; } else { return "High"; }

    case "meltFractureStress":
      comparisonResult = aValue - bValue;
      if (comparisonResult <= 0) { return "Low"; } else if (comparisonResult > 0 && comparisonResult <= 500) { return "Medium"; } else { return "High"; }

    case "mfr":
      comparisonResult = Math.abs(aValue - bValue);
      if (
        comparisonResult <= 0.25 &&
        comparedToValue > 0.1 &&
        comparedToValue < 0.752
      ) { return "Low"; } else if (
        comparisonResult > 0.25 &&
        comparisonResult <= 0.5 &&
        comparedToValue > 0.1 &&
        comparedToValue < 0.752
      ) { return "Medium"; } else { return "High"; }

    case "isBimodal":
      comparisonResult =
        (bValue + 1 === 2 ? 0 : 1) + (aValue + 1 === 2 ? 0 : 1) === 2 ? 0 : 1;

      return comparisonResult <= 0 ? "Low" : "High";
  }
};

export const calculateOverallResult = (data: number[]): number => {
  const [fnct, density, die_swell, meltSag, melt_fracture, mfr, modal] = data;

  // Die Swell Squared Term
  const dieSwell2 = (die_swell - 0.147_24) * (die_swell - 0.147_24);

  // FNCT x Unimodal Term
  const fnctModal = (fnct - 43.8276) * (modal - 0.103_45);

  // Ordinal Logisitc Prediction
  const ordinal =
    -0.118_284_9 * fnct +
    1334.841_62 * density +
    0.055_810_24 * (die_swell * 100) +
    1024.704_15 * meltSag +
    -0.005_015_1 * melt_fracture +
    -43.484_246 * mfr +
    2.391_818_29 * modal +
    53.380_873_2 * dieSwell2 +
    -0.033_390_1 * fnctModal;

  // Risk Rating (0 - 100)
  return Math.round(ordinal * 3.4483 + 55.1742);
};
