/* eslint-disable @typescript-eslint/indent */
export const buildOptionsFromLabels = (
  labels: string | string[],
  caseFunc?: (val: string) => string,
) => Array.isArray(labels)
    ? labels.map(val => ({
      key: val,
      value: caseFunc ? caseFunc(val) : val,
      label: val,
    }))
    : [{
      key: labels,
      value: caseFunc ? caseFunc(labels) : labels,
      label: labels,
    }];

