import type { DependencyList } from "react";
import { useCallback, useEffect } from "react";



export type UseBeforeUnloadFunction = (
  func: (e: BeforeUnloadEvent) => void,
  deps: DependencyList,
) => void;
export type UseUnloadFunction = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  func: (e: any) => void,
  deps: DependencyList,
) => void;

export const useBeforeUnload: UseBeforeUnloadFunction = (func, deps) => {
  const cb = useCallback(func, [func, ...deps]);

  useEffect(() => {
    window.addEventListener("beforeunload", cb, { capture: true });

    return () => {
      window.removeEventListener("beforeunload", cb, { capture: true });
    };
  }, [cb]);
};

export const useUnload: UseUnloadFunction = (func, deps) => {
  const cb = useCallback(func, [func, ...deps]);

  useEffect(() => {
    window.addEventListener("unload", cb, { capture: true });

    return () => {
      window.removeEventListener("unload", cb, { capture: true });
    };
  }, [cb]);
};

export const useBodyClick: UseUnloadFunction = (func, deps) => {
  const cb = useCallback(func, [func, ...deps]);

  useEffect(() => {
    document
      .querySelectorAll("body")
      .forEach(ele => ele.addEventListener("click", cb, { capture: true }));

    return () => {
      document
        .querySelectorAll("body")
        .forEach(ele =>
          ele.removeEventListener("click", cb, { capture: true }),
        );
    };
  }, [cb]);
};

export const useScroll: UseUnloadFunction = (func, deps) => {
  const cb = useCallback(func, [func, ...deps]);

  useEffect(() => {
    window.addEventListener("scroll", cb, { capture: true });

    return () => {
      window.removeEventListener("scroll", cb, { capture: true });
    };
  }, [cb]);
};
