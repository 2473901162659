import type {
  FC } from "react";
import {
  createContext,
  useContext,
  useMemo,
  useEffect,
  useState,
  useCallback,
} from "react";
import { useLocation } from "react-router-dom";
import { useGetAuthToken, useVerifyToken } from "~/hooks";



export interface AuthTokensContextProps {
  loading: boolean;
  authToken: string | undefined;
  isAuthenticated: boolean;
}

const defaultContextValue: AuthTokensContextProps = {
  loading: false,
  authToken: undefined,
  isAuthenticated: false,
};

const AuthTokensContext = createContext(defaultContextValue);

export const AuthTokensProvider: FC = ({ children }) => {
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [didFinishLoading, setDidFinishLoading] = useState(false);
  const [authToken, setAuthToken] = useState("");
  const [storedLocation, setStoredLocation] = useState(location);

  const getAuthToken = useGetAuthToken();
  const verifyToken = useVerifyToken();

  const finalizeAuthToken = useCallback(token => {
    setDidFinishLoading(true);
    setAuthToken(token);
  }, []);

  useEffect(() => {
    if (location === storedLocation) {
      if (isLoading || didFinishLoading) {
        // Do not stop verifying if the location has changed
        return;
      }
    } else {
      setStoredLocation(location);
    }

    setIsLoading(true);
    verifyToken().then(({ token }) => {
      if (!!token) { finalizeAuthToken(token); } else {
        getAuthToken().then(({ authToken: retrievedToken }) =>
          finalizeAuthToken(retrievedToken),
        );
      }
    });
  }, [
    location,
    isLoading,
    didFinishLoading,
    getAuthToken,
    verifyToken,
    finalizeAuthToken,
    storedLocation,
  ]);

  const contextValue = useMemo(
    () => ({
      loading: isLoading && !didFinishLoading,
      authToken,
      isAuthenticated: !!authToken,
    }),
    [isLoading, didFinishLoading, authToken],
  );

  return (
    <AuthTokensContext.Provider value={contextValue}>
      {children}
    </AuthTokensContext.Provider>
  );
};

export const useAuthTokensContext = () => useContext(AuthTokensContext);
