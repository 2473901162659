import { gql } from "@kv/apollo-client";



export const ResinLotFragment = gql`
  fragment ResinLotFragment on ResinLot {
    id
    resinId
    lotNum
    createdAt
    resinDataPoints {
      id
      category
      name
      testMethod
      unit
      strValue
      value
    }
  }
`;

export const GetResinLot = gql`
  query GetResinLot($id: ID!) {
    resinLot(id: $id) {
      ...ResinLotFragment
    }
  }
  ${ResinLotFragment}
`;

export const GetResinLots = gql`
  query GetResinLots($filters: ResinLotFilter, $sort: [String]) {
    resinLots(filters: $filters, sort: $sort) {
      ...ResinLotFragment
    }
  }
  ${ResinLotFragment}
`;

export const CreateResinLot = gql`
  mutation CreateResinLot($input: ResinLotInput!) {
    createResinLot(input: $input) {
      ...ResinLotFragment
    }
  }
  ${ResinLotFragment}
`;

export const UpdateResinLot = gql`
  mutation UpdateResinLot($id: ID!, $input: ResinLotInput!) {
    updateResinLot(id: $id, input: $input) {
      ...ResinLotFragment
    }
  }
  ${ResinLotFragment}
`;

export const DeleteResinLot = gql`
  mutation DeleteResinLot($id: ID!) {
    deleteResinLot(id: $id) {
      id
    }
  }
`;
