import {
  GetPulpFiberCategory,
  GetPulpFiberCategories,
  CreatePulpFiberCategory,
  UpdatePulpFiberCategory,
  DeletePulpFiberCategory,
} from "~/graphql";
import type {
  PulpFiberCategoryQueryResultData,
  PulpFiberCategoriesQueryResultData,
  CreatePulpFiberCategoryInput,
  UpdatePulpFiberCategoryInput,
  CreatePulpFiberCategoryMutationResultData,
  UpdatePulpFiberCategoryMutationResultData,
  DeletePulpFiberCategoryMutationResultData,
} from "~/types";

import {
  makeCreateEntityMutationHook,
  makeGetEntitiesQueryHook,
  makeDeleteEntityMutationHook,
  makeUpdateEntityMutationHook,
  makeGetEntityByIdQueryHook,
} from "../utils";




export const useGetPulpFiberCategoryQuery =
  makeGetEntityByIdQueryHook<PulpFiberCategoryQueryResultData>(GetPulpFiberCategory);

export const useGetPulpFiberCategoriesQuery =
  makeGetEntitiesQueryHook<PulpFiberCategoriesQueryResultData>(GetPulpFiberCategories);

export const useCreatePulpFiberCategoryMutation = makeCreateEntityMutationHook<
  CreatePulpFiberCategoryMutationResultData,
  CreatePulpFiberCategoryInput
>(CreatePulpFiberCategory);

export const useUpdatePulpFiberCategoryMutation = makeUpdateEntityMutationHook<
  UpdatePulpFiberCategoryMutationResultData,
  UpdatePulpFiberCategoryInput
>(UpdatePulpFiberCategory);

export const useDeletePulpFiberCategoryMutation =
  makeDeleteEntityMutationHook<DeletePulpFiberCategoryMutationResultData>(
    DeletePulpFiberCategory,
  );
