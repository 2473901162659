export type PulpTdsOptionsType = {
  [key in "biomass" | "pulping" | "bleaching" | "grade"]: {
    [key: string]: string | string[];
  };
};

export const GeneralFiberOptions: PulpTdsOptionsType = {
  biomass: {
    "virgin": [
      "Pine",
      "Spruce",
      "Acacia",
      "Aspen",
      "Eucalyptus",
      "Mixed Hardwood",
      "Mixed Softwood",
      "Other Hardwood",
      "Other Softwood",
    ],
    "non wood": [
      "Wheat Straw",
      "Bamboo",
      "Bagasse",
      "Hemp",
      "Cotton",
      "Cotton Linter",
      "Flax",
    ],
    "recycled": [
      "Mixed Paper",
      "ONP",
      "OCC",
      "Pulp Substitutes",
      "High-Grade Deinked",
    ],
  },
  pulping: {
    nonRecycled: [
      "Kraft",
      "Soda",
      "Sulfite",
      "Mechanical",
      "Chemical-Mechanical",
      "Semi-Chemical",
    ],
  },
  bleaching: {
    nonRecycled: [
      "Unbleached",
      "ECF",
      "TCF",
      "Chlorine Gas",
      "Hypochlorite",
      "Oxygen",
      "Peroxide",
      "Ozone",
    ],
    recycled: [
      "Unbleached",
      "ECF",
      "TCF",
      "Chlorine Gas",
      "Hypochlorite",
      "Oxygen",
      "Peroxide",
      "Ozone",
      "Sodium Hydrosulfite",
      "Formamidinesulfinic Acid",
    ],
  },
  grade: {
    nonRecycled: [
      "NBSK",
      "SBSK",
      "NBHK",
      "SBHK",
      "BEK",
      "UKP",
      "Fluff Pulp",
      "Dissolving Pulp",
      "Other",
    ],
    recycled: [
      "Deinked",
    ],
  },
};

export const FluffFiberOptions: PulpTdsOptionsType = {
  biomass: {
    "virgin": [
      "Pine",
      "Spruce",
      "Acacia",
      "Aspen",
      "Eucalyptus",
      "Mixed Hardwood",
      "Mixed Softwood",
      "Other Hardwood",
      "Other Softwood",
    ],
    "non wood": [
      "Wheat Straw",
      "Bamboo",
      "Bagasse",
      "Hemp",
      "Cotton",
      "Cotton Linter",
      "Flax",
    ],
    "recycled": [
      "Mixed Paper",
      "ONP",
      "OCC",
      "Pulp Substitutes",
      "High-Grade Deinked",
    ],
  },
  pulping: {
    nonRecycled: [
      "Kraft",
      "Soda",
      "Sulfite",
      "Mechanical",
      "Chemical-Mechanical",
      "Semi-Chemical",
    ],
  },
  bleaching: {
    nonRecycled: [
      "Unbleached",
      "ECF",
      "TCF",
      "Chlorine Gas",
      "Hypochlorite",
      "Oxygen",
      "Peroxide",
      "Ozone",
    ],
    recycled: [
      "Unbleached",
      "ECF",
      "TCF",
      "Chlorine Gas",
      "Hypochlorite",
      "Oxygen",
      "Peroxide",
      "Ozone",
      "Sodium Hydrosulfite",
      "Formamidinesulfinic Acid",
    ],
  },
  grade: {
    all: "Fluff Fiber",
  },
};
