import { gql } from "@kv/apollo-client";



const UploadedFileFragment = gql`
  fragment UploadedFileFragment on UploadedFile {
    id
    resinId
    fileName
    contentType
    gateNum
    createdAt
  }
`;

export const GetUploadedFiles = gql`
  query GetUploadedFiles($filters: UploadedFileFilter) {
    uploadedFiles(filters: $filters) {
      ...UploadedFileFragment
    }
  }
  ${UploadedFileFragment}
`;

export const DeleteUploadedFile = gql`
  mutation DeleteUploadedFile($id: ID!) {
    deleteUploadedFile(id: $id) {
      id
    }
  }
`;
