import { gql } from "@kv/apollo-client";



const ResinCountsFragment = gql`
  fragment ResinCountsFragment on ResinCountData {
    hdpe
    pp
    pet
    lldpe
    ldpe
    nonrich
    hdpeFilm
    ppFilm
    totalFilms
  }
`;

const PulpFiberCountsFragment = gql`
  fragment PulpFiberCountsFragment on PulpFiberCountData {
    virginWood
    recycledFiber
    alternativeFiber
    handsheet
    moldedPart
    cartonboard
    plainPaper
    tissuePaper
    total
  }
`;

export const GetStats = gql`
  query GetStats {
    stats {
      resinCounts {
        mechanicalRecycle {
          ...ResinCountsFragment
        }
        virgin {
          ...ResinCountsFragment
        }
        advancedRecycle {
          ...ResinCountsFragment
        }
      }
      pulpFiberCounts {
        generalFiber {
          ...PulpFiberCountsFragment
        }
        fluffFiber {
          ...PulpFiberCountsFragment
        }
        internalPart {
          ...PulpFiberCountsFragment
        }
        externalPart {
          ...PulpFiberCountsFragment
        }
      }
    }
  }
  ${ResinCountsFragment}
  ${PulpFiberCountsFragment}
`;
