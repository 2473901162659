const slicePiece = -2;

export const formatDateTime = (date: Date) =>
  date.getFullYear() +
  "/" +
  ("0" + (date.getMonth() + 1)).slice(slicePiece) +
  "/" +
  ("0" + date.getDate()).slice(slicePiece) +
  " " +
  ("0" + date.getHours()).slice(slicePiece) +
  ":" +
  ("0" + date.getMinutes()).slice(slicePiece) +
  ":" +
  ("0" + date.getSeconds()).slice(slicePiece);

export const formatDate = (date: Date) =>
  date.getFullYear() +
  "/" +
  ("0" + (date.getMonth() + 1)).slice(slicePiece) +
  "/" +
  ("0" + date.getDate()).slice(slicePiece);
