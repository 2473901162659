import type { InputNumberProps } from "antd";
import { MCP_PADDING } from "~/constants";



export const mcpFormatter: InputNumberProps<string | number>["formatter"] = (
  value,
  info,
) => {
  if (info.userTyping && value !== undefined) { return value.toString(); }

  if (typeof value === "string") { return value.length > 0 ? value.padStart(MCP_PADDING, "0") : ""; }
  if (typeof value === "number") { return value.toString().padStart(MCP_PADDING, "0"); }

  return "";
};
