import styled from "astroturf/react";



export const AppContainer = styled("div").attrs({
  "data-testid": "app-container",
})`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`;
