import { GetRegions, GetRegion } from "~/graphql";
import type { RegionsQueryResultData, RegionQueryResultData } from "~/types";

import { makeGetEntitiesQueryHook, makeGetEntityByIdQueryHook } from "../utils";




export const useGetRegionQuery =
  makeGetEntityByIdQueryHook<RegionQueryResultData>(GetRegion);

export const useGetRegionsQuery =
  makeGetEntitiesQueryHook<RegionsQueryResultData>(GetRegions);
