import {
  GetResinFilms,
  CreateResinFilm,
  DeleteResinFilm,
  UpdateResinFilm,
  GetResinFilm,
} from "~/graphql";
import type {
  ResinFilmsQueryResultData,
  CreateResinFilmInput,
  CreateResinFilmMutationResultData,
  DeleteResinFilmMutationResultData,
  UpdateResinFilmMutationResultData,
  UpdateResinFilmInput,
  ResinFilmQueryResultData,
} from "~/types";

import {
  makeCreateEntityMutationHook,
  makeGetEntitiesQueryHook,
  makeDeleteEntityMutationHook,
  makeUpdateEntityMutationHook,
  makeGetEntityByIdQueryHook,
} from "../utils";




export const useGetResinFilmQuery =
  makeGetEntityByIdQueryHook<ResinFilmQueryResultData>(GetResinFilm);

export const useGetResinFilmsQuery =
  makeGetEntitiesQueryHook<ResinFilmsQueryResultData>(GetResinFilms);

export const useCreateResinFilmMutation = makeCreateEntityMutationHook<
  CreateResinFilmMutationResultData,
  CreateResinFilmInput
>(CreateResinFilm);

export const useUpdateResinFilmMutation = makeUpdateEntityMutationHook<
  UpdateResinFilmMutationResultData,
  UpdateResinFilmInput
>(UpdateResinFilm);

export const useDeleteResinFilmMutation =
  makeDeleteEntityMutationHook<DeleteResinFilmMutationResultData>(
    DeleteResinFilm,
  );
