import {
  GetResinTdses,
  CreateResinTds,
  DeleteResinTds,
  UpdateResinTds,
  GetResinTds,
} from "~/graphql";
import type {
  ResinTdsesQueryResultData,
  CreateResinTdsInput,
  CreateResinTdsMutationResultData,
  DeleteResinTdsMutationResultData,
  UpdateResinTdsMutationResultData,
  UpdateResinTdsInput,
  ResinTdsQueryResultData,
} from "~/types";

import {
  makeCreateEntityMutationHook,
  makeGetEntitiesQueryHook,
  makeDeleteEntityMutationHook,
  makeUpdateEntityMutationHook,
  makeGetEntityByIdQueryHook,
} from "../utils";




export const useGetResinTdsQuery =
  makeGetEntityByIdQueryHook<ResinTdsQueryResultData>(GetResinTds);

export const useGetResinTdsesQuery =
  makeGetEntitiesQueryHook<ResinTdsesQueryResultData>(GetResinTdses);

export const useCreateResinTdsMutation = makeCreateEntityMutationHook<
  CreateResinTdsMutationResultData,
  CreateResinTdsInput
>(CreateResinTds);

export const useUpdateResinTdsMutation = makeUpdateEntityMutationHook<
  UpdateResinTdsMutationResultData,
  UpdateResinTdsInput
>(UpdateResinTds);

export const useDeleteResinTdsMutation =
  makeDeleteEntityMutationHook<DeleteResinTdsMutationResultData>(
    DeleteResinTds,
  );
