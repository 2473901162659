import axios from "axios";
import { useCallback } from "react";
import type { AddToast } from "react-toast-notifications";
import { useToasts } from "react-toast-notifications";
import type { AnswerSectionProps } from "~/components/QuestionnaireAnswerSection";
import { SERVER_API_URL } from "~/constants";
import { useAuthTokensContext } from "~/context";
import type { Questionnaire } from "~/types";



export type SubmitQuestionnaireFunction = (
  addToast: AddToast,
  authToken: string | undefined,
  fileId: string,
) => Promise<string>;

const aboveThreshold = 12;

const headerTranslate = {
  MATERIAL_ASSESSMENT: "Material Assessment",
  GENERAL_INFORMATION: "General Information",
  FEEDSTOCK: "Feedstock",
  TRANSFORMATION: "Transformation",
  QUALITY_ASSURANCE: "Quality Assurance",
  HISTORICAL_DATA: "Historical Data",
  PROPRIETARY_RIGHTS: "Proprietary Rights",
  COMMENTS: "Comments",
  UNKNOWN: "Unknown",
};

const submitQuestionnaireHelper: SubmitQuestionnaireFunction = (
  addToast,
  authToken,
  fileId,
) =>
  new Promise<string>((resolve, reject) => {
    axios
      .get(`${SERVER_API_URL}/files/parse_questionnaire/${fileId}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then(response => {
        if (!response?.data?.questionnaire_id) { return; }
        addToast("Successfully loaded the Questionnaire", {
          appearance: "success",
          autoDismissTimeout: 5000,
        });
        resolve(response?.data?.questionnaire_id);
      })
      .catch(error => {
        const msg =
          error?.response?.data?.error ??
          "Unknown error occurred when loading the questionnaire";
        addToast(msg, {
          appearance: "error",
          autoDismissTimeout: 5000,
        });
        reject(error);
      });
  });

export type ParseQuestionnaireFunction = (
  questionnaire: Questionnaire,
) => AnswerSectionProps[];

const parseQuestionnaireHelper: ParseQuestionnaireFunction = questionnaire => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const workingSections: Record<string, any> = {};

  // Process the data
  for (const item of questionnaire.questionnaireItems) {
    const sectionName = headerTranslate[item.section ?? "UNKNOWN"];
    const qNum = item.questionNumber ?? "-1";
    const qLet = item.questionLetter ?? "";
    const question = item.questionValue ?? "";
    const answer = item.answerValue ?? "UNKNOWN";
    const component = item.mainQuestionValue ?? "";

    // Get relevant variables
    const section = workingSections[sectionName] ?? {};
    const subSection = section[qNum] ?? {};
    const qas = subSection["questionsAnswers"] ?? {};

    let addedInfo = false;
    // Add information if it hasn't been defined already
    if (!subSection.question_num) {
      subSection["question_num"] = qNum;
      subSection["subsection_name"] = question;
      addedInfo = true;
    }

    if (!qas[qNum + qLet]) {
      // Add questionAnswer
      qas[qNum + qLet] = {
        question_num: qNum + qLet,
        question: !addedInfo ? question : "",
        question_type: question.length > aboveThreshold ? "above" : "inline",
        answer: answer,
      };
    } else {
      // This is a table, so adjust it as one
      const cur = qas[`${qNum}${qLet}.table`] ?? {
        question_num: `${qNum}${qLet}.table`,
        question: {
          question: !component ? "Prompt" : "Compound",
          answer: !component ? "Answer" : question,
        },
        question_type: "table",
        answer: [],
      };
      cur.answer.push({
        question: !component ? question : component,
        answer,
      });
      qas[`${qNum}${qLet}.table`] = cur;
    }

    // Update variables
    subSection["questionsAnswers"] = qas;
    section[qNum] = subSection;
    workingSections[sectionName] = section;
  }

  // Clean the data to be used on the front-end
  const answerSections: AnswerSectionProps[] = [];

  // Clean Sections
  for (const sectionName in workingSections) {
    const section = workingSections[sectionName];
    const subSections = [];

    // Clean Sub-Sections
    for (const subSectionNum in section) {
      const subSection = section[subSectionNum];
      const questionAnswers = [];

      // Clean Question Answers
      for (const questionNum in subSection["questionsAnswers"]) {
        const qa = subSection["questionsAnswers"][questionNum];
        questionAnswers.push(qa);
      }

      subSection["questionsAnswers"] = questionAnswers;
      subSections.push(subSection);
    }

    answerSections.push({
      section_name: sectionName,
      sub_sections: subSections,
    });
  }

  return answerSections;
};

export const useQuestionnaireParser = () => {
  const { addToast } = useToasts();
  const { authToken } = useAuthTokensContext();

  const submitQuestionnaire = useCallback(
    (fileId: string) => submitQuestionnaireHelper(addToast, authToken, fileId),
    [addToast, authToken],
  );

  const parseQuestionnaire = useCallback(
    (questionnaire: Questionnaire) => parseQuestionnaireHelper(questionnaire),
    [],
  );

  return {
    submitQuestionnaire,
    parseQuestionnaire,
  };
};
