import { Form } from "antd";
import styled from "astroturf/react";
import Text from "~/components/Text";



export const PromptBackgroundContainer = styled("div")`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000000d0;
  z-index: 999;
`;

export const PromptContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-content: center;
  padding: 4.5em 2.5em;
  background-color: #fefefe;
  height: fit-content;
  max-height: 66%;
  border-radius: 4px;
  overflow-y: auto;
`;

export const LoginHeader = styled(Text).attrs({
  size: "h3",
  weight: "bold",
})`
  text-align: center;
  color: #012169;
`;

export const LoginSubHeader = styled(Text).attrs({
  size: "h6",
})`
  margin-bottom: 1em;
  text-align: center;
  color: #121212;
`;

export const DismissText = styled(Text).attrs({
  size: "small",
})`
  margin-top: 1em;
  color: #a2a2a2;
  text-decoration: underline;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  z-index: 999999;
`;

export const FormItem = styled(Form.Item)`
  :global {
    .ant-form-item-control-input-content,
    .ant-form-item-control-input-content .ant-input-number-group-wrapper,
    .ant-form-item-control-input-content .ant-input-number {
      width: 100%;
    }
  }
`;
