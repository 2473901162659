import {
  GetFiberGpsSoiClassification,
  GetFiberGpsSoiClassifications,
  CreateFiberGpsSoiClassification,
  UpdateFiberGpsSoiClassification,
  DeleteFiberGpsSoiClassification,
} from "~/graphql";
import type {
  CreateFiberGpsSoiClassificationInput,
  CreateFiberGpsSoiClassificationMutationResultData,
  DeleteFiberGpsSoiClassificationMutationResultData,
  FiberGpsSoiClassification,
  FiberGpsSoiClassificationQueryResultData,
  FiberGpsSoiClassificationsQueryResultData,
  UpdateFiberGpsSoiClassificationInput,
  UpdateFiberGpsSoiClassificationMutationResultData,
} from "~/types";

import { makeCreateOrUpdate } from "../../createOrUpdate";
import {
  makeCreateEntityMutationHook,
  makeDeleteEntityMutationHook,
  makeGetEntitiesQueryHook,
  makeGetEntityByIdQueryHook,
  makeUpdateEntityMutationHook,
} from "../utils";





export const useGetFiberGpsSoiClassificationQuery =
  makeGetEntityByIdQueryHook<FiberGpsSoiClassificationQueryResultData>(GetFiberGpsSoiClassification);

export const useGetFiberGpsSoiClassificationsQuery =
  makeGetEntitiesQueryHook<FiberGpsSoiClassificationsQueryResultData>(GetFiberGpsSoiClassifications);

export const useCreateFiberGpsSoiClassificationMutation = makeCreateEntityMutationHook<
  CreateFiberGpsSoiClassificationMutationResultData,
  CreateFiberGpsSoiClassificationInput
>(CreateFiberGpsSoiClassification);

export const useUpdateFiberGpsSoiClassificationMutation = makeUpdateEntityMutationHook<
  UpdateFiberGpsSoiClassificationMutationResultData,
  UpdateFiberGpsSoiClassificationInput
>(UpdateFiberGpsSoiClassification);

export const useDeleteFiberGpsSoiClassificationMutation =
  makeDeleteEntityMutationHook<DeleteFiberGpsSoiClassificationMutationResultData>(
    DeleteFiberGpsSoiClassification,
  );

export const useUpsertFiberGpsSoiClassificationMutation = makeCreateOrUpdate<
CreateFiberGpsSoiClassificationInput,
CreateFiberGpsSoiClassificationMutationResultData,
FiberGpsSoiClassificationsQueryResultData,
"fiberGpsSoiClassifications",
UpdateFiberGpsSoiClassificationMutationResultData,
FiberGpsSoiClassification
>(useCreateFiberGpsSoiClassificationMutation,
  useGetFiberGpsSoiClassificationsQuery,
  useUpdateFiberGpsSoiClassificationMutation,
);
