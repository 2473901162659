import { useQuery } from "@kv/apollo-client";
import {
  GetUsers,
  GetUserById,
  GetCurrentUser,
  DeleteUser,
  DeleteUsers,
} from "~/graphql/users";
import type {
  UsersQueryResultData,
  UserQueryResultData,
  CurrentUserQueryResultData,
  DeleteUserMutationResultData,
  DeleteUsersMutationResultData,
} from "~/types";

import {
  makeGetEntitiesQueryHook,
  makeGetEntityByIdQueryHook,
  makeDeleteEntityMutationHook,
  makeDeleteEntitiesMutationHook,
} from "../utils";



export const useGetUsersQuery =
  makeGetEntitiesQueryHook<UsersQueryResultData>(GetUsers);

export const useGetUserByIdQuery =
  makeGetEntityByIdQueryHook<UserQueryResultData>(GetUserById);

export const useGetCurrentUserQuery = () =>
  useQuery<CurrentUserQueryResultData>(GetCurrentUser);

export const useDeleteUserMutation =
  makeDeleteEntityMutationHook<DeleteUserMutationResultData>(DeleteUser);

export const useDeleteUsersMutation =
  makeDeleteEntitiesMutationHook<DeleteUsersMutationResultData>(DeleteUsers);
