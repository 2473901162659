import {
  GetResinLots,
  CreateResinLot,
  DeleteResinLot,
  UpdateResinLot,
  GetResinLot,
} from "~/graphql";
import type {
  ResinLotsQueryResultData,
  CreateResinLotInput,
  CreateResinLotMutationResultData,
  DeleteResinLotMutationResultData,
  UpdateResinLotMutationResultData,
  UpdateResinLotInput,
  ResinLotQueryResultData,
} from "~/types";

import {
  makeCreateEntityMutationHook,
  makeGetEntitiesQueryHook,
  makeDeleteEntityMutationHook,
  makeUpdateEntityMutationHook,
  makeGetEntityByIdQueryHook,
} from "../utils";




export const useGetResinLotQuery =
  makeGetEntityByIdQueryHook<ResinLotQueryResultData>(GetResinLot);

export const useGetResinLotsQuery =
  makeGetEntitiesQueryHook<ResinLotsQueryResultData>(GetResinLots);

export const useCreateResinLotMutation = makeCreateEntityMutationHook<
  CreateResinLotMutationResultData,
  CreateResinLotInput
>(CreateResinLot);

export const useUpdateResinLotMutation = makeUpdateEntityMutationHook<
  UpdateResinLotMutationResultData,
  UpdateResinLotInput
>(UpdateResinLot);

export const useDeleteResinLotMutation =
  makeDeleteEntityMutationHook<DeleteResinLotMutationResultData>(
    DeleteResinLot,
  );
