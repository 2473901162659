import { is } from "ramda";
import type { QueryFilterValue, QueryFilters } from "~/types";



export const getIlikeFilterValue = (value?: string) =>
  value ? `%${value}%` : undefined;

export const getInFilterValue = (values?: string[]) =>
  values?.length ? values : undefined;

const parseQueryFilterValue = (
  key: string,
  value: QueryFilterValue,
): QueryFilterValue => {
  if (value == null || is(Boolean, value)) {
    return value;
  }

  if (is(String, value)) {
    if (key.toLowerCase().endsWith("like")) {
      return `%${value}%`;
    }

    return value;
  }

  if (Array.isArray(value)) {
    if (key.toLowerCase().endsWith("in")) {
      return value?.length ? value : undefined;
    }

    return value.map((currentValue: QueryFilterValue) =>
      parseQueryFilterValue(key, currentValue),
    );
  }

  if (is(Object, value)) {
    return Object.entries(value).reduce(
      (acc, [currentKey, currentValue]) => ({
        ...acc,
        [currentKey]: parseQueryFilterValue(currentKey, currentValue),
      }),
      {},
    );
  }

  return value;
};

export const parseQueryFilters = (filters: QueryFilters): QueryFilters =>
  Object.entries(filters).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: parseQueryFilterValue(key, value),
    }),
    {},
  );
