import ReactDOM from "react-dom";

import App from "./components/App";
import reportWebVitals from "./reportWebVitals";
import {
  registerServiceWorker,
  unregisterServiceWorker,
} from "./serviceWorker";
import { getIsProd } from "./utils";

import "antd/dist/antd.less";
import "./index.scss";



ReactDOM.render(<App />, document.querySelector("#root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
if (getIsProd()) {
  registerServiceWorker();
} else {
  unregisterServiceWorker();
}
