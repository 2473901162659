import {
  GetExecutions,
  CreateExecution,
  DeleteExecution,
  UpdateExecution,
  GetExecution,
} from "~/graphql";
import type {
  ExecutionsQueryResultData,
  CreateExecutionInput,
  CreateExecutionMutationResultData,
  DeleteExecutionMutationResultData,
  UpdateExecutionMutationResultData,
  UpdateExecutionInput,
  ExecutionQueryResultData,
} from "~/types";

import {
  makeCreateEntityMutationHook,
  makeGetEntitiesQueryHook,
  makeDeleteEntityMutationHook,
  makeUpdateEntityMutationHook,
  makeGetEntityByIdQueryHook,
} from "../utils";




export const useGetExecutionQuery =
  makeGetEntityByIdQueryHook<ExecutionQueryResultData>(GetExecution);

export const useGetExecutionsQuery =
  makeGetEntitiesQueryHook<ExecutionsQueryResultData>(GetExecutions);

export const useCreateExecutionMutation = makeCreateEntityMutationHook<
  CreateExecutionMutationResultData,
  CreateExecutionInput
>(CreateExecution);

export const useUpdateExecutionMutation = makeUpdateEntityMutationHook<
  UpdateExecutionMutationResultData,
  UpdateExecutionInput
>(UpdateExecution);

export const useDeleteExecutionMutation =
  makeDeleteEntityMutationHook<DeleteExecutionMutationResultData>(
    DeleteExecution,
  );
