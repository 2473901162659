import type { QueryHookOptions } from "@kv/apollo-client";
import { useMemo } from "react";
import { useAuthTokensContext } from "~/context/AuthTokens";
import type {
  GraphqlNode,
  BaseQueryVars,
  GetEntitiesQueryVars,
  GetEntityByIdQueryVars,
} from "~/types";

import { makeStandardQueryHook } from "./queries";





export const makeAuthenticatedQueryHook = <
  QueryResults,
  QueryVars extends BaseQueryVars = BaseQueryVars,
>(
  queryNode: GraphqlNode<QueryResults, QueryVars>,
) => {
  const useAuthenticatedQuery = (
    variables?: QueryVars,
    options?: QueryHookOptions<QueryResults, QueryVars>,
  ) => {
    const { loading: authTokenLoading, authToken } = useAuthTokensContext();

    const useStandardQuery = makeStandardQueryHook<QueryResults, QueryVars>(
      queryNode,
    );

    const { data, loading, error, refetch, called } = useStandardQuery(
      variables,
      {
        ...options,
        skip: options?.skip || authTokenLoading,
        //* This will automatically be merged in with the other variables.
        variables: {
          token: authToken,
        } as QueryVars,
      },
    );

    const result = useMemo(
      () => ({
        loading: loading || authTokenLoading,
        error: error ?? "",
        data,
        refetch,
        called,
      }),
      [loading, authTokenLoading, error, data, refetch, called],
    );

    return result;
  };

  return useAuthenticatedQuery;
};

export const makeGetEntitiesQueryHook = <QueryResults>(
  queryNode: GraphqlNode<QueryResults, GetEntitiesQueryVars>,
) => makeAuthenticatedQueryHook<QueryResults, GetEntitiesQueryVars>(queryNode);

export const makeGetEntityByIdQueryHook = <QueryResults>(
  queryNode: GraphqlNode<QueryResults, GetEntityByIdQueryVars>,
) =>
  makeAuthenticatedQueryHook<QueryResults, GetEntityByIdQueryVars>(queryNode);
