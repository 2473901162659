import { GetComments, GetComment, CreateComment } from "~/graphql";
import type {
  CommentQueryResultData,
  CommentsQueryResultData,
  CreateCommentInput,
  CreateCommentResult,
} from "~/types";

import {
  makeAuthenticatedMutationHook,
  makeGetEntitiesQueryHook,
  makeGetEntityByIdQueryHook,
} from "../utils";




export const useGetCommentQuery =
  makeGetEntityByIdQueryHook<CommentQueryResultData>(GetComment);

export const useGetCommentsQuery =
  makeGetEntitiesQueryHook<CommentsQueryResultData>(GetComments);

export const useCreateCommentMutation = makeAuthenticatedMutationHook<
  CreateCommentResult,
  CreateCommentInput
>(CreateComment);
