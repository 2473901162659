import type { FC } from "react";
import { useEffect } from "react";
import AppRoutes from "~/pages";

import AppWrapper from "./AppWrapper";
import { AppContainer } from "./StyledComponents";



interface AppProps {
  className?: string;
}

const App: FC<AppProps> = ({ className }) => {
  useEffect(() => {
    function handleError(e: ErrorEvent) {
      const webpackErrorDiv = document.querySelector(
        "#webpack-dev-server-client-overlay-div",
      );
      const webpackError = document.querySelector(
        "#webpack-dev-server-client-overlay",
      );
      if (e.message === "ResizeObserver loop limit exceeded") {
        if (webpackError) {
          webpackError.setAttribute("style", "display: none");
        }
        if (webpackErrorDiv) {
          webpackErrorDiv.setAttribute("style", "display: none");
        }
      } else {
        if (webpackError) {
          webpackError.removeAttribute("style");
        }
        if (webpackErrorDiv) {
          webpackErrorDiv.removeAttribute("style");
        }
      }
    }

    // A benign error that can be ignored
    // https://stackoverflow.com/questions/75774800/how-to-stop-resizeobserver-loop-limit-exceeded-error-from-appearing-in-react-a
    window.addEventListener("error", handleError);

    return () => {
      window.removeEventListener("error", handleError);
    };
  }, []);

  return (
    <AppWrapper>
      <AppContainer className={className}>
        <AppRoutes />
      </AppContainer>
    </AppWrapper>
  );
};

export default App;
