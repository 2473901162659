import { gql } from "@kv/apollo-client";



const ResinCharacteristicFragment = gql`
  fragment ResinCharacteristicFragment on ResinCharacteristics {
    id
    resinId
    soiDateSubmitted
    soiOriginator
    soiClass
    soiHas4Tpp
    soi4TertPentylPhenol
    soiBpa
    soiIsononylPhenol
    rfpLabLocationId
    rfpRequestedDate
    rfpOriginator
    rfpReceivedDate
    rfpMfrMfi
    rfpMfrMfiAlt
    rfpTmPeakMeltPoint
    rfpCrystalizationPeakTemp
    rfpMaterialFamily
    rfpExtensionalViscosity
    rfpShearViscosity
    rfpModifiedTroutonRatio
    rfpContamTmPeakMeltPoint
    rfpEnthOfMelting
    rfpWtPctPpContam
    rfpHdpeContamEnthOfMelting
    rfpWtPctHdpeContam
    rfpAshWtPctTga
    rfpVisualAppearance
    rfpMn
    rfpMw
    rfpMz
    rfpPolydispersity
    rfpHasCelluloseContam
    rfpHasPolyamideContam
    rfpHasPetContam
    rfpZeroShearViscosity
    rfpShearThinningIndex
  }
`;

export const GetResinCharacteristic = gql`
  query GetResinCharacteristic($id: ID!) {
    resinCharacteristic(id: $id) {
      ...ResinCharacteristicFragment
    }
  }
  ${ResinCharacteristicFragment}
`;

export const GetResinCharacteristics = gql`
  query GetResinCharacteristices($filters: ResinCharacteristicsFilter) {
    resinCharacteristics(filters: $filters) {
      ...ResinCharacteristicFragment
    }
  }
  ${ResinCharacteristicFragment}
`;

export const CreateResinCharacteristic = gql`
  mutation CreateResinCharacteristic($input: ResinCharacteristicsInput!) {
    createResinCharacteristic(input: $input) {
      ...ResinCharacteristicFragment
    }
  }
  ${ResinCharacteristicFragment}
`;

export const UpdateResinCharacteristic = gql`
  mutation UpdateResinCharacteristic(
    $id: ID!
    $input: ResinCharacteristicsInput!
  ) {
    updateResinCharacteristic(id: $id, input: $input) {
      ...ResinCharacteristicFragment
    }
  }
  ${ResinCharacteristicFragment}
`;

export const DeleteResinCharacteristic = gql`
  mutation DeleteResinCharacteristic($id: ID!) {
    deleteResinCharacteristic(id: $id) {
      id
    }
  }
`;
