import { gql } from "@kv/apollo-client";



export const ExecutionFragment = gql`
  fragment ExecutionFragment on Execution {
    id
    resinId
    stage
    brand
    business
    article
    totalPcr
    molder
    plant
    date
    comments {
      id
      message
      user {
        firstName
        lastName
      }
      createdAt
      updatedAt
    }
    executionDataPoints {
      id
      category
      name
      strValue
    }
  }
`;

export const GetExecution = gql`
  query GetExecution($id: ID!) {
    execution(id: $id) {
      ...ExecutionFragment
    }
  }
  ${ExecutionFragment}
`;

export const GetExecutions = gql`
  query GetExecutions($filters: ExecutionFilter, $sort: [String]) {
    executions(filters: $filters, sort: $sort) {
      ...ExecutionFragment
    }
  }
  ${ExecutionFragment}
`;

export const CreateExecution = gql`
  mutation CreateExecution($input: ExecutionInput!) {
    createExecution(input: $input) {
      ...ExecutionFragment
    }
  }
  ${ExecutionFragment}
`;

export const UpdateExecution = gql`
  mutation UpdateExecution($id: ID!, $input: ExecutionInput!) {
    updateExecution(id: $id, input: $input) {
      ...ExecutionFragment
    }
  }
  ${ExecutionFragment}
`;

export const DeleteExecution = gql`
  mutation DeleteExecution($id: ID!) {
    deleteExecution(id: $id) {
      id
    }
  }
`;
