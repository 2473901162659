import { curry } from "ramda";
import { generatePath } from "react-router-dom";
import type { RoutePaths } from "~/enums";



export const getRouteUrlFromPath = curry(
  (routePath: RoutePaths, routeParams: Record<string, string | unknown>) =>
    generatePath(routePath, routeParams),
);
