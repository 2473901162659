import {
  GetFiberChemicalTestGeneral,
  GetFiberChemicalTestGenerals,
  CreateFiberChemicalTestGeneral,
  UpdateFiberChemicalTestGeneral,
  DeleteFiberChemicalTestGeneral,
} from "~/graphql";
import type {
  CreateFiberChemicalTestGeneralInput,
  CreateFiberChemicalTestGeneralMutationResultData,
  DeleteFiberChemicalTestGeneralMutationResultData,
  FiberChemicalTestGeneralQueryResultData,
  FiberChemicalTestGeneralsQueryResultData,
  UpdateFiberChemicalTestGeneralInput,
  UpdateFiberChemicalTestGeneralMutationResultData,
} from "~/types";

import {
  makeCreateEntityMutationHook,
  makeDeleteEntityMutationHook,
  makeGetEntitiesQueryHook,
  makeGetEntityByIdQueryHook,
  makeUpdateEntityMutationHook,
} from "../utils";





export const useGetFiberChemicalTestGeneralQuery =
  makeGetEntityByIdQueryHook<FiberChemicalTestGeneralQueryResultData>(GetFiberChemicalTestGeneral);

export const useGetFiberChemicalTestGeneralsQuery =
  makeGetEntitiesQueryHook<FiberChemicalTestGeneralsQueryResultData>(GetFiberChemicalTestGenerals);

export const useCreateFiberChemicalTestGeneralMutation = makeCreateEntityMutationHook<
  CreateFiberChemicalTestGeneralMutationResultData,
  CreateFiberChemicalTestGeneralInput
>(CreateFiberChemicalTestGeneral);

export const useUpdateFiberChemicalTestGeneralMutation = makeUpdateEntityMutationHook<
  UpdateFiberChemicalTestGeneralMutationResultData,
  UpdateFiberChemicalTestGeneralInput
>(UpdateFiberChemicalTestGeneral);

export const useDeleteFiberChemicalTestGeneralMutation =
  makeDeleteEntityMutationHook<DeleteFiberChemicalTestGeneralMutationResultData>(
    DeleteFiberChemicalTestGeneral,
  );
