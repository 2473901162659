import styled from "astroturf/react";



export const DownloadFileInfo = styled("div")`
  display: flex;
  color: #000;
  padding: 0.5em 0;
  align-items: center;
  justify-content: space-between;

  & + & {
    border-top: 1px solid #aaa;
  }
`;

export const DownloadButtonBar = styled.div`
  display: flex;
  gap: 1em;
`;

export const DownloadFileCreatedAt = styled("div")`
  font-size: 0.75rem;
  color: #aaa;
`;
