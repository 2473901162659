import styled from "astroturf/react";
import Text from "~/components/Text";



export const Container = styled("div")`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  padding: 32px;
`;

export const IconContainer = styled("div")`
  width: 96px;
  height: 96px;
`;

export const TextContainer = styled("div")`
  margin-top: 32px;
  color: #fff;
  text-shadow: 1px 1px 1px #000;
`;

export const LoadingText = styled(Text).attrs({ size: "h5" })`
  margin-top: 32px;
  color: #fff;
  text-shadow: 1px 1px 1px #000;
`;
