import {
  GetFiberBusinessApplicationSupplierInformation,
  GetFiberBusinessApplicationSupplierInformations,
  CreateFiberBusinessApplicationSupplierInformation,
  UpdateFiberBusinessApplicationSupplierInformation,
  DeleteFiberBusinessApplicationSupplierInformation,
} from "~/graphql";
import type {
  CreateFiberBusinessApplicationSupplierInformationInput,
  CreateFiberBusinessApplicationSupplierInformationMutationResultData,
  DeleteFiberBusinessApplicationSupplierInformationMutationResultData,
  FiberBusinessApplicationSupplierInformationQueryResultData,
  FiberBusinessApplicationSupplierInformationsQueryResultData,
  UpdateFiberBusinessApplicationSupplierInformationInput,
  UpdateFiberBusinessApplicationSupplierInformationMutationResultData,
} from "~/types";

import {
  makeCreateEntityMutationHook,
  makeDeleteEntityMutationHook,
  makeGetEntitiesQueryHook,
  makeGetEntityByIdQueryHook,
  makeUpdateEntityMutationHook,
} from "../utils";



export const useGetFiberBusinessApplicationSupplierInformationQuery =
  makeGetEntityByIdQueryHook<FiberBusinessApplicationSupplierInformationQueryResultData>(
    GetFiberBusinessApplicationSupplierInformation,
  );

export const useGetFiberBusinessApplicationSupplierInformationsQuery =
  makeGetEntitiesQueryHook<FiberBusinessApplicationSupplierInformationsQueryResultData>(
    GetFiberBusinessApplicationSupplierInformations,
  );

export const useCreateFiberBusinessApplicationSupplierInformationMutation =
  makeCreateEntityMutationHook<
    CreateFiberBusinessApplicationSupplierInformationMutationResultData,
    CreateFiberBusinessApplicationSupplierInformationInput
  >(CreateFiberBusinessApplicationSupplierInformation);

export const useUpdateFiberBusinessApplicationSupplierInformationMutation =
  makeUpdateEntityMutationHook<
    UpdateFiberBusinessApplicationSupplierInformationMutationResultData,
    UpdateFiberBusinessApplicationSupplierInformationInput
  >(UpdateFiberBusinessApplicationSupplierInformation);

export const useDeleteFiberBusinessApplicationSupplierInformationMutation =
  makeDeleteEntityMutationHook<DeleteFiberBusinessApplicationSupplierInformationMutationResultData>(
    DeleteFiberBusinessApplicationSupplierInformation,
  );
