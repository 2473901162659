import { GetStats } from "~/graphql";
import type { StatsQueryResult } from "~/types";

import { makeAuthenticatedQueryHook } from "../utils";




export const useGetStatsQuery =
  makeAuthenticatedQueryHook<StatsQueryResult>(GetStats);
