import loadable from "@loadable/component";
import { prop } from "ramda";
import LoadingIndicator from "~/components/LoadingIndicator";



export interface AsyncPageProps {
  page: string;
}

const AsyncPage = loadable(({ page }: AsyncPageProps) => import(`./${page}`), {
  cacheKey: prop("page"),
  fallback: <LoadingIndicator />,
});

export default AsyncPage;
