import { gql } from "@kv/apollo-client";



export const LocationFragment = gql`
  fragment LocationFragment on Location {
    id
    name
    addressLine1
    addressLine2
    city
    state
    zipCode
    country
  }
`;

export const GetLocation = gql`
  query GetLocation($id: ID!) {
    location(id: $id) {
      ...LocationFragment
    }
  }
  ${LocationFragment}
`;

export const GetLocations = gql`
  query GetLocations($filters: LocationFilter, $sort: [String]) {
    locations(filters: $filters, sort: $sort) {
      ...LocationFragment
    }
  }
  ${LocationFragment}
`;

export const CreateLocation = gql`
  mutation CreateLocation($input: LocationInput!) {
    createLocation(input: $input) {
      ...LocationFragment
    }
  }
  ${LocationFragment}
`;

export const UpdateLocation = gql`
  mutation UpdateLocation($id: ID!, $input: LocationInput!) {
    updateLocation(id: $id, input: $input) {
      ...LocationFragment
    }
  }
  ${LocationFragment}
`;

export const DeleteLocation = gql`
  mutation DeleteLocation($id: ID!) {
    deleteLocation(id: $id) {
      id
    }
  }
`;
