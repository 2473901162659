import type { FC } from "react";
import { Redirect } from "react-router-dom";
import AsyncContent from "~/components/AsyncContent";
import { useAuthTokensContext } from "~/context";
import { RoutePaths } from "~/enums";




const AuthenticatedContent: FC = ({ children }) => {
  const { loading, isAuthenticated } = useAuthTokensContext();
  const loginLocationDescriptor = {
    pathname: RoutePaths.LOGIN,
    state: {
      redirectPath: window.location.pathname,
    },
  };

  return (
    <AsyncContent loading={loading}>
      {!isAuthenticated ? (
        <Redirect to={loginLocationDescriptor} />
      ) : (
        <>{children}</>
      )}
    </AsyncContent>
  );
};

export default AuthenticatedContent;
