import styled from "astroturf/react";
import type { TextSize, TextWeight } from "~/types";



export interface StyledTextProps {
  size: TextSize;
  weight: TextWeight;
}

//* https://type-scale.com
export const StyledText = styled("p")<StyledTextProps>`
  margin: 0;
  font-size: 16px;
  line-height: 24px;

  & + & {
    margin-top: 8px;
  }

  &.size-h1 {
    font-size: 49px;
    line-height: 56px;
  }

  &.size-h2 {
    font-size: 39px;
    line-height: 48px;
  }

  &.size-h3 {
    font-size: 31px;
    line-height: 40px;
  }

  &.size-h4 {
    font-size: 25px;
    line-height: 32px;
  }

  &.size-h5 {
    font-size: 20px;
    line-height: 24px;
  }

  &.size-h6,
  &.size-body {
    font-size: 16px;
    line-height: 24px;
  }

  &.size-medium {
    font-size: 15px;
    line-height: 16px;
  }

  &.size-small {
    font-size: 13px;
    line-height: 16px;
  }

  &.size-xsmall {
    font-size: 12px;
    line-height: 14px;
  }

  &.size-button {
    font-size: 14.6667px;
    line-height: 16px;
  }

  &.weight-normal {
    font-weight: 400;
  }

  &.weight-bold {
    font-weight: 700;
  }
`;
