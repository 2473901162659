import type { FC } from "react";
import { Redirect, useLocation } from "react-router-dom";
import AsyncContent from "~/components/AsyncContent";
import { useAuthTokensContext } from "~/context";
import { RoutePaths } from "~/enums";
import type { RouterLocationState } from "~/types";



const NonAuthenticatedContent: FC = ({ children }) => {
  const { loading, isAuthenticated } = useAuthTokensContext();
  const { state: locationState } = useLocation<RouterLocationState>();

  // TODO - find a way to avoid duplicating this logic
  // * currently it's needed both here and in the login page component
  const redirectPath = locationState?.redirectPath;

  return (
    <AsyncContent loading={loading}>
      {isAuthenticated ? (
        <Redirect to={redirectPath || RoutePaths.RIGIDS_DASHBOARD} />
      ) : (
        <>{children}</>
      )}
    </AsyncContent>
  );
};

export default NonAuthenticatedContent;
