// eslint-disable-next-line unicorn/prefer-node-protocol
import * as https from "https";

import { createApolloClient } from "@kv/apollo-client";
import { GRAPHQL_URL } from "~/constants";



let fetchOptions = {};
if (process.env.NODE_ENV !== "production") {
  fetchOptions = { agent: new https.Agent({ rejectUnauthorized: false }) };
}

export const apolloClient = createApolloClient({
  linkOptions: {
    uri: GRAPHQL_URL,
    fetchOptions,
  },
});
