import { gql } from "@kv/apollo-client";



export const RegionFragment = gql`
  fragment RegionFragment on Region {
    id
    name
  }
`;

export const GetRegion = gql`
  query GetRegion($id: ID!) {
    region(id: $id) {
      ...RegionFragment
    }
  }
  ${RegionFragment}
`;

export const GetRegions = gql`
  query GetRegions($sort: [String]) {
    regions(sort: $sort) {
      ...RegionFragment
    }
  }
  ${RegionFragment}
`;
