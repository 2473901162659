import { gql } from "@kv/apollo-client";



const ResinTdsFragment = gql`
  fragment ResinTdsFragment on ResinTds {
    id
    density
    mfrMfi
    mfrMfiAlt
    siv
    meltTemp
    flexuralModulus
    izodNotchedImpactStrength
    izodNotchedImpactStrengthIso
    izodNotchedImpactStrengthAstm
    pelletL
    pelletA
    pelletB
    charpyImpact
    charpyImpactAlt
    tensileStrength
    tensileStressAtBreak
    elongationAtYield
    elongationAtBreak
    escr
    tensileModulus
    ashContent
    fnct
    colorLab
    polypropylene
    additiveMoldRelease
    additiveNucleator
    additiveClarifier
    additiveAntiStat
    mfrMfi1
    mfrMfi2
    peakMeltTemp
    elmendorfMd
    elmendorfCd
    filmStrength
    dartDropImpact
    filmHaze
    gloss
    colorApp
    polymerContam
    ash
    thermalStab
    antiBlock
    slip
    antiStat
    processAid
    heatDeflectTemp
    haze
    vicatSoftTemp
  }
`;

export const GetResinTds = gql`
  query GetResinTds($id: ID!) {
    resinTds(id: $id) {
      ...ResinTdsFragment
    }
  }
  ${ResinTdsFragment}
`;

export const GetResinTdses = gql`
  query GetResinTdses($filters: ResinTdsFilter) {
    resinTdses(filters: $filters) {
      ...ResinTdsFragment
    }
  }
  ${ResinTdsFragment}
`;

export const CreateResinTds = gql`
  mutation CreateResinTds($input: ResinTdsInput!) {
    createResinTds(input: $input) {
      ...ResinTdsFragment
    }
  }
  ${ResinTdsFragment}
`;

export const UpdateResinTds = gql`
  mutation UpdateResinTds($id: ID!, $input: ResinTdsInput!) {
    updateResinTds(id: $id, input: $input) {
      ...ResinTdsFragment
    }
  }
  ${ResinTdsFragment}
`;

export const DeleteResinTds = gql`
  mutation DeleteResinTds($id: ID!) {
    deleteResinTds(id: $id) {
      id
    }
  }
`;
