import { useMemo } from "react";
import AsyncContent from "~/components/AsyncContent";
import UploadViewer from "~/components/UploadViewer";
import { useGetUploadedFilesQuery } from "~/hooks";
import type { QueryFilters } from "~/types";



export const useUploadViewer = (
  filters: QueryFilters,
  sort: string[] = ["created_at", "desc"],
) => {
  const {
    data: uploadedFilesData,
    loading: uploadedFilesLoading,
    error: uploadedFilesError,
    refetch: uploadedFilesRefetch,
  } = useGetUploadedFilesQuery({
    filters,
    sort,
  });

  const uploadedFiles = useMemo(
    () => uploadedFilesData?.uploadedFiles,
    [uploadedFilesData?.uploadedFiles],
  );

  const uploadedFilesViewer = useMemo(
    () => (
      <AsyncContent loading={uploadedFilesLoading} error={uploadedFilesError}>
        <UploadViewer
          uploadedFiles={uploadedFiles}
          refetch={uploadedFilesRefetch}
        />
      </AsyncContent>
    ),
    [
      uploadedFiles,
      uploadedFilesError,
      uploadedFilesLoading,
      uploadedFilesRefetch,
    ],
  );

  const ret = useMemo(
    () => ({
      uploadedFilesViewer,
      refetch: uploadedFilesRefetch,
    }),
    [uploadedFilesRefetch, uploadedFilesViewer],
  );

  return ret;
};
