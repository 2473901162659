import type { QueryHookOptions } from "@kv/apollo-client";
import type { PaginationProps } from "antd";
import { Pagination } from "antd";
import { useCallback, useMemo, useState } from "react";
import type { BaseQueryVars } from "~/types";

import type { makeGetEntitiesQueryHook } from "./graphql/utils";




type MakeGetEntitiesQueryHookType = typeof makeGetEntitiesQueryHook;
type QueryFunctionType<QueryResult> = (
  variables?: BaseQueryVars,
  options?: QueryHookOptions<QueryResult, BaseQueryVars>,
) => ReturnType<ReturnType<MakeGetEntitiesQueryHookType>>;
type QueryFunctionParametersType<QueryResult> = Parameters<QueryFunctionType<QueryResult>>;

// eslint-disable-next-line @typescript-eslint/comma-dangle
export function useGraphQLPagination<QueryResult>(
  func: QueryFunctionType<QueryResult>,
  entitiesCountName: string,
  defaultPage = 1,
  defaultPageSize = 8,
  paginationProps?: PaginationProps,
  ...args: QueryFunctionParametersType<QueryResult>
) {
  const [page, setPage] = useState(defaultPage);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [fetchParams, setFetchParams] = useState(args[0]);

  const {
    data,
    refetch: _refetch,
    ...rest
  } = func(
    {
      pagination: {
        page: page,
        count: pageSize,
      },
      ...fetchParams,
    },
    args[1],
  );

  type RefetchType = typeof _refetch;

  const refetch = useCallback<RefetchType>(
    (...refetchArgs) => {
      setFetchParams(refetchArgs[0]);

      return _refetch({
        pagination: {
          page: page,
          count: pageSize,
        },
        ...refetchArgs[0],
      });
    },
    [_refetch, page, pageSize],
  );

  const onPaginationChange = useCallback(
    (newPage: number, newPageSize: number) => {
      setPage(newPage);
      setPageSize(newPageSize);
      refetch(fetchParams);
    },
    [fetchParams, refetch],
  );

  const Paginator = useMemo(
    () => (
      <Pagination
        {...paginationProps}
        current={page}
        pageSize={pageSize}
        showSizeChanger
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        total={((data ?? {}) as any)[entitiesCountName]}
        onChange={onPaginationChange}
      />
    ),
    [
      data,
      entitiesCountName,
      onPaginationChange,
      page,
      pageSize,
      paginationProps,
    ],
  );

  const ret = useMemo(
    () => ({
      Paginator,
      refetch,
      data: data as QueryResult,
      setPage,
      setPageSize,
      ...rest,
    }),
    [Paginator, refetch, data, rest],
  );

  return ret;
}
