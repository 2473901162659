import type { ApolloError } from "@kv/apollo-client";
import type { FC } from "react";
import { useMemo } from "react";
import { getApolloErrorMessages } from "~/utils";



interface ApolloErrorsProps {
  error?: ApolloError | string;
}

const ApolloErrors: FC<ApolloErrorsProps> = ({ error }) => {
  const messages = useMemo(() => {
    if (typeof error == "string") { return error; }

    return getApolloErrorMessages(error);
  }, [error]);

  if (messages.length === 0) {
    return null;
  }

  return (
    <>
      {(messages as string[]).map === undefined && <p>{messages}</p>}
      {(messages as string[]).map !== undefined &&
        (messages as string[]).map((message, index) => (
          <p key={`${index}-${message}`}>{message}</p>
        ))}
    </>
  );
};

export default ApolloErrors;
