import { ProgressState } from "~/enums";



export const MAX_PROGRESS = 100;

export const calcState = (percent: number) => {
  if (percent <= 0) { return ProgressState.STARTED; }
  if (percent >= MAX_PROGRESS) { return ProgressState.COMPLETED; }

  return ProgressState.IN_PROGRESS;
};

export const calcProgress = (count: number, expected: number, valWhenZeroExpected?: number) =>
  expected === 0 ? (valWhenZeroExpected ?? MAX_PROGRESS) : Math.round((MAX_PROGRESS * count) / expected);

export const normalizeProgress = (state: ProgressState, progress: number) =>
  state === ProgressState.IN_PROGRESS ? progress : undefined;
