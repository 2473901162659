import {
  GetPulpFiber,
  GetPulpFibers,
  CreatePulpFiber,
  UpdatePulpFiber,
  DeletePulpFiber,
  GetPulpFibersForDashboard,
  GetPulpFiberForPartsDump,
} from "~/graphql";
import type {
  CreatePulpFiberInput,
  CreatePulpFiberMutationResultData,
  DeletePulpFiberMutationResultData,
  PulpFiberForPartsDumpQueryResultData,
  PulpFiberQueryResultData,
  PulpFibersForDashboardQueryResultData,
  PulpFibersQueryResultData,
  UpdatePulpFiberInput,
  UpdatePulpFiberMutationResultData,
} from "~/types";

import {
  makeCreateEntityMutationHook,
  makeDeleteEntityMutationHook,
  makeGetEntitiesQueryHook,
  makeGetEntityByIdQueryHook,
  makeUpdateEntityMutationHook,
} from "../utils";





export const useGetPulpFiberQuery =
  makeGetEntityByIdQueryHook<PulpFiberQueryResultData>(GetPulpFiber);

export const useGetPulpFiberForPartsDumpQuery =
  makeGetEntityByIdQueryHook<PulpFiberForPartsDumpQueryResultData>(GetPulpFiberForPartsDump);

export const useGetPulpFibersQuery =
  makeGetEntitiesQueryHook<PulpFibersQueryResultData>(GetPulpFibers);

export const useGetPulpFibersForDashboardQuery =
  makeGetEntitiesQueryHook<PulpFibersForDashboardQueryResultData>(GetPulpFibersForDashboard);

export const useCreatePulpFiberMutation = makeCreateEntityMutationHook<
  CreatePulpFiberMutationResultData,
  CreatePulpFiberInput
>(CreatePulpFiber);

export const useUpdatePulpFiberMutation = makeUpdateEntityMutationHook<
  UpdatePulpFiberMutationResultData,
  UpdatePulpFiberInput
>(UpdatePulpFiber);

export const useDeletePulpFiberMutation =
  makeDeleteEntityMutationHook<DeletePulpFiberMutationResultData>(
    DeletePulpFiber,
  );
