import { gql } from "@kv/apollo-client";



export const UserFragment = gql`
  fragment UserFragment on User {
    __typename
    id
    first_name
    last_name
    email
  }
`;

export const GetUsers = gql`
  query GetUsers($filters: UserFilter) {
    users(filters: $filters) {
      ...UserFragment
    }
  }
  ${UserFragment}
`;

export const GetUserById = gql`
  query GetUserById($id: ID!) {
    user(id: $id) {
      ...UserFragment
    }
  }
  ${UserFragment}
`;

export const CreateUser = gql`
  mutation CreateUser($input: UserInput!) {
    createUser(input: $input) {
      ...UserFragment
    }
  }
  ${UserFragment}
`;

export const UpdateUser = gql`
  mutation UpdateUser($id: ID!, $input: UserInput!) {
    updateUser(id: $id, input: $input) {
      ...UserFragment
    }
  }
  ${UserFragment}
`;

export const DeleteUser = gql`
  mutation DeleteUser($id: ID!) {
    deleteUser(id: $id) {
      id
    }
  }
`;

export const DeleteUsers = gql`
  mutation DeleteUsers($ids: [ID]) {
    deleteUsers(ids: $ids) {
      ids
    }
  }
`;

export const GetCurrentUser = gql`
  query GetCurrentUser {
    currentUser {
      ...UserFragment
    }
  }
  ${UserFragment}
`;
