import { gql } from "@kv/apollo-client";



const ResinFragment = gql`
  fragment ResinFragment on Resin {
    id
    mcpNum
    plant
    supplier
    materialType
    tradeName
    originCountry
    materialColor
    recommendedPackaging
    resinType
    resinFeedstock
    marketedUse
    scorecardScore
    isTemporary
    categoryId
    createdAt
    updatedAt
    comments {
      id
      message
      user {
        firstName
        lastName
      }
      createdAt
      updatedAt
    }
    tds {
      id
      density
      mfrMfi
      mfrMfiAlt
      meltTemp
      flexuralModulus
      izodNotchedImpactStrength
      izodNotchedImpactStrengthIso
      izodNotchedImpactStrengthAstm
      pelletL
      pelletA
      pelletB
      charpyImpact
      charpyImpactAlt
      tensileStrength
      tensileStressAtBreak
      elongationAtYield
      elongationAtBreak
      escr
      tensileModulus
      ashContent
      fnct
      colorLab
      polypropylene
      additiveMoldRelease
      additiveNucleator
      additiveClarifier
      additiveAntiStat
      siv
      mfrMfi1
      mfrMfi2
      peakMeltTemp
      elmendorfMd
      elmendorfCd
      filmStrength
      dartDropImpact
      filmHaze
      gloss
      colorApp
      polymerContam
      ash
      thermalStab
      antiBlock
      slip
      antiStat
      processAid
      heatDeflectTemp
      haze
      vicatSoftTemp
    }
    characteristics {
      id
      resinId
      soiDateSubmitted
      soiOriginator
      soiClass
      soiHas4Tpp
      soi4TertPentylPhenol
      soiBpa
      soiIsononylPhenol
      rfpLabLocationId
      rfpRequestedDate
      rfpOriginator
      rfpReceivedDate
      rfpMfrMfi
      rfpMfrMfiAlt
      rfpTmPeakMeltPoint
      rfpCrystalizationPeakTemp
      rfpMaterialFamily
      rfpExtensionalViscosity
      rfpShearViscosity
      rfpModifiedTroutonRatio
      rfpContamTmPeakMeltPoint
      rfpEnthOfMelting
      rfpWtPctPpContam
      rfpHdpeContamEnthOfMelting
      rfpWtPctHdpeContam
      rfpAshWtPctTga
      rfpVisualAppearance
      rfpMn
      rfpMw
      rfpMz
      rfpPolydispersity
      rfpHasCelluloseContam
      rfpHasPolyamideContam
      rfpHasPetContam
      rfpZeroShearViscosity
      rfpShearThinningIndex
    }
    regions {
      id
      name
    }
  }
`;

export const GetResin = gql`
  query GetResin($id: ID!) {
    resin(id: $id) {
      ...ResinFragment
    }
  }
  ${ResinFragment}
`;

const ResinExecutionFragment = gql`
  fragment ResinExecutionFragment on Execution {
    id
    stage
    brand
    business
    article
    totalPcr
    molder
    plant
    date
    executionDataPoints {
      id
      category
      name
      strValue
    }
    comments {
      id
      message
      user {
        firstName
        lastName
      }
      createdAt
      updatedAt
    }
  }
`;

export const GetResinAllData = gql`
  query GetResinAllData($id: ID!) {
    resin(id: $id) {
      ...ResinFragment
      resinLots {
        id
        lotNum
        resinDataPoints {
          id
          category
          name
          testMethod
          unit
          strValue
          value
        }
      }
      resinFilms {
        id
        filmNum
        resinDataPoints {
          id
          category
          name
          testMethod
          unit
          strValue
          value
        }
        executions {
          ...ResinExecutionFragment
        }
      }
      executions {
        ...ResinExecutionFragment
      }
    }
  }
  ${ResinFragment}
  ${ResinExecutionFragment}
`;

export const GetResins = gql`
  query GetResins(
    $filters: ResinFilter
    $sort: [String]
    $pagination: PaginationInput
  ) {
    resins(filters: $filters, sort: $sort, pagination: $pagination) {
      ...ResinFragment
    }
    resinsCount(filters: $filters)
  }
  ${ResinFragment}
`;

export const GetResinsForDashboard = gql`
  query GetResins(
    $filters: ResinFilter
    $sort: [String]
    $pagination: PaginationInput
  ) {
    resins(filters: $filters, sort: $sort, pagination: $pagination) {
      ...ResinFragment
      newestResinLot {
        id
        resinId
        lotNum
        resinDataPoints {
          id
          category
          name
          testMethod
          unit
          strValue
          value
        }
      }
      newestResinFilm {
        id
        resinId
        filmNum
        resinDataPoints {
          id
          category
          name
          testMethod
          unit
          strValue
          value
        }
      }
      primaryExecution {
        id
        resinId
        stage
        brand
        business
        article
        totalPcr
        molder
        plant
        date
      }
    }
    resinsCount(filters: $filters)
  }
  ${ResinFragment}
`;

export const GetResinsForRRA = gql`
  query GetResins($filters: ResinFilter, $sort: [String]) {
    resins(filters: $filters, sort: $sort) {
      ...ResinFragment
      resinLots {
        id
        resinId
        lotNum
        resinDataPoints {
          id
          category
          name
          testMethod
          unit
          strValue
          value
        }
      }
      resinFilms {
        id
        resinId
        filmNum
        resinDataPoints {
          id
          category
          name
          testMethod
          unit
          strValue
          value
        }
      }
    }
  }
  ${ResinFragment}
`;

export const CreateResin = gql`
  mutation CreateResin($input: ResinInput!) {
    createResin(input: $input) {
      ...ResinFragment
    }
  }
  ${ResinFragment}
`;

export const UpdateResin = gql`
  mutation UpdateResin($id: ID!, $input: ResinInput!) {
    updateResin(id: $id, input: $input) {
      ...ResinFragment
    }
  }
  ${ResinFragment}
`;

export const DeleteResin = gql`
  mutation DeleteResin($id: ID!) {
    deleteResin(id: $id) {
      id
    }
  }
`;
