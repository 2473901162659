import {
  GetResinCategory,
  GetResinCategories,
  CreateResinCategory,
  UpdateResinCategory,
  DeleteResinCategory,
} from "~/graphql";
import type {
  ResinCategoryQueryResultData,
  ResinCategoriesQueryResultData,
  CreateResinCategoryInput,
  UpdateResinCategoryInput,
  CreateResinCategoryMutationResultData,
  UpdateResinCategoryMutationResultData,
  DeleteResinCategoryMutationResultData,
} from "~/types";

import {
  makeCreateEntityMutationHook,
  makeGetEntitiesQueryHook,
  makeDeleteEntityMutationHook,
  makeUpdateEntityMutationHook,
  makeGetEntityByIdQueryHook,
} from "../utils";




export const useGetResinCategoryQuery =
  makeGetEntityByIdQueryHook<ResinCategoryQueryResultData>(GetResinCategory);

export const useGetResinCategoriesQuery =
  makeGetEntitiesQueryHook<ResinCategoriesQueryResultData>(GetResinCategories);

export const useCreateResinCategoryMutation = makeCreateEntityMutationHook<
  CreateResinCategoryMutationResultData,
  CreateResinCategoryInput
>(CreateResinCategory);

export const useUpdateResinCategoryMutation = makeUpdateEntityMutationHook<
  UpdateResinCategoryMutationResultData,
  UpdateResinCategoryInput
>(UpdateResinCategory);

export const useDeleteResinCategoryMutation =
  makeDeleteEntityMutationHook<DeleteResinCategoryMutationResultData>(
    DeleteResinCategory,
  );
