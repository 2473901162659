import type { Writeable } from "../utils/react";



export type AntdOptionFromStrings<T extends readonly string[]> = Writeable<{
  [K in keyof T]: { key: K; label: T[K]; value: T[K] };
}>;
export function arrayToOptions<T extends readonly string[]>(
  values: T,
): AntdOptionFromStrings<T> {
  return values.map((value, idx) => ({
    key: idx.toString(),
    label: value,
    value,
  })) as AntdOptionFromStrings<T>;
}

// -- Gate 1 Dropdowns --

export const yesNo = [
  { key: "1", label: "Yes", value: "true" },
  { key: "2", label: "No", value: "false" },
];

export const yesNoBoolean = [
  { key: "1", label: "Yes", value: true },
  { key: "2", label: "No", value: false },
];

export const virginResinControls = [
  { key: "1", label: "10 microns - LLDPE", value: "10 microns - LLDPE" },
  { key: "2", label: "50 microns - LLDPE", value: "50 microns - LLDPE" },
  {
    key: "3",
    label: "50 microns - 80% LLDPE, 20% LDPE",
    value: "50 microns - 80% LLDPE, 20% LDPE",
  },
];

export const passFail = [
  { key: "1", label: "Pass", value: "pass" },
  { key: "2", label: "Fail", value: "fail" },
];

export const colorAppearance = [
  { key: "1", label: "Clear/Natural", value: "Clear/Natural" },
  { key: "2", label: "White", value: "White" },
  { key: "3", label: "Colored/Pigmented", value: "Colored/Pigmented" },
  { key: "5", label: "Other", value: "Other" },
];

export const pelletAppearance = [
  { key: "1", label: "Natural/Clear", value: "natural/clear" },
  { key: "2", label: "Pigmented/Colored", value: "PigmentedColored" },
];

export const virginResinFamily = [
  { key: "1", label: "HDPE", value: "HDPE" },
  { key: "2", label: "LLDPE", value: "LLDPE" },
  { key: "3", label: "LDPE", value: "LDPE" },
  { key: "4", label: "Other", value: "Other" },
];

export const polypropyleneResinFamily = [
  { key: "1", label: "Homopolymer", value: "Homopolymer" },
  { key: "2", label: "Random Copolymer", value: "Random Copolymer" },
  { key: "3", label: "Impact Copolymer", value: "Impact Copolymer" },
  { key: "5", label: "PCR Homopolymer", value: "PCR Homopolymer" },
  { key: "6", label: "PCR Unknown Family", value: "PCR Unknown Family" },
];

export const rigidMaterialTypes = [
  { key: "1", label: "HDPE", value: "HDPE" },
  { key: "2", label: "PET", value: "PET" },
  { key: "3", label: "PP", value: "PP" },
  { key: "4", label: "Other", value: "Other" },
];

export const flexibleMaterialTypes = [
  { key: "1", label: "LLDPE Rich", value: "LLDPE Rich" },
  { key: "2", label: "LDPE Rich", value: "LDPE Rich" },
  {
    key: "3",
    label: "LLDPE/LDPE non-Rich",
    value: "LLDPE/LDPE non-Rich Blends",
  },
  { key: "4", label: "HDPE Film Grade", value: "HDPE Film Grade" },
  { key: "5", label: "PP Film Grade", value: "PP Film Grade" },
];

export const resinTypes = [
  { key: "1", label: "Virgin", value: "Virgin" },
  { key: "2", label: "Mechanical Recycle", value: "Mechanical Recycle" },
  { key: "3", label: "Advanced Recycle", value: "Advanced Recycle" },
];

export const resinFeedTypesRigids = [
  { key: "1", label: "Petroleum", value: "Petroleum" },
  { key: "2", label: "PCR", value: "PCR" },
  { key: "3", label: "PIR", value: "PIR" },
  { key: "4", label: "BIO", value: "BIO" },
];

export const resinFeedTypesFlexibles = [
  {
    key: "1",
    label: "Petroleum",
    value: "Petroleum",
  },
  {
    key: "2",
    label: "Post Consumer Household",
    value: "Post Consumer Household",
  },
  {
    key: "3",
    label: "Post Consumer Household Curbside",
    value: "Post Consumer Household Curbside",
  },
  {
    key: "4",
    label: "Post Consumer Household Store Takeback",
    value: "Post Consumer Household Store Takeback",
  },
  {
    key: "5",
    label: "Post Consumer Commercial",
    value: "Post Consumer Commercial",
  },
  {
    key: "6",
    label: "Post Consumer Commercial Stretch",
    value: "Post Consumer Commercial Stretch",
  },
  {
    key: "7",
    label: "Post Consumer Commercial Shrink",
    value: "Post Consumer Commercial Shrink",
  },
];

export const packageTypes = [
  { key: "1", label: "Bottle", value: "Bottle" },
  { key: "2", label: "Other", value: "Other" },
];

export const samplePrepTypes = [
  { key: "1", label: "Compression Molded", value: "Compression Molded" },
  { key: "2", label: "Cast Extruded", value: "Cast Extruded" },
  { key: "3", label: "Extracted from Bottle", value: "Extracted from Bottle" },
];

export const originCountry = [
  { key: "1", label: "Africa", value: "Africa" },
  { key: "2", label: "Argentina", value: "Argentina" },
  { key: "3", label: "Austria", value: "Austria" },
  { key: "4", label: "Balkan Adria", value: "Balkan Adria" },
  { key: "5", label: "Belgium", value: "Belgium" },
  { key: "6", label: "Brazil", value: "Brazil" },
  { key: "7", label: "Canada", value: "Canada" },
  { key: "8", label: "China", value: "China" },
  { key: "9", label: "Columbia", value: "Columbia" },
  { key: "10", label: "Costa Rica", value: "Costa Rica" },
  { key: "11", label: "Czech Republic", value: "Czech Republic" },
  { key: "12", label: "Dominican Republic", value: "Dominican Republic" },
  { key: "13", label: "Finland", value: "Finland" },
  { key: "14", label: "France", value: "France" },
  { key: "15", label: "Germany", value: "Germany" },
  { key: "16", label: "Greece", value: "Greece" },
  { key: "17", label: "Hungary", value: "Hungary" },
  { key: "18", label: "India", value: "India" },
  { key: "19", label: "Indonesia", value: "Indonesia" },
  { key: "20", label: "Italy", value: "Italy" },
  { key: "21", label: "Luzembourg", value: "Luzembourg" },
  { key: "22", label: "Mexico", value: "Mexico" },
  { key: "23", label: "Morocco", value: "Morocco" },
  { key: "24", label: "Netherlands", value: "Netherlands" },
  { key: "25", label: "Nicaragua", value: "Nicaragua" },
  { key: "26", label: "Panama", value: "Panama" },
  { key: "27", label: "Peru", value: "Peru" },
  { key: "28", label: "Philippines", value: "Philippines" },
  { key: "29", label: "Poland", value: "Poland" },
  { key: "30", label: "Portugal", value: "Portugal" },
  { key: "31", label: "Puerto Rico", value: "Puerto Rico" },
  { key: "32", label: "Romania", value: "Romania" },
  { key: "33", label: "Russia", value: "Russia" },
  { key: "34", label: "Spain", value: "Spain" },
  { key: "35", label: "Sweden", value: "Sweden" },
  { key: "36", label: "Switzerland", value: "Switzerland" },
  { key: "37", label: "Thailand", value: "Thailand" },
  { key: "38", label: "Turkey", value: "Turkey" },
  { key: "39", label: "United States", value: "United States" },
  { key: "40", label: "United Kingdom", value: "United Kingdom" },
  { key: "41", label: "Venezuela", value: "Venezuela" },
  { key: "42", label: "Vietnam", value: "Vietnam" },
  { key: "43", label: "Other", value: "Other" },
];

export const materialColor = [
  { key: "1", label: "Clear/Natural", value: "Clear/Natural" },
  { key: "2", label: "White", value: "White" },
  { key: "3", label: "Gray", value: "Gray" },
  { key: "4", label: "Other", value: "Other" },
];

export const rigidMarketedUses = [
  { key: "1", label: "Bottle", value: "Bottle" },
  { key: "2", label: "IM", value: "IM" },
  { key: "3", label: "Other", value: "Other" },
];

export const flexibleMarketedUses = [
  { key: "1", label: "Overwrap", value: "Overwrap" },
  { key: "2", label: "Sealant Film/Laminate", value: "Sealant Film/Laminate" },
  { key: "3", label: "Large Count Pack Wrap", value: "Large Count Pack Wrap" },
  { key: "4", label: "High Stock HD", value: "High Stock HD" },
  { key: "5", label: "Trash Bag", value: "Trash Bag" },
  { key: "6", label: "Other", value: "Other" },
];

export const fiberMarketedUses = [
  { key: "1", label: "Tissue/Towel", value: "Tissue/Towel" },
  { key: "2", label: "Paperboard", value: "Paperboard" },
  { key: "3", label: "Corrugate", value: "Corrugate" },
  { key: "4", label: "Sack Paper", value: "Sack Paper" },
  { key: "5", label: "Molded Parts", value: "Molded Parts" },
  { key: "6", label: "Absorbent Material", value: "Absorbent Material" },
  { key: "7", label: "Other", value: "Other" },
];

export const colorLABs = [
  { key: "1", label: "Clear/Natural", value: "Clear/Natural" },
  { key: "2", label: "White", value: "White" },
  { key: "3", label: "Gray", value: "Gray" },
  { key: "4", label: "Black", value: "Black" },
];

// -- Gate 3 Dropdowns --

export const businessUnits = [
  { key: "1", label: "Beauty Care", value: "Beauty Care" },
  { key: "2", label: "Fabric and Home Care", value: "Fabric and Home Care" },
  { key: "3", label: "Other", value: "Other" },
];

export const subBusinessUnits = [
  { key: "1", label: "Fabric Care", value: "Fabric Care" },
  { key: "2", label: "Hair Care", value: "Hair Care" },
  { key: "3", label: "Home Care", value: "Home Care" },
  { key: "4", label: "Other", value: "Other" },
];

export const fabricCareBrands = [
  { key: "1", label: "Ariel", value: "Ariel" },
  { key: "2", label: "Downy", value: "Downy" },
  { key: "3", label: "Gain", value: "Gain" },
  { key: "4", label: "Lenor", value: "Lenor" },
  { key: "5", label: "Tide", value: "Tide" },
  { key: "6", label: "Herman XL-ESB", value: "Herman XL-ESB" },
  { key: "7", label: "Ace", value: "Ace" },
  { key: "8", label: "Dash", value: "Dash" },
  { key: "9", label: "Dreft", value: "Dreft" },
  { key: "10", label: "Era", value: "Era" },
  { key: "11", label: "Ivory Snow", value: "Ivory Snow" },
  { key: "12", label: "Other", value: "Other" },
];

export const hairCareBrands = [
  { key: "1", label: "Aussie", value: "Aussie" },
  { key: "2", label: "Bear Fruits", value: "Bear Fruits" },
  { key: "3", label: "Hair & Skin Kimea", value: "Hair & Skin Kimea" },
  { key: "4", label: "Hair Food", value: "Hair Food" },
  { key: "5", label: "Hair Recipe", value: "Hair Recipe" },
  { key: "6", label: "Head & Shoulders", value: "Head & Shoulders" },
  { key: "7", label: "Herbal Essences", value: "Herbal Essences" },
  { key: "8", label: "Modest ego", value: "Modest ego" },
  { key: "9", label: "My Black Is Beautiful", value: "My Black Is Beautiful" },
  { key: "10", label: "Old Spice", value: "Old Spice" },
  { key: "11", label: "Pantene", value: "Pantene" },
  { key: "12", label: "Pert", value: "Pert" },
  { key: "13", label: "Pro Series", value: "Pro Series" },
  { key: "14", label: "Rejoice", value: "Rejoice" },
  { key: "15", label: "Rooted Ritual", value: "Rooted Ritual" },
  { key: "16", label: "Spring & Vine", value: "Spring & Vine" },
  { key: "17", label: "Vidal Sassoon", value: "Vidal Sassoon" },
  { key: "18", label: "Waterless", value: "Waterless" },
  { key: "19", label: "Other", value: "Other" },
];

export const homeCareBrands = [
  { key: "1", label: "Magistral", value: "Magistral" },
  { key: "2", label: "Salvo", value: "Salvo" },
  { key: "3", label: "Other", value: "Other" },
];

export const fabricCareShapes = [
  { key: "1", label: "Ambrosia - Aquarium", value: "Ambrosia - Aquarium" },
  { key: "2", label: "Aquarium - Jacuzzi", value: "Aquarium - Jacuzzi" },
  { key: "3", label: "Beetle", value: "Beetle" },
  { key: "4", label: "Bottle Sprayer", value: "Bottle Sprayer" },
  { key: "5", label: "Bullseye", value: "Bullseye" },
  { key: "6", label: "Cutie", value: "Cutie" },
  { key: "7", label: "Gherkin", value: "Gherkin" },
  {
    key: "8",
    label: "Goldie 2.0 and Goldie 1.0",
    value: "Goldie 2.0 and Goldie 1.0",
  },
  { key: "9", label: "Heman XL - ES", value: "Heman XL - ES" },
  { key: "10", label: "Jasmine", value: "Jasmine" },
  { key: "11", label: "Legacy", value: "Legacy" },
  { key: "12", label: "Pure", value: "Pure" },
  { key: "13", label: "Renegade", value: "Renegade" },
  { key: "14", label: "Winpack", value: "Winpack" },
  { key: "15", label: "1X Dilute", value: "1X Dilute" },
  { key: "16", label: "Other", value: "Other" },
];

export const hairCareShapes = [
  { key: "1", label: "Apothecary", value: "Apothecary" },
  { key: "2", label: "Column", value: "Column" },
  { key: "3", label: "Cylindrical", value: "Cylindrical" },
  { key: "4", label: "Diamond", value: "Diamond" },
  { key: "5", label: "Disktop", value: "Disktop" },
  { key: "6", label: "Ghost", value: "Ghost" },
  { key: "7", label: "Ghost 2.0 ", value: "Ghost 2.0 " },
  { key: "8", label: "Iris", value: "Iris" },
  { key: "9", label: "Mushroom", value: "Mushroom" },
  { key: "10", label: "Swan", value: "Swan" },
  { key: "11", label: "Other", value: "Other" },
];

export const homeCareShapes = [
  { key: "1", label: "Mars", value: "Mars" },
  { key: "2", label: "Other", value: "Other" },
];

export const converter = [
  { key: "1", label: "Alpla", value: "Alpla" },
  { key: "2", label: "Aptar", value: "Aptar" },
  { key: "3", label: "Axium", value: "Axium" },
  { key: "4", label: "Berry", value: "Berry" },
  { key: "5", label: "IMCO", value: "IMCO" },
  { key: "6", label: "Logoplaste", value: "Logoplaste" },
  { key: "7", label: "Plastipak", value: "Plastipak" },
  { key: "8", label: "SAP", value: "SAP" },
  { key: "9", label: "Serioplast", value: "Serioplast" },
  { key: "10", label: "Silgan", value: "Silgan" },
  { key: "11", label: "Technimark", value: "Technimark" },
  { key: "12", label: "Tessy", value: "Tessy" },
  { key: "13", label: "YMP", value: "YMP" },
  { key: "14", label: "Other", value: "Other" },
];

export const fabricCareProductionPlatforms = [
  { key: "1", label: "GEM", value: "GEM" },
  { key: "2", label: "Nissei", value: "Nissei" },
  { key: "3", label: "Sidel", value: "Sidel" },
  { key: "4", label: "Wheel", value: "Wheel" },
  { key: "5", label: "HDL", value: "HDL" },
  { key: "6", label: "Other", value: "Other" },
];

export const hairCareProductionPlatforms = [
  { key: "1", label: "EBM and SSB", value: "EBM and SSB" },
  { key: "2", label: "IM", value: "IM" },
  { key: "3", label: "ISBM 2step", value: "ISBM 2step" },
  { key: "4", label: "Kautex", value: "Kautex" },
  { key: "5", label: "Seriopack", value: "Seriopack" },
  { key: "6", label: "HDL", value: "HDL" },
  { key: "7", label: "Other", value: "Other" },
];

export const homeCareProductionPlatforms = [
  { key: "1", label: "ALS", value: "ALS" },
  { key: "2", label: "Wheel", value: "Wheel" },
  { key: "3", label: "HDL", value: "HDL" },
  { key: "4", label: "Other", value: "Other" },
];

// Gate Two
export const soiClass = [
  { key: "1", label: "1", value: "1" },
  { key: "2", label: "2", value: "2" },
  { key: "3", label: "3", value: "3" },
  { key: "4", label: "4", value: "4" },
  { key: "5", label: "Rejected", value: "Rejected" },
];

export const screenMoldingStatuses = [
  { key: "1", label: "Pass", value: "pass" },
  { key: "2", label: "Marginal", value: "marginal" },
  { key: "3", label: "Fail", value: "fail" },
];

export const screenMoldingScorecardStatuses = [
  { key: "1", label: "Good", value: "good" },
  { key: "2", label: "Caution", value: "caution" },
  { key: "3", label: "Risk", value: "risk" },
  { key: "4", label: "High Risk", value: "high risk" },
];

export const tensileDogboneType = [
  { key: "1", label: "Type I", value: "Type I" },
  { key: "2", label: "Type V", value: "Type V" },
];

export const partGeometries = [
  { key: "1", label: "Complex Geometry", value: "Complex Geometry" },
  { key: "2", label: "Disc-Top Closure", value: "Disc-Top Closure" },
  { key: "3", label: "Hinge Closure", value: "Hinge Closure" },
  { key: "4", label: "Overcap", value: "Overcap" },
  { key: "5", label: "Pump housing/actuator", value: "Pump housing/actuator" },
  { key: "6", label: "Push/Pull Closure", value: "Push/Pull Closure" },
  { key: "7", label: "Thick Wall Part", value: "Thick Wall Part" },
  { key: "8", label: "Threaded Closure", value: "Threaded Closure" },
  { key: "9", label: "Tub", value: "Tub" },
  { key: "10", label: "Other", value: "Other" },
];

export const regionsSelectedOptions = [
  { key: "1", label: "APAC", value: 1 },
  { key: "2", label: "Europe", value: 2 },
  { key: "3", label: "Greater China", value: 3 },
  { key: "4", label: "IMEA", value: 4 },
  { key: "5", label: "Latin America", value: 5 },
  { key: "6", label: "North America", value: 6 },
];
