import { gql } from "@kv/apollo-client";



export const FiberGpsSoiClassificationBasicFragment = gql`
  fragment FiberGpsSoiClassificationBasicFragment on FiberGpsSoiClassification {
    id
    dateSubmitted
    originator
    soiClass
    soi4TertPentylPhenol
    galabBpa
    galabIsononylPhenol
  }
`;

const FiberGpsSoiClassification = gql`
  fragment FiberGpsSoiClassification on FiberGpsSoiClassification {
    ...FiberGpsSoiClassificationBasicFragment
    fiberPart{
      id
    }
    fiberLot{
      id
    }
  }
  ${FiberGpsSoiClassificationBasicFragment}
`;

export const GetFiberGpsSoiClassification = gql`
  query GetFiberGpsSoiClassification($id: ID!) {
    fiberGpsSoiClassification(id: $id) {
      ...FiberGpsSoiClassification
    }
  }
  ${FiberGpsSoiClassification}
`;

export const GetFiberGpsSoiClassifications = gql`
  query GetFiberGpsSoiClassifications(
    $filters: FiberGpsSoiClassificationFilter
    $pagination: PaginationInput
  ) {
    fiberGpsSoiClassifications(filters: $filters, pagination: $pagination) {
      ...FiberGpsSoiClassification
    }
    fiberGpsSoiClassificationsCount(filters: $filters)
  }
  ${FiberGpsSoiClassification}
`;

export const CreateFiberGpsSoiClassification = gql`
  mutation CreateFiberGpsSoiClassification($input: FiberGpsSoiClassificationInput!) {
    createFiberGpsSoiClassification(input: $input) {
      ...FiberGpsSoiClassification
    }
  }
  ${FiberGpsSoiClassification}
`;

export const UpdateFiberGpsSoiClassification = gql`
  mutation UpdateFiberGpsSoiClassification($id: ID!, $input: FiberGpsSoiClassificationInput!) {
    updateFiberGpsSoiClassification(id: $id, input: $input) {
      ...FiberGpsSoiClassification
    }
  }
  ${FiberGpsSoiClassification}
`;

export const DeleteFiberGpsSoiClassification = gql`
  mutation DeleteFiberGpsSoiClassification($id: ID!) {
    deleteFiberGpsSoiClassification(id: $id) {
      id
    }
  }
`;
