import { gql } from "@kv/apollo-client";



export const FiberFingerprintTestKajaaniBasicFragment = gql`
  fragment FiberFingerprintTestKajaaniBasicFragment on FiberFingerprintTestKajaani {
    id
    createdAt
    updatedAt
    lN
    lL
    fibersPerGram
    width
    wall
    coarseness
    curl
    fibrilation
  }
`;

export const FiberFingerprintTestKajaaniFragment = gql`
  fragment FiberFingerprintTestKajaaniFragment on FiberFingerprintTestKajaani {
    ...FiberFingerprintTestKajaaniBasicFragment
    fiberLot{
      id
    }
  }
  ${FiberFingerprintTestKajaaniBasicFragment}
`;

export const GetFiberFingerprintTestKajaani = gql`
  query GetFiberFingerprintTestKajaani($id: ID!) {
    fiberFingerprintTestKajaani(id: $id) {
      ...FiberFingerprintTestKajaaniFragmnet
    }
  }
  ${FiberFingerprintTestKajaaniFragment}
`;



export const GetFiberFingerprintTestKajaanis = gql`
  query GetFiberFingerprintTestKajaanis($filters: FiberFingerprintTestKajaaniFilter, $sort: [String], $pagination: PaginationInput) {
    fiberFingerprintTestKajaanis(filters: $filters, sort: $sort, pagination: $pagination) {
      ...FiberFingerprintTestKajaaniFragment
    }
  }
  ${FiberFingerprintTestKajaaniFragment}
`;


export const CreateFiberFingerprintTestKajaani = gql`
  mutation CreateFiberFingerprintTestKajaani($input: FiberFingerprintTestKajaaniInput!) {
    createFiberFingerprintTestKajaani(input: $input) {
      ...FiberFingerprintTestKajaaniFragment
    }
  }
  ${FiberFingerprintTestKajaaniFragment}
`;

export const UpdateFiberFingerprintTestKajaani = gql`
  mutation UpdateFiberFingerprintTestKajaani($id: ID!, $input: FiberFingerprintTestKajaaniInput!) {
    updateFiberFingerprintTestKajaani(id: $id, input: $input) {
      ...FiberFingerprintTestKajaaniFragment
    }
  }
  ${FiberFingerprintTestKajaaniFragment}
`;

export const DeleteFiberFingerprintTestKajaani = gql`
  mutation DeleteFiberFingerprintTestKajaani($id: ID!) {
    deleteFiberFingerprintTestKajaani(id: $id) {
      id
    }
  }
`;
