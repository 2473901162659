import type { FC, ReactNode } from "react";
import { useCallback } from "react";

import { useDownloadFile } from "../../hooks/download";



export interface DownloadButtonProps {
  fileId: string;
  children: ReactNode;
}

const DownloadButton: FC<DownloadButtonProps> = ({ fileId, children }) => {
  const { downloadFile } = useDownloadFile();

  const download = useCallback(
    () => downloadFile(fileId),
    [downloadFile, fileId],
  );

  return <div onClick={download}>{children}</div>;
};

export default DownloadButton;
