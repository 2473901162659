import { gql } from "@kv/apollo-client";



export const FiberChemicalTestGeneralBasicFragment = gql`
  fragment FiberChemicalTestGeneralBasicFragment on FiberChemicalTestGeneral {
    id
    createdAt
    updatedAt
    moisture
    ash
    fines
    dcmExtract
    onePctSodiumHydroxide
    anionicTrash
    cationicDemand
    carboxylNumber
    phStdWater
    phDeionizedWater
    celluloseSolutionViscosity
    alphaCellulose
    kappaNumberUnbleached
    cleanlinessRecycledFiber
  }
`;


const FiberChemicalTestGeneralFragment = gql`
  fragment FiberChemicalTestGeneralFragment on FiberChemicalTestGeneral {
    ...FiberChemicalTestGeneralBasicFragment
    fiberLot{
      id
    }
  }
  ${FiberChemicalTestGeneralBasicFragment}
`;

export const GetFiberChemicalTestGeneral = gql`
  query GetFiberChemicalTestGeneral($id: ID!) {
    fiberChemicalTestGeneral(id: $id) {
      ...FiberChemicalTestGeneralFragment
    }
  }
  ${FiberChemicalTestGeneralFragment}
`;

export const GetFiberChemicalTestGenerals = gql`
  query GetFiberChemicalTestGenerals(
    $filters: FiberChemicalTestGeneralFilter
    $sort: [String]
    $pagination: PaginationInput
  ) {
    fiberChemicalTestGenerals(filters: $filters, sort: $sort, pagination: $pagination) {
      ...FiberChemicalTestGeneralFragment
    }
    fiberChemicalTestGeneralsCount(filters: $filters)
  }
  ${FiberChemicalTestGeneralFragment}
`;

export const CreateFiberChemicalTestGeneral = gql`
  mutation CreateFiberChemicalTestGeneral($input: FiberChemicalTestGeneralInput!) {
    createFiberChemicalTestGeneral(input: $input) {
      ...FiberChemicalTestGeneralFragment
    }
  }
  ${FiberChemicalTestGeneralFragment}
`;

export const UpdateFiberChemicalTestGeneral = gql`
  mutation UpdateFiberChemicalTestGeneral($id: ID!, $input: FiberChemicalTestGeneralInput!) {
    updateFiberChemicalTestGeneral(id: $id, input: $input) {
      ...FiberChemicalTestGeneralFragment
    }
  }
  ${FiberChemicalTestGeneralFragment}
`;

export const DeleteFiberChemicalTestGeneral = gql`
  mutation DeleteFiberChemicalTestGeneral($id: ID!) {
    deleteFiberChemicalTestGeneral(id: $id) {
      id
    }
  }
`;
