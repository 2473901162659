export const menuItems = [
  {
    stepId: 1,
    stepName: "Resin Search",
    isVisible: (isTemporary: boolean) => isTemporary,
  },
  {
    stepId: 2,
    stepName: "Meta Data",
    isVisible: (isTemporary: boolean) => true,
  },
  {
    stepId: 3,
    stepName: "TDS from Supplier",
    isVisible: (isTemporary: boolean) => true,
  },
  {
    stepId: 4,
    stepName: "PCR-PIR Questionnaire",
    isVisible: (isTemporary: boolean) => true,
  },
  {
    stepId: 5,
    stepName: "Comments",
    isVisible: (isTemporary: boolean) => true,
  },
  {
    stepId: 6,
    stepName: "Confirm & Save",
    isVisible: (isTemporary: boolean) => true,
  },
];

export const fiberMenuItems = [
  {
    stepId: 1,
    stepName: "Pulp Material Search",
    isVisible: (isTemporary: boolean) => isTemporary,
  },
  {
    stepId: 2,
    stepName: "Meta Data",
    isVisible: (isTemporary: boolean) => true,
  },
  {
    stepId: 3,
    stepName: "TDS from Supplier",
    isVisible: (isTemporary: boolean) => true,
  },
  {
    stepId: 4,
    stepName: "GPS Questionnaire",
    isVisible: (isTemporary: boolean) => true,
  },
  {
    stepId: 5,
    stepName: "Comments",
    isVisible: (isTemporary: boolean) => true,
  },
  {
    stepId: 6,
    stepName: "Confirm & Save",
    isVisible: (isTemporary: boolean) => true,
  },
];
