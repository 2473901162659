import { gql } from "@kv/apollo-client";



export const FiberChemicalTestIonBasicFragment = gql`
  fragment FiberChemicalTestIonBasicFragment on FiberChemicalTestIon {
    id
    extracted
    sodium
    magnesium
    calcium
    iron
    silicon
    aluminum
    chloride
    phosphate
    sulphate
    nitrate
  }
`;

const FiberChemicalTestIonFragment = gql`
  fragment FiberChemicalTestIonFragment on FiberChemicalTestIon {
    ...FiberChemicalTestIonBasicFragment
    fiberLot{
      id
    }
  }
  ${FiberChemicalTestIonBasicFragment}
`;

export const GetFiberChemicalTestIon = gql`
  query GetFiberChemicalTestIon($id: ID!) {
    fiberChemicalTestIon(id: $id) {
      ...FiberChemicalTestIonFragment
    }
  }
  ${FiberChemicalTestIonFragment}
`;

export const GetFiberChemicalTestIons = gql`
  query GetFiberChemicalTestIons(
    $filters: FiberChemicalTestIonFilter
    $sort: [String]
    $pagination: PaginationInput
  ) {
    fiberChemicalTestIons(filters: $filters, sort: $sort, pagination: $pagination) {
      ...FiberChemicalTestIonFragment
    }
    fiberChemicalTestIonsCount(filters: $filters)
  }
  ${FiberChemicalTestIonFragment}
`;

export const CreateFiberChemicalTestIon = gql`
  mutation CreateFiberChemicalTestIon($input: FiberChemicalTestIonInput!) {
    createFiberChemicalTestIon(input: $input) {
      ...FiberChemicalTestIonFragment
    }
  }
  ${FiberChemicalTestIonFragment}
`;

export const UpdateFiberChemicalTestIon = gql`
  mutation UpdateFiberChemicalTestIon($id: ID!, $input: FiberChemicalTestIonInput!) {
    updateFiberChemicalTestIon(id: $id, input: $input) {
      ...FiberChemicalTestIonFragment
    }
  }
  ${FiberChemicalTestIonFragment}
`;

export const DeleteFiberChemicalTestIon = gql`
  mutation DeleteFiberChemicalTestIon($id: ID!) {
    deleteFiberChemicalTestIon(id: $id) {
      id
    }
  }
`;
