import { gql } from "@kv/apollo-client";

import { FiberPartBlendedCompositionMaterialFragment } from "./fiberPartBlendedCompositionMaterial";



const FiberPartBlendedCompositionFragment = gql`
  fragment FiberPartBlendedCompositionFragment on FiberPartBlendedComposition {
    id
    fiberPart{
      id
    }
    totalBasisWeight
    materials{
      ...FiberPartBlendedCompositionMaterialFragment
    }
  }
  ${FiberPartBlendedCompositionMaterialFragment}
`;

export const GetFiberPartBlendedComposition = gql`
  query GetFiberPartBlendedComposition($id: ID!) {
    fiberPartBlendedComposition(id: $id) {
      ...FiberPartBlendedCompositionFragment
    }
  }
  ${FiberPartBlendedCompositionFragment}
`;

export const GetFiberPartBlendedCompositions = gql`
  query GetFiberPartBlendedCompositions(
    $filters: FiberPartBlendedCompositionFilter
    $pagination: PaginationInput
  ) {
    fiberPartBlendedCompositions(filters: $filters, pagination: $pagination) {
      ...FiberPartBlendedCompositionFragment
    }
    fiberPartBlendedCompositionsCount(filters: $filters)
  }
  ${FiberPartBlendedCompositionFragment}
`;

export const CreateFiberPartBlendedComposition = gql`
  mutation CreateFiberPartBlendedComposition($input: FiberPartBlendedCompositionInput!) {
    createFiberPartBlendedComposition(input: $input) {
      ...FiberPartBlendedCompositionFragment
    }
  }
  ${FiberPartBlendedCompositionFragment}
`;

export const UpdateFiberPartBlendedComposition = gql`
  mutation UpdateFiberPartBlendedComposition($id: ID!, $input: FiberPartBlendedCompositionInput!) {
    updateFiberPartBlendedComposition(id: $id, input: $input) {
      ...FiberPartBlendedCompositionFragment
    }
  }
  ${FiberPartBlendedCompositionFragment}
`;

export const DeleteFiberPartBlendedComposition = gql`
  mutation DeleteFiberPartBlendedComposition($id: ID!) {
    deleteFiberPartBlendedComposition(id: $id) {
      id
    }
  }
`;
