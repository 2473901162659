import { Button } from "antd";
import type { FC, ReactNode } from "react";
import { useCallback } from "react";
import { useForm } from "react-hook-form";

import PromptInput from "./promptInput";
import {
  DismissText,
  LoginHeader,
  LoginSubHeader,
  PromptBackgroundContainer,
  PromptContainer,
} from "./StyledComponents";



interface CredentialPromptProps {
  promptVisible?: boolean;
  onSubmit?: (username: string, password: string) => void;
  onDismiss?: () => void;
  children?: ReactNode;
}

interface CredentialFormValues {
  username: string;
  password: string;
}

const CredentialPrompt: FC<CredentialPromptProps> = ({
  promptVisible,
  onSubmit: _onSubmit,
  onDismiss: _onDismiss,
  children,
}) => {
  const { control, getValues } = useForm<CredentialFormValues>();

  const onSubmit = useCallback(() => {
    const username = getValues("username");
    const password = getValues("password");

    if (_onSubmit) { _onSubmit(username, password); }
  }, [_onSubmit, getValues]);

  const onDismiss = useCallback(() => {
    if (_onDismiss) { _onDismiss(); }
  }, [_onDismiss]);

  return (
    <>
      {children}
      {promptVisible && (
        <PromptBackgroundContainer>
          <PromptContainer>
            <LoginHeader>Enovia Log In</LoginHeader>
            <LoginSubHeader>
              Please log into your Enovia account.
              <br />
              This allows for integration with Enovia.
            </LoginSubHeader>
            <PromptInput
              name="username"
              placeholder="Enovia Username"
              control={control}
            />
            <PromptInput
              name="password"
              placeholder="Enovia Password"
              control={control}
              isPassword
            />
            <Button onClick={onSubmit}>Log In</Button>
            <DismissText onClick={onDismiss}>Click here to dismiss</DismissText>
          </PromptContainer>
        </PromptBackgroundContainer>
      )}
    </>
  );
};
export default CredentialPrompt;
