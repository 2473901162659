import {
  GetFiberLot,
  GetFiberLots,
  CreateFiberLot,
  UpdateFiberLot,
  DeleteFiberLot,
  GetFiberLotFulls,
} from "~/graphql";
import type {
  CreateFiberLotInput,
  CreateFiberLotMutationResultData,
  DeleteFiberLotMutationResultData,
  FiberLotFull,
  FiberLotQueryResultData,
  FiberLotsQueryResultData,
  UpdateFiberLotInput,
  UpdateFiberLotMutationResultData,
} from "~/types";

import {
  makeCreateEntityMutationHook,
  makeDeleteEntityMutationHook,
  makeGetEntitiesQueryHook,
  makeGetEntityByIdQueryHook,
  makeUpdateEntityMutationHook,
} from "../utils";





export const useGetFiberLotQuery =
  makeGetEntityByIdQueryHook<FiberLotQueryResultData>(GetFiberLot);

export const useGetFiberLotFullsQuery =
makeGetEntitiesQueryHook<FiberLotsQueryResultData<FiberLotFull>>(GetFiberLotFulls);

export const useGetFiberLotsQuery =
  makeGetEntitiesQueryHook<FiberLotsQueryResultData>(GetFiberLots);

export const useCreateFiberLotMutation = makeCreateEntityMutationHook<
  CreateFiberLotMutationResultData,
  CreateFiberLotInput
>(CreateFiberLot);

export const useUpdateFiberLotMutation = makeUpdateEntityMutationHook<
  UpdateFiberLotMutationResultData,
  UpdateFiberLotInput
>(UpdateFiberLot);

export const useDeleteFiberLotMutation =
  makeDeleteEntityMutationHook<DeleteFiberLotMutationResultData>(
    DeleteFiberLot,
  );
