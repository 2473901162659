import { gql } from "@kv/apollo-client";



export const ResinCategoryFragment = gql`
  fragment ResinCategoryFragment on ResinCategory {
    id
    name
    createdAt
  }
`;

export const GetResinCategory = gql`
  query GetResinCategory($id: ID!) {
    resinCategory(id: $id) {
      ...ResinCategoryFragment
    }
  }
  ${ResinCategoryFragment}
`;

export const GetResinCategories = gql`
  query GetResinCategories($sort: [String]) {
    resinCategories(sort: $sort) {
      ...ResinCategoryFragment
    }
  }
  ${ResinCategoryFragment}
`;

export const CreateResinCategory = gql`
  mutation CreateResinCategory($input: CategoryInput!) {
    createResinCategory(input: $input) {
      ...ResinCategoryFragment
    }
  }
  ${ResinCategoryFragment}
`;

export const UpdateResinCategory = gql`
  mutation UpdateResinCategory($id: ID!, $input: CategoryInput!) {
    updateResinCategory(id: $id, input: $input) {
      ...ResinCategoryFragment
    }
  }
  ${ResinCategoryFragment}
`;

export const DeleteResinCategory = gql`
  mutation DeleteResinCategory($id: ID!) {
    deleteResinCategory(id: $id) {
      id
    }
  }
`;
