import axios from "axios";
import type {
  FC } from "react";
import {
  createContext,
  useContext,
  useMemo,
  useCallback,
  useState,
} from "react";
import { SERVER_API_URL } from "~/constants";

import {
  DISABLE_ENOVIA,
  LS_ENOVIA_IGNORE,
  LS_ENOVIA_USERNAME,
  LS_ENOVIA_PASSWORD,
} from "./constants";
import CredentialPrompt from "./credentialPrompt";
import type { EnoviaWSFindAllResponse } from "./types";
import { useAuthTokensContext } from "..";





interface EnoviaContextProps {
  promptCredentials: () => Promise<void>;
  saveCredentials: (username: string, password: string) => Promise<void>;
  getByName: (name: string) => Promise<Record<string, string>>;
  encrypt: <T extends string | string[]>(data: T) => Promise<T>;
  wsFindAll: (
    filters: Record<string, string>,
  ) => Promise<EnoviaWSFindAllResponse>;
}

const defaultContextValue: EnoviaContextProps = {
  promptCredentials: () => new Promise(resolve => resolve()),
  saveCredentials: (username, password) => new Promise(resolve => resolve()),
  getByName: name => new Promise(resolve => resolve({ name })),
  encrypt: data => new Promise(resolve => resolve(data)),
  wsFindAll: filters => new Promise(resolve => resolve({})),
};

const EnoviaContext = createContext(defaultContextValue);

const retryTime = 1000;

export const EnoviaProvider: FC = ({ children }) => {
  const { authToken, loading: authTokenLoading } = useAuthTokensContext();
  const [promptVisible, setPromptVisible] = useState(false);
  const [promptCredentialsResolve, setPromptCredentialsResolve] = useState<
  ((value: void | PromiseLike<void>) => void)[]
    >([]);

  // Prompts for the credentials to be entered
  const promptCredentials = useCallback(
    () =>
      new Promise<void>(resolve => {
        // Save as array because otherwise react's state calls the function and resolves it
        setPromptCredentialsResolve([resolve]);
        setPromptVisible(true);
      }),
    [],
  );

  // Takes data that is passed to it and returns the encrypted version back
  const encrypt = useCallback(
    <T extends string | string[]>(data: T) =>
      new Promise<T>((resolve, reject) => {
        if (authTokenLoading) {
          setTimeout(() => {
            encrypt(data)
              .then(response => resolve(response))
              .catch(error => reject(error));
          }, retryTime);

          return;
        }

        if (data.length === 0) { return reject("Invalid data to be encrypted"); }

        axios
          .post(
            `${SERVER_API_URL}/enovia/encrypt`,
            {
              data: data,
            },
            {
              headers: {
                Authorization: `Bearer ${authToken}`,
              },
            },
          )
          .then(response => {
            if (response?.data) { resolve(response.data); } else { reject("Invalid data"); }
          })
          .catch(error => reject(error));
      }),
    [authToken, authTokenLoading],
  );

  // Gets the credentials from storage
  // If they are not present, then prompts for them
  const getCredentials = useCallback(
    () =>
      new Promise<string[]>((resolve, reject) => {
        if (DISABLE_ENOVIA && localStorage.getItem(LS_ENOVIA_IGNORE) !== "0") { localStorage.setItem(LS_ENOVIA_IGNORE, "1"); }
        const ignored = localStorage.getItem(LS_ENOVIA_IGNORE) === "1";
        if (ignored) { return reject("Enovia Integration is disabled for this session"); }

        const username = localStorage.getItem(LS_ENOVIA_USERNAME);
        const password = localStorage.getItem(LS_ENOVIA_PASSWORD);
        if (!username || !password) {
          promptCredentials().then(() =>
            getCredentials()
              .then(resp => resolve(resp))
              .catch(error => reject(error)),
          );

          return;
        }
        resolve([username, password]);
      }),
    [promptCredentials],
  );

  // Handles enovioa errors and returns if the call should be attempted again
  const handleEnoviaErrors = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (error: any) =>
      new Promise<boolean>((resolve, reject) => {
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        if (error?.response?.status === 401) {
          if (DISABLE_ENOVIA && localStorage.getItem(LS_ENOVIA_IGNORE) !== "0") { localStorage.setItem(LS_ENOVIA_IGNORE, "1"); }
          if (localStorage.getItem(LS_ENOVIA_IGNORE) === "1") {
            reject("Enovia Integration is disabled for this session");
          } else {
            promptCredentials().then(() => {
              resolve(true);
            });
          }

          return;
        }
        resolve(false);
      }),
    [promptCredentials],
  );

  const wsLogin = useCallback(
    () =>
      new Promise<void>(async (resolve, reject) => {
        const [username, password] = (await getCredentials().catch(error =>
          reject(error),
        )) ?? ["", ""];

        axios
          .post(
            `${SERVER_API_URL}/enovia/ws/login`,
            {},
            {
              headers: {
                "Authorization": `Bearer ${authToken}`,
                "X-Enovia-Username": username,
                "X-Enovia-Password": password,
              },
            },
          )
          .then(() => resolve())
          .catch(() => {
            promptCredentials().then(() => resolve());
          });
      }),
    [authToken, getCredentials, promptCredentials],
  );

  const wsFindAll = useCallback(
    (filters: Record<string, string>) =>
      new Promise<EnoviaWSFindAllResponse>(async (resolve, reject) => {
        if (authTokenLoading) {
          setTimeout(() => {
            wsFindAll(filters)
              .then(response => resolve(response))
              .catch(error => reject(error));
          }, retryTime);

          return;
        }

        axios
          .post(
            `${SERVER_API_URL}/enovia/ws/find_all`,
            { filters },
            {
              headers: {
                Authorization: `Bearer ${authToken}`,
              },
            },
          )
          .then(response => {
            if (response?.data) { resolve(response.data); } else { reject("No data provided"); }
          })
          .catch(error => {
            handleEnoviaErrors(error)
              .then(shouldRetry => {
                if (shouldRetry) {
                  wsFindAll(filters)
                    .then(response => resolve(response))
                    .catch(newError => reject(newError));
                } else { reject(error); }
              })
              .catch(newError => reject(newError));
          });
      }),
    [authToken, authTokenLoading, handleEnoviaErrors],
  );

  // Performs the getByName query against the enovia backend
  const getByName = useCallback(
    (name: string) => {
      const encodedName = encodeURIComponent(name);

      return new Promise<Record<string, string>>(async (resolve, reject) => {
        if (authTokenLoading) {
          setTimeout(() => {
            getByName(name)
              .then(response => resolve(response))
              .catch(error => reject(error));
          }, retryTime);

          return;
        }

        axios
          .get(`${SERVER_API_URL}/enovia/name/${encodedName}`, {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          })
          .then(response => {
            if (response?.data) { resolve(response.data); } else { reject("No data provided"); }
          })
          .catch(error => {
            handleEnoviaErrors(error)
              .then(shouldRetry => {
                if (shouldRetry) {
                  getByName(name)
                    .then(response => resolve(response))
                    .catch(newError => reject(newError));
                } else { reject(error); }
              })
              .catch(newError => reject(newError));
          });
      });
    },
    [authToken, authTokenLoading, handleEnoviaErrors],
  );

  // Saves encrypted credentials in the localstorage
  const saveCredentials = useCallback(
    (username: string, password: string) =>
      new Promise<void>((resolve, reject) => {
        encrypt([username, password])
          .then(response => {
            const data = response;
            localStorage.setItem(LS_ENOVIA_USERNAME, data[0]);
            localStorage.setItem(LS_ENOVIA_PASSWORD, data[1]);
            resolve();
          })
          .catch(error => reject(error));
      }),
    [encrypt],
  );

  // This is when the "Log In" button is clicked on the Enovia Log In Prompt
  const onPromptSubmit = useCallback(
    (username: string, password: string) => {
      setPromptVisible(false);
      saveCredentials(username, password)
        .then(() => {
          if (promptCredentialsResolve.length > 0) {
            wsLogin().then(() => promptCredentialsResolve[0]());
          }
        })
        .catch(() => setPromptVisible(true));
    },
    [promptCredentialsResolve, saveCredentials, wsLogin],
  );

  const onPromptDismiss = useCallback(() => {
    setPromptVisible(false);
    localStorage.setItem(LS_ENOVIA_IGNORE, "1");
    if (promptCredentialsResolve.length > 0) { promptCredentialsResolve[0](); }
  }, [promptCredentialsResolve]);

  const contextValue = useMemo(
    () => ({
      promptCredentials,
      saveCredentials,
      getByName,
      encrypt,
      wsFindAll,
    }),
    [promptCredentials, saveCredentials, getByName, encrypt, wsFindAll],
  );

  return (
    <EnoviaContext.Provider value={contextValue}>
      <CredentialPrompt
        promptVisible={promptVisible}
        onSubmit={onPromptSubmit}
        onDismiss={onPromptDismiss}
      >
        {children}
      </CredentialPrompt>
    </EnoviaContext.Provider>
  );
};

export const useEnoviaContext = () => useContext(EnoviaContext);

export const useEnovia = () => {
  const ctx = useEnoviaContext();

  if (!ctx) {
    throw new Error(
      "The `useEnovia` hook must be called from a descendent of the `EnoviaProvider`.",
    );
  }

  return {
    promptCredentials: ctx.promptCredentials,
    saveCredentials: ctx.saveCredentials,
    getByName: ctx.getByName,
    encrypt: ctx.encrypt,
    wsFindAll: ctx.wsFindAll,
  };
};

export * from "./hooks";
export * from "./types";
