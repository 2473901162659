import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import type { FC } from "react";

import { Container } from "./StyledComponents";



export interface LoadingIndicatorProps {
  className?: string;
  iconClassName?: string;
  text?: string;
  iconSpin?: boolean;
  fullScreen?: boolean;
}

const LoadingIndicator: FC<LoadingIndicatorProps> = ({
  className,
  iconClassName,
  text = "Loading...",
  iconSpin = true,
}) => (
  <Container className={className}>
    <Spin
      size="large"
      tip={text}
      indicator={<LoadingOutlined className={iconClassName} spin={iconSpin} />}
    />
  </Container>
);

export default LoadingIndicator;
