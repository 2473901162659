import { gql } from "@kv/apollo-client";

import { FiberChemicalTestGeneralBasicFragment } from "./fiberChemicalTestGeneral";
import { FiberChemicalTestIonBasicFragment } from "./fiberChemicalTestIon";
import { FiberFingerprintTestKajaaniBasicFragment } from "./fiberFingerprintTestKajaani";
import { FiberFingerprintTestValmetFs5BasicFragment } from "./fiberFingerprintTestValmetFs5";
import { FiberGpsSoiClassificationBasicFragment } from "./fiberGpsSoiClassification";



export const FiberLotFragment = gql`
  fragment FiberLotFragment on FiberLot {
    id
    lotNumber
    createdAt
    updatedAt
    pulpFiber{
      id
    }
    featureEnableFingerprintTesting
    featureEnableChemicalTesting
  }
`;

export const FiberLotFullFragment = gql`
  fragment FiberLotFullFragment on FiberLot {
    id
    lotNumber
    createdAt
    updatedAt
    pulpFiber{
      id
    }
    featureEnableFingerprintTesting
    featureEnableChemicalTesting
    kajaaniTest{
      ...FiberFingerprintTestKajaaniBasicFragment
    }
    valmetFs5Test{
      ...FiberFingerprintTestValmetFs5BasicFragment
    }
    chemicalTestIons{
      ...FiberChemicalTestIonBasicFragment
    }
    generalChemTest{
      ...FiberChemicalTestGeneralBasicFragment
    }
    gpsSoiClassification{
      ...FiberGpsSoiClassificationBasicFragment
    }
  }
  ${FiberFingerprintTestKajaaniBasicFragment}
  ${FiberFingerprintTestValmetFs5BasicFragment}
  ${FiberChemicalTestIonBasicFragment}
  ${FiberChemicalTestGeneralBasicFragment}
  ${FiberGpsSoiClassificationBasicFragment}
`;

export const GetFiberLot = gql`
  query GetFiberLot($id: ID!) {
    fiberLot(id: $id) {
      ...FiberLotFragment
    }
  }
  ${FiberLotFragment}
`;

export const GetFiberLotFulls = gql`
  query GetFiberLots($filters: FiberLotFilter, $sort: [String], $pagination: PaginationInput) {
    fiberLots(filters: $filters, sort: $sort, pagination: $pagination) {
      ...FiberLotFullFragment
    }
  }
  ${FiberLotFullFragment}
`;



export const GetFiberLots = gql`
  query GetFiberLots($filters: FiberLotFilter, $sort: [String], $pagination: PaginationInput) {
    fiberLots(filters: $filters, sort: $sort, pagination: $pagination) {
      ...ResinLotFragment
    }
  }
  ${FiberLotFragment}
`;


export const CreateFiberLot = gql`
  mutation CreateFiberLot($input: FiberLotInput!) {
    createFiberLot(input: $input) {
      ...FiberLotFragment
    }
  }
  ${FiberLotFragment}
`;

export const UpdateFiberLot = gql`
  mutation UpdateFiberLot($id: ID!, $input: FiberLotInput!) {
    updateFiberLot(id: $id, input: $input) {
      ...FiberLotFragment
    }
  }
  ${FiberLotFragment}
`;

export const DeleteFiberLot = gql`
  mutation DeleteFiberLot($id: ID!) {
    deleteFiberLot(id: $id) {
      id
    }
  }
`;
