import {
  GetFiberFingerprintTestValmetFs5,
  GetFiberFingerprintTestValmetFs5s,
  CreateFiberFingerprintTestValmetFs5,
  UpdateFiberFingerprintTestValmetFs5,
  DeleteFiberFingerprintTestValmetFs5,
} from "~/graphql";
import type {
  CreateFiberFingerprintTestValmetFs5Input,
  CreateFiberFingerprintTestValmetFs5MutationResultData,
  DeleteFiberFingerprintTestValmetFs5MutationResultData,
  FiberFingerprintTestValmetFs5QueryResultData,
  FiberFingerprintTestValmetFs5sQueryResultData,
  UpdateFiberFingerprintTestValmetFs5Input,
  UpdateFiberFingerprintTestValmetFs5MutationResultData,
} from "~/types";

import {
  makeCreateEntityMutationHook,
  makeDeleteEntityMutationHook,
  makeGetEntitiesQueryHook,
  makeGetEntityByIdQueryHook,
  makeUpdateEntityMutationHook,
} from "../utils";





export const useGetFiberFingerprintTestValmetFs5Query =
  makeGetEntityByIdQueryHook<FiberFingerprintTestValmetFs5QueryResultData>(GetFiberFingerprintTestValmetFs5);

export const useGetFiberFingerprintTestValmetFs5sQuery =
  makeGetEntitiesQueryHook<FiberFingerprintTestValmetFs5sQueryResultData>(GetFiberFingerprintTestValmetFs5s);

export const useCreateFiberFingerprintTestValmetFs5Mutation = makeCreateEntityMutationHook<
  CreateFiberFingerprintTestValmetFs5MutationResultData,
  CreateFiberFingerprintTestValmetFs5Input
>(CreateFiberFingerprintTestValmetFs5);

export const useUpdateFiberFingerprintTestValmetFs5Mutation = makeUpdateEntityMutationHook<
  UpdateFiberFingerprintTestValmetFs5MutationResultData,
  UpdateFiberFingerprintTestValmetFs5Input
>(UpdateFiberFingerprintTestValmetFs5);

export const useDeleteFiberFingerprintTestValmetFs5Mutation =
  makeDeleteEntityMutationHook<DeleteFiberFingerprintTestValmetFs5MutationResultData>(
    DeleteFiberFingerprintTestValmetFs5,
  );
