/* eslint-disable @typescript-eslint/no-explicit-any */
import { find } from "lodash";
import type { MutableRefObject } from "react";
import { useCallback } from "react";
import { useToasts } from "react-toast-notifications";
import { utils, write } from "xlsx";
import type {
  EvaluationToolComparisonProperties,
  EvaluationToolDatum,
  RowType,
} from "~/components/EvaluationTool";



interface ExportDataType extends Partial<RowType> {
  [key: string]: any;
}

function exportDataHelperLocal(fileName: string, data: any) {
  const workbook = utils.book_new();
  const worksheet = utils.json_to_sheet(data);

  utils.book_append_sheet(workbook, worksheet);

  const buffer = write(workbook, { type: "buffer", bookType: "xlsx" });

  const url = window.URL.createObjectURL(new Blob([buffer]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.append(link);
  link.click();
  link.remove();
}

function getNewPropertyKey(
  data: EvaluationToolDatum<EvaluationToolComparisonProperties>,
  property: ExportDataType,
) {
  const formattedName = data.datumName.replaceAll("'", "");

  return !Object.keys(property).includes(`${formattedName}`)
    ? `${formattedName}`
    : `${formattedName} (${Object.keys(property).reduce(
      (acc, key) => (key.includes(formattedName) ? acc + 1 : acc),
      0,
    )})`;
}

function formatFiberLotsData(
  data: string | number | { key: string; value: string | number }[],
) {
  if (!Array.isArray(data)) {
    return data;
  }

  return data.map(({ key, value }) => `${key}: ${value}`).join("\n ");
}

export function useDataExporter() {
  const { addToast } = useToasts();

  const downloadData = useCallback(
    (data: any[], fileName: string) => exportDataHelperLocal(fileName, data),
    [],
  );

  const handleResinEvaluateExport = useCallback(
    (
      dataRef: MutableRefObject<RowType[] | undefined>,
      comparisonData:
        | EvaluationToolDatum<EvaluationToolComparisonProperties>[]
        | undefined,
      fileName: string,
    ) => {
      const dataToExport: ExportDataType[] = dataRef.current ?? [];

      const formattedData: ExportDataType[] = [];

      if (!dataToExport || !comparisonData) {
        return addToast("No data available for export", {
          appearance: "warning",
          autoDismissTimeout: 5000,
        });
      }

      try {
        dataToExport.forEach(property => {
          // Delete the key field (Property field is the same), and then replace ids with resin supplier & trade name
          delete property?.key;
          const resinIdValues = Object.entries(property).filter(
            entry =>
              !["explanation", "property", "reference-film"].includes(
                entry[0].toLowerCase(),
              ),
          );

          const newProperty: ExportDataType = {
            property: property.property,
          };

          // If exporting film table, there will be a reference film
          if (property["reference-film"]) {
            newProperty["reference-film"] = property["reference-film"].value;
          }

          resinIdValues.forEach(comparedResin => {
            const matchingResin = comparisonData?.find(
              resin => resin.id === comparedResin[0],
            );
            if (!matchingResin) {
              return;
            }

            newProperty[getNewPropertyKey(matchingResin, newProperty)] =
              comparedResin[1].value;
          });

          // Set explanation last, we want this at the end of our final excel
          newProperty["explanation"] = property.explanation;

          formattedData.push(newProperty);
        });
      } catch {
        return addToast("Error parsing datatable", {
          appearance: "error",
          autoDismissTimeout: 5000,
        });
      }

      exportDataHelperLocal(fileName, formattedData);
    },
    [addToast],
  );

  const handleFiberCompareExport = useCallback(
    (
      dataRef: MutableRefObject<RowType[] | undefined>,
      comparisonData:
        | EvaluationToolDatum<EvaluationToolComparisonProperties>[]
        | undefined,
      fileName: string,
    ) => {
      const dataToExport: ExportDataType[] = dataRef.current ?? [];

      const formattedData: ExportDataType[] = [];

      if (!dataToExport || !comparisonData) {
        return addToast("No data available for export", {
          appearance: "warning",
          autoDismissTimeout: 5000,
        });
      }

      try {
        dataToExport.forEach(property => {
          // Delete the key field (Property field is the same), and then replace ids with resin supplier & trade name
          delete property?.key;
          const fiberIdValues = Object.entries(property).filter(
            entry =>
              !["explanation", "property"].includes(entry[0].toLowerCase()),
          );

          const newProperty: ExportDataType = {
            property: property.property,
          };

          fiberIdValues.forEach(comparedFiber => {
            const matchingFiber = find(comparisonData, [
              "id",
              comparedFiber[0],
            ]);
            if (!matchingFiber) {
              return;
            }
            const propName = newProperty?.property ?? "";
            const hasRawData = Object.keys(
              matchingFiber?.rawPropsData ?? {},
            ).includes(propName);

            newProperty[getNewPropertyKey(matchingFiber, newProperty)] =
              hasRawData
                ? formatFiberLotsData(
                  matchingFiber?.rawPropsData?.[propName] ?? [],
                )
                : comparedFiber[1].value;
          });

          formattedData.push(newProperty);
        });
      } catch {
        return addToast("Error parsing datatable", {
          appearance: "error",
          autoDismissTimeout: 5000,
        });
      }

      exportDataHelperLocal(fileName, formattedData);
    },
    [addToast],
  );

  return {
    downloadData,
    handleResinEvaluateExport,
    handleFiberCompareExport,
  };
}
