import type { FC } from "react";
import { Switch, Route } from "react-router-dom";
import AuthenticatedContent from "~/components/AuthenticatedContent";
import NonAuthenticatedContent from "~/components/NonAuthenticatedContent";
import { RoutePaths } from "~/enums";

import AsyncPage from "./AsyncPage";



const AppRoutes: FC = () => (
  <Switch>
    <Route path={RoutePaths.LOGIN}>
      <NonAuthenticatedContent>
        <AsyncPage page="Login" />
      </NonAuthenticatedContent>
    </Route>

    <Route path={RoutePaths.ENOVIA_TEST}>
      <AuthenticatedContent>
        <AsyncPage page="EnoviaTest" />
      </AuthenticatedContent>
    </Route>

    <Route path={RoutePaths.RIGIDS_RESIN_PDF}>
      <AuthenticatedContent>
        <AsyncPage page="Rigids/PDF" />
      </AuthenticatedContent>
    </Route>

    <Route path={RoutePaths.RIGIDS_RESIN_RISK_ADVISOR_DETAILS}>
      <AuthenticatedContent>
        <AsyncPage page="Rigids/RiskAdvisorDetails" />
      </AuthenticatedContent>
    </Route>

    <Route path={RoutePaths.RIGIDS_RESIN_RISK_ADVISOR}>
      <AuthenticatedContent>
        <AsyncPage page="Rigids/RiskAdvisor" />
      </AuthenticatedContent>
    </Route>

    <Route path={RoutePaths.RIGIDS_RESIN_DETAILS}>
      <AuthenticatedContent>
        <AsyncPage page="Rigids/ResinDetails" />
      </AuthenticatedContent>
    </Route>

    <Route path={RoutePaths.RIGIDS_RESIN_GATE_THREE}>
      <AuthenticatedContent>
        <AsyncPage page="Rigids/GateThree" />
      </AuthenticatedContent>
    </Route>

    <Route path={RoutePaths.RIGIDS_RESIN_GATE_TWO}>
      <AuthenticatedContent>
        <AsyncPage page="Rigids/NewLot" />
      </AuthenticatedContent>
    </Route>

    <Route path={[RoutePaths.RIGIDS_RESIN_NEW]}>
      <AuthenticatedContent>
        <AsyncPage page="Rigids/NewResin" />
      </AuthenticatedContent>
    </Route>

    <Route path={RoutePaths.RIGIDS_DASHBOARD}>
      <AuthenticatedContent>
        <AsyncPage page="Rigids/Dashboard" />
      </AuthenticatedContent>
    </Route>

    <Route path={RoutePaths.FLEXIBLES_RESIN_PDF}>
      <AuthenticatedContent>
        <AsyncPage page="Flexibles/PDF" />
      </AuthenticatedContent>
    </Route>

    <Route path={RoutePaths.FLEXIBLES_RESIN_RISK_ADVISOR_DETAILS}>
      <AuthenticatedContent>
        <AsyncPage page="Flexibles/RiskAdvisorDetails" />
      </AuthenticatedContent>
    </Route>

    <Route path={RoutePaths.FLEXIBLES_RESIN_RISK_ADVISOR}>
      <AuthenticatedContent>
        <AsyncPage page="Flexibles/RiskAdvisor" />
      </AuthenticatedContent>
    </Route>

    <Route path={RoutePaths.FLEXIBLES_RESIN_DETAILS}>
      <AuthenticatedContent>
        <AsyncPage page="Flexibles/ResinDetails" />
      </AuthenticatedContent>
    </Route>

    <Route path={RoutePaths.FLEXIBLES_RESIN_GATE_THREE}>
      <AuthenticatedContent>
        <AsyncPage page="Flexibles/GateThree" />
      </AuthenticatedContent>
    </Route>

    <Route path={RoutePaths.FLEXIBLES_RESIN_GATE_TWO}>
      <AuthenticatedContent>
        <AsyncPage page="Flexibles/NewFilm" />
      </AuthenticatedContent>
    </Route>

    <Route path={[RoutePaths.FLEXIBLES_RESIN_NEW]}>
      <AuthenticatedContent>
        <AsyncPage page="Flexibles/NewResin" />
      </AuthenticatedContent>
    </Route>

    <Route path={RoutePaths.FLEXIBLES_FILM_EVALUATION_TOOL}>
      <AuthenticatedContent>
        <AsyncPage page="Flexibles/FilmEvaluationTool" />
      </AuthenticatedContent>
    </Route>

    <Route path={RoutePaths.FLEXIBLES_RESIN_EVALUATION_TOOL}>
      <AuthenticatedContent>
        <AsyncPage page="Flexibles/ResinEvaluationTool" />
      </AuthenticatedContent>
    </Route>

    <Route path={RoutePaths.FLEXIBLES_DASHBOARD}>
      <AuthenticatedContent>
        <AsyncPage page="Flexibles/Dashboard" />
      </AuthenticatedContent>
    </Route>

    <Route path={RoutePaths.FIBERS_FIBER_NEW}>
      <AuthenticatedContent>
        <AsyncPage page="Fibers/NewFiber" />
      </AuthenticatedContent>
    </Route>

    <Route path={RoutePaths.FIBERS_FIBER_GATE_TWO_A}>
      <AuthenticatedContent>
        <AsyncPage page="Fibers/NewLot" />
      </AuthenticatedContent>
    </Route>

    <Route path={RoutePaths.FIBERS_FIBER_GATE_TWO_B}>
      <AuthenticatedContent>
        <AsyncPage page="Fibers/NewPart" />
      </AuthenticatedContent>
    </Route>

    <Route path={RoutePaths.FIBERS_FIBER_GATE_THREE}>
      <AuthenticatedContent>
        <AsyncPage page="Fibers/GateThree" />
      </AuthenticatedContent>
    </Route>

    <Route path={RoutePaths.FIBERS_FIBER_DETAILS}>
      <AuthenticatedContent>
        <AsyncPage page="Fibers/FiberDetails" />
      </AuthenticatedContent>
    </Route>

    <Route path={RoutePaths.FIBERS_FIBER_EVALUATION_TOOL}>
      <AuthenticatedContent>
        <AsyncPage page="Fibers/FiberEvaluationTool" />
      </AuthenticatedContent>
    </Route>

    <Route path={RoutePaths.FIBERS_FIBER_PDF}>
      <AuthenticatedContent>
        <AsyncPage page="Fibers/PDF" />
      </AuthenticatedContent>
    </Route>

    <Route path={RoutePaths.FIBERS_PART_EVALUATION_TOOL}>
      <AuthenticatedContent>
        <AsyncPage page="Fibers/PartEvaluationTool" />
      </AuthenticatedContent>
    </Route>

    <Route path={RoutePaths.FIBERS_FIBER_PARTS_EVALUATION_TOOL}>
      <AuthenticatedContent>
        <AsyncPage page="Fibers/FiberPartEvaluationTool" />
      </AuthenticatedContent>
    </Route>

    <Route path={RoutePaths.FIBERS_DASHBOARD}>
      <AuthenticatedContent>
        <AsyncPage page="Fibers/Dashboard" />
      </AuthenticatedContent>
    </Route>




    <Route path={RoutePaths.SELECTOR}>
      <AuthenticatedContent>
        <AsyncPage page="Selector" />
      </AuthenticatedContent>
    </Route>
  </Switch>
);

export default AppRoutes;
