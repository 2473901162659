import {
  GetFiberFingerprintTestKajaani,
  GetFiberFingerprintTestKajaanis,
  CreateFiberFingerprintTestKajaani,
  UpdateFiberFingerprintTestKajaani,
  DeleteFiberFingerprintTestKajaani,
} from "~/graphql";
import type {
  CreateFiberFingerprintTestKajaaniInput,
  CreateFiberFingerprintTestKajaaniMutationResultData,
  DeleteFiberFingerprintTestKajaaniMutationResultData,
  FiberFingerprintTestKajaaniQueryResultData,
  FiberFingerprintTestKajaanisQueryResultData,
  UpdateFiberFingerprintTestKajaaniInput,
  UpdateFiberFingerprintTestKajaaniMutationResultData,
} from "~/types";

import {
  makeCreateEntityMutationHook,
  makeDeleteEntityMutationHook,
  makeGetEntitiesQueryHook,
  makeGetEntityByIdQueryHook,
  makeUpdateEntityMutationHook,
} from "../utils";





export const useGetFiberFingerprintTestKajaaniQuery =
  makeGetEntityByIdQueryHook<FiberFingerprintTestKajaaniQueryResultData>(GetFiberFingerprintTestKajaani);

export const useGetFiberFingerprintTestKajaanisQuery =
  makeGetEntitiesQueryHook<FiberFingerprintTestKajaanisQueryResultData>(GetFiberFingerprintTestKajaanis);

export const useCreateFiberFingerprintTestKajaaniMutation = makeCreateEntityMutationHook<
  CreateFiberFingerprintTestKajaaniMutationResultData,
  CreateFiberFingerprintTestKajaaniInput
>(CreateFiberFingerprintTestKajaani);

export const useUpdateFiberFingerprintTestKajaaniMutation = makeUpdateEntityMutationHook<
  UpdateFiberFingerprintTestKajaaniMutationResultData,
  UpdateFiberFingerprintTestKajaaniInput
>(UpdateFiberFingerprintTestKajaani);

export const useDeleteFiberFingerprintTestKajaaniMutation =
  makeDeleteEntityMutationHook<DeleteFiberFingerprintTestKajaaniMutationResultData>(
    DeleteFiberFingerprintTestKajaani,
  );
