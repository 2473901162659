import {
  GetResinCharacteristics,
  CreateResinCharacteristic,
  DeleteResinCharacteristic,
  UpdateResinCharacteristic,
  GetResinCharacteristic,
} from "~/graphql";
import type {
  ResinCharacteristicsQueryResultData,
  CreateResinCharacteristicInput,
  CreateResinCharacteristicMutationResultData,
  DeleteResinCharacteristicMutationResultData,
  UpdateResinCharacteristicMutationResultData,
  UpdateResinCharacteristicInput,
  ResinCharacteristicQueryResultData,
} from "~/types";

import {
  makeCreateEntityMutationHook,
  makeGetEntitiesQueryHook,
  makeDeleteEntityMutationHook,
  makeUpdateEntityMutationHook,
  makeGetEntityByIdQueryHook,
} from "../utils";




export const useGetResinCharacteristicQuery =
  makeGetEntityByIdQueryHook<ResinCharacteristicQueryResultData>(
    GetResinCharacteristic,
  );

export const useGetResinCharacteristicsQuery =
  makeGetEntitiesQueryHook<ResinCharacteristicsQueryResultData>(
    GetResinCharacteristics,
  );

export const useCreateResinCharacteristicMutation =
  makeCreateEntityMutationHook<
    CreateResinCharacteristicMutationResultData,
    CreateResinCharacteristicInput
  >(CreateResinCharacteristic);

export const useUpdateResinCharacteristicMutation =
  makeUpdateEntityMutationHook<
    UpdateResinCharacteristicMutationResultData,
    UpdateResinCharacteristicInput
  >(UpdateResinCharacteristic);

export const useDeleteResinCharacteristicMutation =
  makeDeleteEntityMutationHook<DeleteResinCharacteristicMutationResultData>(
    DeleteResinCharacteristic,
  );
