import {
  GetFiberPartBlendedComposition,
  GetFiberPartBlendedCompositions,
  CreateFiberPartBlendedComposition,
  UpdateFiberPartBlendedComposition,
  DeleteFiberPartBlendedComposition,
} from "~/graphql";
import type {
  CreateFiberPartBlendedCompositionInput,
  CreateFiberPartBlendedCompositionMutationResultData,
  DeleteFiberPartBlendedCompositionMutationResultData,
  FiberPartBlendedCompositionQueryResultData,
  FiberPartBlendedCompositionsQueryResultData,
  UpdateFiberPartBlendedCompositionInput,
  UpdateFiberPartBlendedCompositionMutationResultData,
} from "~/types";

import {
  makeCreateEntityMutationHook,
  makeDeleteEntityMutationHook,
  makeGetEntitiesQueryHook,
  makeGetEntityByIdQueryHook,
  makeUpdateEntityMutationHook,
} from "../utils";





export const useGetFiberPartBlendedCompositionQuery =
  makeGetEntityByIdQueryHook<FiberPartBlendedCompositionQueryResultData>(GetFiberPartBlendedComposition);

export const useGetFiberPartBlendedCompositionsQuery =
  makeGetEntitiesQueryHook<FiberPartBlendedCompositionsQueryResultData>(GetFiberPartBlendedCompositions);

export const useCreateFiberPartBlendedCompositionMutation = makeCreateEntityMutationHook<
  CreateFiberPartBlendedCompositionMutationResultData,
  CreateFiberPartBlendedCompositionInput
>(CreateFiberPartBlendedComposition);

export const useUpdateFiberPartBlendedCompositionMutation = makeUpdateEntityMutationHook<
  UpdateFiberPartBlendedCompositionMutationResultData,
  UpdateFiberPartBlendedCompositionInput
>(UpdateFiberPartBlendedComposition);

export const useDeleteFiberPartBlendedCompositionMutation =
  makeDeleteEntityMutationHook<DeleteFiberPartBlendedCompositionMutationResultData>(
    DeleteFiberPartBlendedComposition,
  );
