import {
  GetFiberChemicalTestIon,
  GetFiberChemicalTestIons,
  CreateFiberChemicalTestIon,
  UpdateFiberChemicalTestIon,
  DeleteFiberChemicalTestIon,
} from "~/graphql";
import type {
  CreateFiberChemicalTestIonInput,
  CreateFiberChemicalTestIonMutationResultData,
  DeleteFiberChemicalTestIonMutationResultData,
  FiberChemicalTestIonQueryResultData,
  FiberChemicalTestIonsQueryResultData,
  UpdateFiberChemicalTestIonInput,
  UpdateFiberChemicalTestIonMutationResultData,
} from "~/types";

import {
  makeCreateEntityMutationHook,
  makeDeleteEntityMutationHook,
  makeGetEntitiesQueryHook,
  makeGetEntityByIdQueryHook,
  makeUpdateEntityMutationHook,
} from "../utils";





export const useGetFiberChemicalTestIonQuery =
  makeGetEntityByIdQueryHook<FiberChemicalTestIonQueryResultData>(GetFiberChemicalTestIon);

export const useGetFiberChemicalTestIonsQuery =
  makeGetEntitiesQueryHook<FiberChemicalTestIonsQueryResultData>(GetFiberChemicalTestIons);

export const useCreateFiberChemicalTestIonMutation = makeCreateEntityMutationHook<
  CreateFiberChemicalTestIonMutationResultData,
  CreateFiberChemicalTestIonInput
>(CreateFiberChemicalTestIon);

export const useUpdateFiberChemicalTestIonMutation = makeUpdateEntityMutationHook<
  UpdateFiberChemicalTestIonMutationResultData,
  UpdateFiberChemicalTestIonInput
>(UpdateFiberChemicalTestIon);

export const useDeleteFiberChemicalTestIonMutation =
  makeDeleteEntityMutationHook<DeleteFiberChemicalTestIonMutationResultData>(
    DeleteFiberChemicalTestIon,
  );
