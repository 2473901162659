import { every, filter, isEmpty, isNil } from "lodash";
import { useMemo } from "react";

import { useGetLocationsQuery } from ".";
import type { LocationsQueryResultData } from "../types";




export const useFilteredLocations = (
  visibleLocations: Set<string> = new Set([]),
  locations: LocationsQueryResultData["locations"] = [],
) => {
  const { data: locationsData } = useGetLocationsQuery();

  const locationOptions = useMemo(
    () =>
      (!isEmpty(locations) ? locations : locationsData?.locations ?? [])
        .filter(
          location =>
            isEmpty(visibleLocations) !== visibleLocations.has(location.id),
        )
        .map(location => ({
          label: location.name,
          value: location.id,
        })),
    [locations, locationsData?.locations, visibleLocations],
  );

  return locationOptions;
};

export const useLocationInformation = (watchResinTestingLab: string) => {
  const { data: locationsData } = useGetLocationsQuery();

  const locationInformation = useMemo(() => {
    const locationUuid = watchResinTestingLab;
    const validLocations = filter(locationsData?.locations ?? [], [
      "id",
      locationUuid,
    ]);
    if (validLocations.length === 0) {
      return "Please select a location.";
    }
    const loc = validLocations[0];

    const lastLineValues = [
      loc.city,
      loc.state,
      loc.zipCode,
      loc.country,
    ].filter(value => !isNil(value) && !isEmpty(value));

    if (
      every([loc.addressLine1, loc.addressLine2, ...lastLineValues], isEmpty)
    ) {
      return "Location address not known.";
    }

    return (
      <>
        {[loc.addressLine1, loc.addressLine2].map(
          (value, index) =>
            !isNil(value) && (
              <div key={`address-line-${index + 1}`}>{value}</div>
            ),
        )}
        <div>
          {lastLineValues.map((value, index) =>
            index !== lastLineValues.length - 1 ? `${value}, ` : value,
          )}
        </div>
      </>
    );
  }, [locationsData?.locations, watchResinTestingLab]);

  return locationInformation;
};
