import { DeleteUploadedFile, GetUploadedFiles } from "~/graphql";
import type {
  DeleteUploadedFileMutationResultData,
  UploadedFilesQueryResultData,
} from "~/types";

import {
  makeDeleteEntityMutationHook,
  makeGetEntitiesQueryHook,
} from "../utils";




export const useGetUploadedFilesQuery =
  makeGetEntitiesQueryHook<UploadedFilesQueryResultData>(GetUploadedFiles);

export const useDeleteUploadedFile =
  makeDeleteEntityMutationHook<DeleteUploadedFileMutationResultData>(
    DeleteUploadedFile,
  );
