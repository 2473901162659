import { useMemo, useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";
import type { RoutePaths } from "~/enums";
import { getRouteUrlFromPath } from "~/utils";



type RouteParams = Record<string, string | undefined>;

const useGetRouteUrl = () => {
  const params = useParams<RouteParams>();

  const getRouteUrl = useCallback(
    (
      routePath: RoutePaths,
      extraParams: RouteParams = {},
      includeCurrentParams = true,
    ) =>
      getRouteUrlFromPath(routePath, {
        ...(includeCurrentParams ? params : {}),
        ...extraParams,
      }),
    [params],
  );

  return getRouteUrl;
};

export const useGetAppRouteUrl = (
  routePath: RoutePaths,
  extraParams?: RouteParams,
) => {
  const getRouteUrl = useGetRouteUrl();
  const routeUrl = useMemo(
    () => getRouteUrl(routePath, extraParams),
    [getRouteUrl, routePath, extraParams],
  );

  return routeUrl;
};

export const useGoToAppRoute = (
  routePath: RoutePaths,
  defaultParams: RouteParams = {},
  includeCurrentParams = true,
) => {
  const history = useHistory();
  const getRouteUrl = useGetRouteUrl();

  const goToRoute = useCallback(
    (
      extraParams: RouteParams = {},
      hIncludeCurrentParams = includeCurrentParams,
    ) => {
      const routeUrl = getRouteUrl(
        routePath,
        {
          ...extraParams,
          ...defaultParams,
        },
        hIncludeCurrentParams,
      );

      history.push(routeUrl);
    },
    [includeCurrentParams, getRouteUrl, routePath, defaultParams, history],
  );

  return goToRoute;
};
