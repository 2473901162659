export enum RoutePaths {
  SELECTOR = "/",
  ENOVIA_TEST = "/enovia/test",
  LOGIN = "/login",
  SHAREPOINT = "https://pcr.pg.com/",

  //Flexibles Routes
  FLEXIBLES_DASHBOARD = "/flexibles",
  FLEXIBLES_FILM_EVALUATION_TOOL = "/flexibles/film/evaluation-tool/:resinIds?",
  FLEXIBLES_RESIN_EVALUATION_TOOL = "/flexibles/resin/evaluation-tool/:resinIds",
  FLEXIBLES_RESIN_PDF = "/flexibles/pdf/resin/:resinId",
  FLEXIBLES_RESIN_NEW = "/flexibles/resin/gate/1/:resinId?/:step?",
  FLEXIBLES_RESIN_GATE_TWO = "/flexibles/resin/gate/2/:resinId/:filmId/:step?",
  FLEXIBLES_RESIN_GATE_THREE = "/flexibles/resin/gate/3/:resinId/:filmId/:step?",
  FLEXIBLES_RESIN_DETAILS = "/flexibles/resin/details/:resinId",
  FLEXIBLES_RESIN_RISK_ADVISOR = "/flexibles/resin/risk-advisor/:resinId?/:comparison?/:resinIds?",
  FLEXIBLES_RESIN_RISK_ADVISOR_DETAILS = "/flexibles/resin/risk-advisor/details/:primaryId/:secondaryId",

  //Rigids Routes
  RIGIDS_DASHBOARD = "/rigids",
  RIGIDS_RESIN_PDF = "/rigids/pdf/resin/:resinId",
  RIGIDS_RESIN_NEW = "/rigids/resin/gate/1/:resinId?/:step?",
  RIGIDS_RESIN_GATE_TWO = "/rigids/resin/gate/2/:resinId/:id/:step?",
  RIGIDS_RESIN_GATE_THREE = "/rigids/resin/gate/3/:resinId/:id/:step?",
  RIGIDS_RESIN_DETAILS = "/rigids/resin/details/:resinId",
  RIGIDS_RESIN_RISK_ADVISOR = "/rigids/resin/risk-advisor/:resinId?/:comparison?/:resinIds?",
  RIGIDS_RESIN_RISK_ADVISOR_DETAILS = "/rigids/resin/risk-advisor/details/:primaryId/:secondaryId/:geometry?",

  //Pulp Fibers Routes
  FIBERS_DASHBOARD = "/fibers",
  FIBERS_FIBER_PDF = "/fibers/pdf/fiber/:fiberId",
  FIBERS_FIBER_NEW = "/fibers/gate/1/:fiberId?/:step?",
  FIBERS_FIBER_GATE_TWO = "/fibers/gate/2/:fiberId?/:lotId?/:step?",
  FIBERS_FIBER_GATE_TWO_A = "/fibers/gate/2a/:fiberId?/:lotId?/:step?",
  FIBERS_FIBER_GATE_TWO_B = "/fibers/gate/2b/:fiberId?/:partId?/:step?",
  FIBERS_FIBER_GATE_THREE = "/fibers/gate/3/:fiberId?/:id?/:step?",
  FIBERS_FIBER_DETAILS = "/fibers/details/:fiberId",
  FIBERS_FIBER_EVALUATION_TOOL = "/fibers/evaluation-tool/fibers/:fiberIds?",
  FIBERS_PART_EVALUATION_TOOL = "/fibers/evaluation-tool/parts/:fiberIds?",
  FIBERS_FIBER_PARTS_EVALUATION_TOOL = "/fibers/evaluation-tool/fiber-parts/:fiberIds?",
}
