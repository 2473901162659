import {
  GetExecutionDataPoints,
  CreateExecutionDataPoint,
  DeleteExecutionDataPoint,
  UpdateExecutionDataPoint,
  GetExecutionDataPoint,
} from "~/graphql";
import type {
  ExecutionDataPointsQueryResultData,
  CreateExecutionDataPointInput,
  CreateExecutionDataPointMutationResultData,
  DeleteExecutionDataPointMutationResultData,
  UpdateExecutionDataPointMutationResultData,
  UpdateExecutionDataPointInput,
  ExecutionDataPointQueryResultData,
  ExecutionDataPoint,
} from "~/types";

import { makeCreateOrUpdate } from "../../createOrUpdate";
import {
  makeCreateEntityMutationHook,
  makeGetEntitiesQueryHook,
  makeDeleteEntityMutationHook,
  makeUpdateEntityMutationHook,
  makeGetEntityByIdQueryHook,
} from "../utils";





export const useGetExecutionDataPointQuery =
  makeGetEntityByIdQueryHook<ExecutionDataPointQueryResultData>(
    GetExecutionDataPoint,
  );

export const useGetExecutionDataPointsQuery =
  makeGetEntitiesQueryHook<ExecutionDataPointsQueryResultData>(
    GetExecutionDataPoints,
  );

export const useCreateExecutionDataPointMutation = makeCreateEntityMutationHook<
  CreateExecutionDataPointMutationResultData,
  CreateExecutionDataPointInput
>(CreateExecutionDataPoint);

export const useUpdateExecutionDataPointMutation = makeUpdateEntityMutationHook<
  UpdateExecutionDataPointMutationResultData,
  UpdateExecutionDataPointInput
>(UpdateExecutionDataPoint);

export const useDeleteExecutionDataPointMutation =
  makeDeleteEntityMutationHook<DeleteExecutionDataPointMutationResultData>(
    DeleteExecutionDataPoint,
  );

export const useExecutionDataPointCreateOrUpdate = makeCreateOrUpdate<
  CreateExecutionDataPointInput,
  CreateExecutionDataPointMutationResultData,
  ExecutionDataPointsQueryResultData,
  "executionDataPoints",
  UpdateExecutionDataPointMutationResultData,
  ExecutionDataPoint
>(
  useCreateExecutionDataPointMutation,
  useGetExecutionDataPointsQuery,
  useUpdateExecutionDataPointMutation,
);
