import {
  GetFiberPart,
  GetFiberParts,
  CreateFiberPart,
  UpdateFiberPart,
  DeleteFiberPart,
  GetFiberPartsFull,
} from "~/graphql";
import type {
  CreateFiberPartInput,
  CreateFiberPartMutationResultData,
  DeleteFiberPartMutationResultData,
  FiberPartFull,
  FiberPartQueryResultData,
  FiberPartsQueryResultData,
  UpdateFiberPartInput,
  UpdateFiberPartMutationResultData,
} from "~/types";

import {
  makeCreateEntityMutationHook,
  makeDeleteEntityMutationHook,
  makeGetEntitiesQueryHook,
  makeGetEntityByIdQueryHook,
  makeUpdateEntityMutationHook,
} from "../utils";





export const useGetFiberPartQuery =
  makeGetEntityByIdQueryHook<FiberPartQueryResultData>(GetFiberPart);

export const useGetFiberPartsQuery =
  makeGetEntitiesQueryHook<FiberPartsQueryResultData>(GetFiberParts);

export const useGetFiberPartsFullQuery =
  makeGetEntitiesQueryHook<FiberPartsQueryResultData<FiberPartFull>>(GetFiberPartsFull);

export const useCreateFiberPartMutation = makeCreateEntityMutationHook<
  CreateFiberPartMutationResultData,
  CreateFiberPartInput
>(CreateFiberPart);

export const useUpdateFiberPartMutation = makeUpdateEntityMutationHook<
  UpdateFiberPartMutationResultData,
  UpdateFiberPartInput
>(UpdateFiberPart);

export const useDeleteFiberPartMutation =
  makeDeleteEntityMutationHook<DeleteFiberPartMutationResultData>(
    DeleteFiberPart,
  );
