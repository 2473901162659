import axios from "axios";
import { useCallback } from "react";
import type { AddToast } from "react-toast-notifications";
import { useToasts } from "react-toast-notifications";

import { DOWNLOAD_URL } from "../constants";
import { useAuthTokensContext } from "../context";



export type DownloadProgressFunction = (progressEvent: ProgressEvent) => void;

type downloadFileHelperFunction = (
  addToast: AddToast,
  fileUUID: string,
  authToken?: string,
  onChange?: DownloadProgressFunction,
) => void;

const downloadFileHelper: downloadFileHelperFunction = (
  addToast,
  fileUUID,
  authToken,
  onChange,
) => {
  axios
    .get(`${DOWNLOAD_URL}/${fileUUID}`, {
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      withCredentials: true,
      onDownloadProgress: onChange,
    })
    .then(response => {
      const file_name =
        response.headers["content-disposition"]?.replace(
          /attachment;\s?filename=/g,
          "",
        ) ?? "unknown.txt";
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", file_name);
      document.body.append(link);
      link.click();
      link.remove();
    })
    .catch(error => {
      addToast(`Failed to download the file: ${error}`, {
        appearance: "error",
        autoDismissTimeout: 5000,
      });
    });
};

export const useDownloadFile = () => {
  const { addToast } = useToasts();
  const { authToken } = useAuthTokensContext();

  const downloadFile = useCallback(
    (fileUUID: string, onChange?: DownloadProgressFunction) =>
      downloadFileHelper(addToast, fileUUID, authToken, onChange),
    [addToast, authToken],
  );

  return {
    downloadFile,
  };
};
