import type { ApolloError } from "@kv/apollo-client";
import type { FC } from "react";
import ApolloErrors from "~/components/ApolloErrors";
import LoadingIndicator from "~/components/LoadingIndicator";



export interface AsyncContentProps {
  loading?: boolean;
  error?: ApolloError | string;
  loadingText?: string;
}

const AsyncContent: FC<AsyncContentProps> = ({
  children,
  loading,
  error,
  loadingText,
}) => {
  if (loading) {
    return <LoadingIndicator text={loadingText} />;
  }

  if (error) {
    return <ApolloErrors error={error} />;
  }

  return <>{children}</>;
};

export default AsyncContent;
