export const structuralIndexHoverText = (
  <>
    <p>
      This value is the gram weight difference compared to a reference resin
      (which has a Structural Index value of 1). Example calculation: if the
      reference resin has a Modulus of 1188.7 MPa and the new resin has a
      Modulus of 1225.4, the new resin would have a Structural Index value of
      0.97, and therefore would need to use 3% less plastic material by weight
      than the reference.
    </p>
    <p>
      Reference is recommended as Formosa now, with a Modulus (CD) value of
      1188.7 MPa.
    </p>
    <p>NA Recommended Reference: Resin C</p>
    <p>Europe Recommended Reference: Tipelin 6000</p>
  </>
);
