import { gql } from "@kv/apollo-client";

import { FiberGpsSoiClassificationBasicFragment } from "./fiberGpsSoiClassification";
import { FiberPartBlendedCompositionMaterialFragment } from "./fiberPartBlendedCompositionMaterial";
import { FiberPartTestDataBasicFragment } from "./fiberPartTestData";




const FiberPartFragment = gql`
  fragment FiberPartFragment on FiberPart {
    id
    pulpFiber{
      id
    }
    description
    partNumber
    isWet
    isRefiningTesting
  }
`;

export const GetFiberPartsFull = gql`
  query GetFiberPartsFull(
    $filters: FiberPartFilter
    $sort: [String]
    $pagination: PaginationInput
  ) {
    fiberParts(filters: $filters, sort: $sort, pagination: $pagination) {
      ...FiberPartFragment
      createdAt
      fiberPartTestData{
        ...FiberPartTestDataBasicFragment
      }
      blendedComposition{
        id
        totalBasisWeight
        materials{
          ...FiberPartBlendedCompositionMaterialFragment
        }
      }
      gpsSoiClassification{
      ...FiberGpsSoiClassificationBasicFragment
    }
    }
    fiberPartsCount(filters: $filters)
  }
  ${FiberPartFragment}
  ${FiberPartTestDataBasicFragment}
  ${FiberPartBlendedCompositionMaterialFragment}
  ${FiberGpsSoiClassificationBasicFragment}
`;

export const GetFiberPart = gql`
  query GetFiberPart($id: ID!) {
    fiberPart(id: $id) {
      ...FiberPartFragment
    }
  }
  ${FiberPartFragment}
`;

export const GetFiberParts = gql`
  query GetFiberParts(
    $filters: FiberPartFilter
    $sort: [String]
    $pagination: PaginationInput
  ) {
    fiberParts(filters: $filters, sort: $sort, pagination: $pagination) {
      ...FiberPartFragment
    }
    fiberPartsCount(filters: $filters)
  }
  ${FiberPartFragment}
`;

export const CreateFiberPart = gql`
  mutation CreateFiberPart($input: FiberPartInput!) {
    createFiberPart(input: $input) {
      ...FiberPartFragment
    }
  }
  ${FiberPartFragment}
`;

export const UpdateFiberPart = gql`
  mutation UpdateFiberPart($id: ID!, $input: FiberPartInput!) {
    updateFiberPart(id: $id, input: $input) {
      ...FiberPartFragment
    }
  }
  ${FiberPartFragment}
`;

export const DeleteFiberPart = gql`
  mutation DeleteFiberPart($id: ID!) {
    deleteFiberPart(id: $id) {
      id
    }
  }
`;
