import { gql } from "@kv/apollo-client";



const FiberBusinessApplicationSupplierInformationFragment = gql`
  fragment FiberBusinessApplicationSupplierInformationFragment on FiberBusinessApplicationSupplierInformation {
    id
    createdAt
    updatedAt
    fiberBusinessApplicationId
    converter
    plantLocation
    productionPlatform
    productionLine
    cavitation
    productionRate
    startDate
  }
`;

export const GetFiberBusinessApplicationSupplierInformation = gql`
  query GetFiberBusinessApplicationSupplierInformation($id: ID!) {
    fiberBusinessApplicationSupplierInformation(id: $id) {
      ...FiberBusinessApplicationSupplierInformationFragment
    }
  }
  ${FiberBusinessApplicationSupplierInformationFragment}
`;

export const GetFiberBusinessApplicationSupplierInformations = gql`
  query GetFiberBusinessApplicationSupplierInformations(
    $filters: FiberBusinessApplicationSupplierInformationFilter
    $sort: [String]
    $pagination: PaginationInput
  ) {
    fiberBusinessApplicationSupplierInformations(
      filters: $filters
      sort: $sort
      pagination: $pagination
    ) {
      ...FiberBusinessApplicationSupplierInformationFragment
    }
    fiberBusinessApplicationSupplierInformationsCount(filters: $filters)
  }
  ${FiberBusinessApplicationSupplierInformationFragment}
`;

export const CreateFiberBusinessApplicationSupplierInformation = gql`
  mutation CreateFiberBusinessApplicationSupplierInformation(
    $input: FiberBusinessApplicationSupplierInformationInput!
  ) {
    createFiberBusinessApplicationSupplierInformation(input: $input) {
      ...FiberBusinessApplicationSupplierInformationFragment
    }
  }
  ${FiberBusinessApplicationSupplierInformationFragment}
`;

export const UpdateFiberBusinessApplicationSupplierInformation = gql`
  mutation UpdateFiberBusinessApplicationSupplierInformation(
    $id: ID!
    $input: FiberBusinessApplicationSupplierInformationInput!
  ) {
    updateFiberBusinessApplicationSupplierInformation(id: $id, input: $input) {
      ...FiberBusinessApplicationSupplierInformationFragment
    }
  }
  ${FiberBusinessApplicationSupplierInformationFragment}
`;

export const DeleteFiberBusinessApplicationSupplierInformation = gql`
  mutation DeleteFiberBusinessApplicationSupplierInformation($id: ID!) {
    deleteFiberBusinessApplicationSupplierInformation(id: $id) {
      id
    }
  }
`;
