import {
  GetFiberBusinessApplication,
  GetFiberBusinessApplications,
  CreateFiberBusinessApplication,
  UpdateFiberBusinessApplication,
  DeleteFiberBusinessApplication,
} from "~/graphql";
import type {
  CreateFiberBusinessApplicationInput,
  CreateFiberBusinessApplicationMutationResultData,
  DeleteFiberBusinessApplicationMutationResultData,
  FiberBusinessApplicationQueryResultData,
  FiberBusinessApplicationsQueryResultData,
  UpdateFiberBusinessApplicationInput,
  UpdateFiberBusinessApplicationMutationResultData,
} from "~/types";

import {
  makeCreateEntityMutationHook,
  makeDeleteEntityMutationHook,
  makeGetEntitiesQueryHook,
  makeGetEntityByIdQueryHook,
  makeUpdateEntityMutationHook,
} from "../utils";



export const useGetFiberBusinessApplicationQuery =
  makeGetEntityByIdQueryHook<FiberBusinessApplicationQueryResultData>(GetFiberBusinessApplication);

export const useGetFiberBusinessApplicationsQuery =
  makeGetEntitiesQueryHook<FiberBusinessApplicationsQueryResultData>(GetFiberBusinessApplications);

export const useCreateFiberBusinessApplicationMutation = makeCreateEntityMutationHook<
  CreateFiberBusinessApplicationMutationResultData,
  CreateFiberBusinessApplicationInput
>(CreateFiberBusinessApplication);

export const useUpdateFiberBusinessApplicationMutation = makeUpdateEntityMutationHook<
  UpdateFiberBusinessApplicationMutationResultData,
  UpdateFiberBusinessApplicationInput
>(UpdateFiberBusinessApplication);

export const useDeleteFiberBusinessApplicationMutation =
  makeDeleteEntityMutationHook<DeleteFiberBusinessApplicationMutationResultData>(
    DeleteFiberBusinessApplication,
  );
