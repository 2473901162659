import axios from "axios";
import {
  LS_ENOVIA_IGNORE,
  LS_ENOVIA_PASSWORD,
  LS_ENOVIA_USERNAME,
} from "~/context/Enovia/constants";

import { SERVER_API_URL } from "../constants";




export interface GetAuthTokenHookResult {
  authToken: string;
}

export interface VerifyTokenHookResult {
  token: string;
}

export interface GetLoginURLHookResult {
  url: string;
}

export interface AuthTokenResponseData {
  token: string;
}

export interface VerifyTokenResponseData {
  valid: boolean;
}

export interface LoginURLResponseData {
  url: string;
}

export interface LogoutHookResult {
  error?: string;
}

const storageTokenKey = "user__authToken";

const getAuthToken = () =>
  new Promise<GetAuthTokenHookResult>(resolve => {
    axios
      .get<AuthTokenResponseData>(`${SERVER_API_URL}/auth/get_token`)
      .then(({ data }) => {
        const token = data?.token ?? "";

        localStorage.clear();
        localStorage.setItem(storageTokenKey, token);

        resolve({ authToken: token });
      })
      .catch(error => {
        resolve({ authToken: "" });
      });
  });

const getLoginURL = () =>
  new Promise<GetLoginURLHookResult>(resolve => {
    axios
      .get<LoginURLResponseData>(`${SERVER_API_URL}/auth/open_pingid`)
      .then(({ data }) => {
        resolve({ url: data?.url ?? "" });
      })
      .catch(error => {
        resolve({ url: "#" });
      });
  });

const verifyToken = () =>
  new Promise<VerifyTokenHookResult>(resolve => {
    axios
      .post<VerifyTokenResponseData>(
        `${SERVER_API_URL}/auth/verify`,
        {
          token: localStorage.getItem(storageTokenKey) || "",
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      )
      .then(({ data }) => {
        resolve({
          token:
            data?.valid ?? false
              ? localStorage.getItem(storageTokenKey) ?? ""
              : "",
        });
      })
      .catch(error => {
        resolve({ token: "" });
      });
  });

const logout = () =>
  new Promise<LogoutHookResult>(resolve => {
    axios
      .get(`${SERVER_API_URL}/auth/logout`)
      .then(() => {
        localStorage.removeItem(storageTokenKey);
        localStorage.removeItem(LS_ENOVIA_IGNORE);
        localStorage.removeItem(LS_ENOVIA_USERNAME);
        localStorage.removeItem(LS_ENOVIA_PASSWORD);
        resolve({ error: "" });
      })
      .catch(error => {
        resolve({ error });
      });
  });

export const useGetAuthToken = () => getAuthToken;

export const useGetLoginURL = () => getLoginURL;

export const useLogout = () => logout;

export const useVerifyToken = () => verifyToken;
