import { Modal } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { IN_DEV, IN_QA, SECOND_IN_MS } from "~/constants";
import { usePersistentValue } from "~/hooks/usePersistentState";



const QA_PROMPT_DISMISSAL_TIME_SECONDS = 3600; // 60 minutes
const QA_PROMPT_DISMISSAL_TIME =
  QA_PROMPT_DISMISSAL_TIME_SECONDS * SECOND_IN_MS;

export const QAModal = () => {
  const [qaPromptDismissedAt, setQAPromptDismissedAt] = usePersistentValue<
    Partial<number>
  >("qa-prompt-dismissed", 0);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const shouldShowModal = useMemo(
    () => isModalVisible && (IN_QA || IN_DEV),
    [isModalVisible],
  );

  const handleOk = useCallback(() => {
    setQAPromptDismissedAt(Date.now());
    setIsModalVisible(false);
  }, [setQAPromptDismissedAt]);

  useEffect(() => {
    setIsModalVisible(
      qaPromptDismissedAt + QA_PROMPT_DISMISSAL_TIME < Date.now(),
    );
  }, [qaPromptDismissedAt]);

  return (
    <Modal
      title="Welcome to PCR QA"
      visible={shouldShowModal}
      onOk={handleOk}
      cancelButtonProps={{ hidden: true }}
    >
      <p>
        This is the QA environment for PCR, so any data inputted will not be
        saved.
      </p>
      <p>
        If you wish to have your data saved during testing, be sure to use the
        production environment.{" "}
      </p>
    </Modal>
  );
};
