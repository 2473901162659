import { ApolloProvider } from "@kv/apollo-client";
import type { FC } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { apolloClient } from "~/apollo";
import { AuthTokensProvider, EnoviaProvider } from "~/context";
import store from "~/state";

import { QAModal } from "../QAModal";



const App: FC = ({ children }) => (
  <ApolloProvider client={apolloClient}>
    <Provider store={store}>
      <ToastProvider autoDismiss autoDismissTimeout={5000}>
        <BrowserRouter>
          <AuthTokensProvider>
            <EnoviaProvider>
              <QAModal />
              {children}
            </EnoviaProvider>
          </AuthTokensProvider>
        </BrowserRouter>
      </ToastProvider>
    </Provider>
  </ApolloProvider>
);

export default App;
