import { startCase } from "lodash";

import { EXTERNAL_PART_CATEGORY_ID, FLUFF_FIBER_CATEGORY_ID, GENERAL_FIBER_CATEGORY_ID } from "../constants";
import type { PulpFiber } from "../types";



export const getFiberTdsTypeName = (fiber: PulpFiber) => {
  switch (fiber?.categoryId) {
    case GENERAL_FIBER_CATEGORY_ID:
    case FLUFF_FIBER_CATEGORY_ID: {
      switch (fiber?.fiberType) {
        case "virgin": {
          return "Virgin Wood";
        }
        case "recycled": {
          return "Recycled Fiber";
        }
        case "non wood": {
          return "Alternative Fiber";
        }
        default: {
          return startCase(fiber?.fiberType);
        }
      }
    }
    case EXTERNAL_PART_CATEGORY_ID: {
      switch (fiber?.partType) {
        case "cartonboard": {
          return "CartonBoard";
        }
        case "plain paper": {
          return "Plain Paper";
        }
        case "tissue paper": {
          return "Tissue Paper";
        }
        default: {
          return "Molded Part";
        }
      }
    }
    default: {
      return "Molded Part";
    }
  }
};
