import type {
  ApolloError,
  ApolloQueryResult,
  MutationHookOptions,
  QueryHookOptions,
} from "@kv/apollo-client";
import { useCallback } from "react";

import type { MutationWithStateTuple } from "./graphql/utils";
import type {
  BaseQueryVars,
  CreateEntityMutationVars,
  MutationFunc,
  UpdateEntityMutationVars,
} from "../types";




export interface CreateOrUpdateResponse<ResultType> {
  data?: ResultType;
  error?: ApolloError;
}

type RefetchFunction<ReadResultType> = (
  variables?: Partial<BaseQueryVars> | undefined,
) => Promise<ApolloQueryResult<ReadResultType>>;

export const createOrUpdateHelper = <
  InputType,
  CreateResult,
  ReadResult extends { [K: string]: { id: string }[] },
  UpdateResult,
  ResultType,
>(
  create: MutationFunc<CreateResult, [InputType]>,
  read: RefetchFunction<ReadResult>,
  update: MutationFunc<UpdateResult, [string, InputType]>,
  options: Partial<BaseQueryVars>,
  input: InputType,
): Promise<CreateOrUpdateResponse<ResultType>> =>
  new Promise<CreateOrUpdateResponse<ResultType>>(async (resolve, reject) => {
    const { data: readData } = await read(options);

    // Get the next step of data without knowing what exactly the variable name is
    const cleanReadData = Object.values(readData ?? { data: [] })[0];

    if (cleanReadData.length > 0) {
      // Found data
      const id = cleanReadData[0].id;

      const { data: updateData, error: updateError } = await update(id, input);

      // Get the next step of data without knowing what exactly the variable name is
      const cleanUpdateData = Object.values(updateData ?? { data: [] })[0];

      if (!!cleanUpdateData || !!updateError) {
        return resolve({
          data: cleanUpdateData as ResultType,
          error: updateError,
        });
      }
    } else {
      const { data: createData, error: createError } = await create(input);

      // Get the next step of data without knowing what exactly the variable name is
      const cleanCreateData = Object.values(createData ?? { data: [] })[0];

      if (!!cleanCreateData || !!createError) {
        return resolve({
          data: cleanCreateData as ResultType,
          error: createError,
        });
      }
    }

    // This last part should in theory never be reached
    reject("Something unexpected happened...");
  });

type CreateMutationFunc<CreateResult, InputType> = (
  options?:
    | MutationHookOptions<CreateResult, CreateEntityMutationVars<InputType>>
    | undefined,
) => MutationWithStateTuple<CreateResult, [InputType]>;

type ReadFunc<ReadResult> = (
  variables?: BaseQueryVars | undefined,
  options?: QueryHookOptions<ReadResult, BaseQueryVars> | undefined,
) => {
  loading: boolean;
  error: string | ApolloError;
  data: ReadResult | undefined;
  refetch: RefetchFunction<ReadResult>;
};

type UpdateMutationFunc<UpdateResult, InputType> = (
  options?:
    | MutationHookOptions<UpdateResult, UpdateEntityMutationVars<InputType>>
    | undefined,
) => MutationWithStateTuple<UpdateResult, [string, InputType]>;

export const makeCreateOrUpdate = <
  InputType,
  CreateResult,
  ReadResult extends { [K in ReadResultKey]: { id: string }[] },
  ReadResultKey extends string,
  UpdateResult,
  ResultType,
>(
  useCreateMutation: CreateMutationFunc<CreateResult, InputType>,
  useRead: ReadFunc<ReadResult>,
  useUpdateMutation: UpdateMutationFunc<UpdateResult, InputType>,
) => {
  const useCreateOrUpdate = () => {
    const [create] = useCreateMutation();
    const { refetch: read } = useRead();
    const [update] = useUpdateMutation();

    const createOrUpdate = useCallback(
      (options: Partial<BaseQueryVars>, input: InputType) =>
        createOrUpdateHelper<
          InputType,
          CreateResult,
          ReadResult,
          UpdateResult,
          ResultType
        >(create, read, update, options, input),
      [create, read, update],
    );

    return {
      createOrUpdate,
    };
  };

  return useCreateOrUpdate;
};
