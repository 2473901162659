import { gql } from "@kv/apollo-client";



export const FiberPartTestDataBasicFragment = gql`
  fragment FiberPartTestDataBasicFragment on FiberPartTestData {
    id
    temperature
    pressure
    processingSeconds
    moldPartNumber
    moldPartWeight
    baseWeight
    moistureContent
    refiningMethod
    refiningLevel
    refiningLevelUnit
    csf
    pfr
    weightCorrected
    dryCaliper
    density
    bulk
    tensileStrengthMd
    tensileStrengthCd
    tensileModulus
    dryBurstStrength
    tearStrength
    shortSpanCompressionModulus
    threePtBendingStiffnessModulus
    roughness
    smoothness
    abrasionResistance
    contactAngle
    pickStrength
    cof
    opacity
    brightness
    whiteness
    fluorescence
    lightScatteringAbsorption
    color
    #water vapor transmission rate @ 23deg C/ 50% relative humidity
    wvtr50PctRh
    #water vapor transmission rate @ 38deg C/ 90% relative humidity
    wvtr90PctRh
    waterAbsorbancy
    greaseResistance
    airPermeation
    heatSealability
    repulpability
    sheetAdhesion
    visualImpurities
    overallRecyclability
  }
`;

const FiberPartTestDataFragment = gql`
  fragment FiberPartTestDataFragment on FiberPartTestData {
    fiberPart{
      id
    }
    ...FiberPartTestDataBasicFragment
  }

  ${FiberPartTestDataBasicFragment}
`;

export const GetFiberPartTestData = gql`
  query GetFiberPartTestData($id: ID!) {
    fiberPartTestData(id: $id) {
      ...FiberPartTestDataFragment
    }
  }
  ${FiberPartTestDataFragment}
`;

export const GetFiberPartTestDatas = gql`
  query GetFiberPartTestDatas(
    $filters: FiberPartTestDataFilter
    $sort: [String]
    $pagination: PaginationInput
  ) {
    fiberPartTestDatas(filters: $filters, sort: $sort, pagination: $pagination) {
      ...FiberPartTestDataFragment
    }
    fiberPartTestDatasCount(filters: $filters)
  }
  ${FiberPartTestDataFragment}
`;

export const CreateFiberPartTestData = gql`
  mutation CreateFiberPartTestData($input: FiberPartTestDataInput!) {
    createFiberPartTestData(input: $input) {
      ...FiberPartTestDataFragment
    }
  }
  ${FiberPartTestDataFragment}
`;

export const UpdateFiberPartTestData = gql`
  mutation UpdateFiberPartTestData($id: ID!, $input: FiberPartTestDataInput!) {
    updateFiberPartTestData(id: $id, input: $input) {
      ...FiberPartTestDataFragment
    }
  }
  ${FiberPartTestDataFragment}
`;

export const DeleteFiberPartTestData = gql`
  mutation DeleteFiberPartTestData($id: ID!) {
    deleteFiberPartTestData(id: $id) {
      id
    }
  }
`;
