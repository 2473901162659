const { REACT_APP_ENABLE_MIRAGE_SERVER, NODE_ENV, APP_ENV } = process.env;

export const IN_PROD = NODE_ENV === "production";
export const IN_DEV = NODE_ENV === "development";
export const IN_QA = APP_ENV === "staging";

export const MCP_PADDING = 8;

export const SERVER_URL = "/server";
export const SERVER_API_URL = `${SERVER_URL}/api`;
export const UPLOAD_URL = `${SERVER_API_URL}/files/upload`;
export const DOWNLOAD_URL = `${SERVER_API_URL}/files/download`;
export const GRAPHQL_URL =
  REACT_APP_ENABLE_MIRAGE_SERVER === "true"
    ? "/mirage/graphql/"
    : `${SERVER_URL}/graphql/`;

export const ENOVIA_URL = !IN_PROD
  ? "https://plmqa.pg.com/enovia"
  : "https://plmprod.pg.com/enovia";
