import type { InputProps as AntdInputProps } from "antd";
import { Input as AntdInput } from "antd";
import type { FC } from "react";
import { useCallback } from "react";
import type { Control, UseControllerReturn } from "react-hook-form";
import { Controller } from "react-hook-form";

import { FormItem } from "./StyledComponents";



const { Password: AntdPasswordInput } = AntdInput;

type PromptInputProps = AntdInputProps & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultValue?: any;
  name: string;
  isPassword?: boolean;
};

const PromptInput: FC<PromptInputProps> = ({
  control,
  defaultValue,
  name,
  isPassword,
  ...inputProps
}) => {
  const renderInput = useCallback(
    ({ field: { ref, ...fieldProps } }: UseControllerReturn) => (
      <>
        <AntdInput {...inputProps} {...fieldProps} />
      </>
    ),
    [inputProps],
  );

  const renderInputPassword = useCallback(
    ({ field: { ref, ...fieldProps } }: UseControllerReturn) => (
      <>
        <AntdPasswordInput {...inputProps} {...fieldProps} />
      </>
    ),
    [inputProps],
  );

  const render = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (args: UseControllerReturn<any, any>) => {
      if (!!isPassword) { return renderInputPassword(args); }

      return renderInput(args);
    },
    [isPassword, renderInput, renderInputPassword],
  );

  return (
    <FormItem>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={render}
      />
    </FormItem>
  );
};
export default PromptInput;
