import { gql } from "@kv/apollo-client";



const FiberBusinessApplicationFragment = gql`
  fragment FiberBusinessApplicationFragment on FiberBusinessApplication {
    id
    createdAt
    updatedAt
    businessUnit
    businessSubUnit
    brand
    shape
    part
    partSize
    partWeight
    mpmpNumber
    pgContact
    supplierInformation {
      id
      createdAt
      updatedAt
      fiberBusinessApplicationId
      converter
      plantLocation
      productionPlatform
      productionLine
      cavitation
      productionRate
      startDate
    }
  }
`;

export const GetFiberBusinessApplication = gql`
  query GetFiberBusinessApplication($id: ID!) {
    fiberBusinessApplication(id: $id) {
      ...FiberBusinessApplicationFragment
    }
  }
  ${FiberBusinessApplicationFragment}
`;

export const GetFiberBusinessApplications = gql`
  query GetFiberBusinessApplications(
    $filters: FiberBusinessApplicationFilter
    $pagination: PaginationInput
  ) {
    fiberBusinessApplications(
      filters: $filters
      pagination: $pagination
    ) {
      ...FiberBusinessApplicationFragment
    }
    fiberBusinessApplicationsCount(filters: $filters)
  }
  ${FiberBusinessApplicationFragment}
`;

export const CreateFiberBusinessApplication = gql`
  mutation CreateFiberBusinessApplication(
    $input: FiberBusinessApplicationInput!
  ) {
    createFiberBusinessApplication(input: $input) {
      ...FiberBusinessApplicationFragment
    }
  }
  ${FiberBusinessApplicationFragment}
`;

export const UpdateFiberBusinessApplication = gql`
  mutation UpdateFiberBusinessApplication(
    $id: ID!
    $input: FiberBusinessApplicationInput!
  ) {
    updateFiberBusinessApplication(id: $id, input: $input) {
      ...FiberBusinessApplicationFragment
    }
  }
  ${FiberBusinessApplicationFragment}
`;

export const DeleteFiberBusinessApplication = gql`
  mutation DeleteFiberBusinessApplication($id: ID!) {
    deleteFiberBusinessApplication(id: $id) {
      id
    }
  }
`;
