import type { Dispatch, SetStateAction } from "react";
import { useCallback, useState } from "react";



export function usePersistentState<T>(key: string, initialState: T) {
  const [state, setState] = useState(() => {
    // Using 'typeof' because it avoids throwing a ReferenceError if undefined.
    if (typeof window === "undefined") {
      return initialState;
    }
    try {
      const item = window.localStorage.getItem(key);

      return item ? (JSON.parse(item) as T) : initialState;
    } catch (error) {
      console.log(error);

      return initialState;
    }
  });

  const setPersistentState: Dispatch<SetStateAction<T>> = useCallback(
    value => {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(state) : value;
      setState(valueToStore);

      try {
        if (typeof window !== "undefined") {
          window.localStorage.setItem(key, JSON.stringify(valueToStore));
        }
      } catch (error) {
        console.log(error);
      }
    },
    [key, state, setState],
  );

  return [state, setPersistentState] as const;
}

/**
 * Similar to `usePersistentState`, but doesn't use any Reactive structures.
 */
export function usePersistentValue<T>(key: string, defaultState: T) {
  let currentValue = defaultState;

  if (typeof window !== "undefined") {
    try {
      const item = window.localStorage.getItem(key);
      if (item) {
        currentValue = JSON.parse(item);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const setPersistentState = (value: T) => {
    // Allow value to be a function so we have same API as useState
    try {
      if (typeof window !== "undefined") {
        window.localStorage.setItem(key, JSON.stringify(value));
      }
    } catch (error) {
      console.log(error);
    }
  };

  return [currentValue, setPersistentState] as const;
}
