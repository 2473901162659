import { gql } from "@kv/apollo-client";



export const PulpFiberCategoryFragment = gql`
  fragment PulpFiberCategoryFragment on PulpFiberCategory {
    id
    name
    createdAt
  }
`;

export const GetPulpFiberCategory = gql`
  query GetPulpFiberCategory($id: ID!) {
    pulpFiberCategory(id: $id) {
      ...PulpFiberCategoryFragment
    }
  }
  ${PulpFiberCategoryFragment}
`;

export const GetPulpFiberCategories = gql`
  query GetPulpFiberCategories($sort: [String]) {
    pulpFiberCategories(sort: $sort) {
      ...PulpFiberCategoryFragment
    }
  }
  ${PulpFiberCategoryFragment}
`;

export const CreatePulpFiberCategory = gql`
  mutation CreatePulpFiberCategory($input: CategoryInput!) {
    createPulpFiberCategory(input: $input) {
      ...PulpFiberCategoryFragment
    }
  }
  ${PulpFiberCategoryFragment}
`;

export const UpdatePulpFiberCategory = gql`
  mutation UpdatePulpFiberCategory($id: ID!, $input: CategoryInput!) {
    updatePulpFiberCategory(id: $id, input: $input) {
      ...PulpFiberCategoryFragment
    }
  }
  ${PulpFiberCategoryFragment}
`;

export const DeletePulpFiberCategory = gql`
  mutation DeletePulpFiberCategory($id: ID!) {
    deletePulpFiberCategory(id: $id) {
      id
    }
  }
`;
