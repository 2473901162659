import { useCallback, useEffect, useMemo, useState } from "react";

import { useEnovia } from ".";
import type { EnoviaWSFindAllResponse } from "./types";




export const useEnoviaQuery = <ReturnType>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fetch: (...args: any[]) => Promise<ReturnType>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ...defaultArgs: any[]
): {
  data?: ReturnType;
  loading: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  refetch: (...args: any[]) => void;
  error?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fullError?: any;
} => {
  const [loading, setLoading] = useState(false);

  const [disableAutoFetch, setDisableAutoFetch] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [cachedArgs, setCachedArgs] = useState<any[]>(defaultArgs);
  const [data, setData] = useState<ReturnType | undefined | null>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [fullError, setFullError] = useState<any>();

  const error = useMemo(
    () =>
      typeof fullError === "string"
        ? fullError
        : fullError?.response?.data?.message,
    [fullError],
  );

  const finalData = useMemo(() => {
    if (!data) { return undefined; }

    return data;
  }, [data]);

  const newFetch = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (...args: any[]) =>
      new Promise<void>(resolve => {
        setLoading(true);
        fetch(...(args.length > 0 ? args : cachedArgs ?? []))
          .then(returnedData => {
            setData(returnedData);
            setFullError(null);
          })
          .catch(fetchError => {
            setData(null);
            setFullError(fetchError);
          })
          .finally(() => {
            setLoading(false);
            resolve();
          });
      }),
    [cachedArgs, fetch],
  );

  const refetch = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (...newArgs: any[]) => {
      setDisableAutoFetch(true);
      if (newArgs.length > 0) { setCachedArgs(newArgs); }
      newFetch(...newArgs);
    },
    [newFetch],
  );

  // Call once on mount and don't call again (unless refetch is called)
  useEffect(() => {
    if (disableAutoFetch) { return; }
    newFetch();
  }, [disableAutoFetch, newFetch]);

  return {
    data: finalData,
    loading,
    refetch,
    error,
    fullError,
  };
};

export const useEnoviaGetByNameQuery = (name: string) => {
  const { getByName } = useEnovia();

  return useEnoviaQuery<Record<string, string>>(getByName, name);
};

export const useEnoviaWSFindAllQuery = (filters: Record<string, string>) => {
  const { wsFindAll } = useEnovia();

  return useEnoviaQuery<EnoviaWSFindAllResponse>(wsFindAll, filters);
};
