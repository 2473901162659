import { useMemo, useState } from "react";



interface UseToggleReturn {
  value: boolean;
  setValue: (value: boolean) => void;
  toggle: () => void;
}

export function useToggle(initialValue: boolean) {
  const [value, setValue] = useState<boolean>(initialValue);

  const useToggleReturn = useMemo<UseToggleReturn>(() => {
    const toggle = () => setValue(!value);

    return {
      value,
      setValue,
      toggle,
    };
  }, [value, setValue]);

  return useToggleReturn;
}
