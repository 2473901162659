export const GENERAL_FIBER_CATEGORY_ID = "83b9a101-4e48-4521-a549-fcf7136b771b";
export const FLUFF_FIBER_CATEGORY_ID = "23a772cb-1f68-44e5-b716-890e014b13c0";
export const INTERNAL_PART_CATEGORY_ID = "9c2409f2-0f37-4d3c-b886-e408485fbaf9";
export const EXTERNAL_PART_CATEGORY_ID = "8182798f-8cc2-4344-ba3a-6806c0aa6e82";


export enum PulpFiberCategoryType {
  General = "General Fiber",
  Fluff = "Fluff Fiber",
  InternalLabPart = "Internal Lab Part",
  ExternalLabPart = "External Lab Part",
}


