import {
  GetLocations,
  CreateLocation,
  DeleteLocation,
  UpdateLocation,
  GetLocation,
} from "~/graphql";
import type {
  LocationsQueryResultData,
  CreateLocationInput,
  CreateLocationMutationResultData,
  DeleteLocationMutationResultData,
  UpdateLocationMutationResultData,
  UpdateLocationInput,
  LocationQueryResultData,
} from "~/types";

import {
  makeCreateEntityMutationHook,
  makeGetEntitiesQueryHook,
  makeDeleteEntityMutationHook,
  makeUpdateEntityMutationHook,
  makeGetEntityByIdQueryHook,
} from "../utils";




export const useGetLocationQuery =
  makeGetEntityByIdQueryHook<LocationQueryResultData>(GetLocation);

export const useGetLocationsQuery =
  makeGetEntitiesQueryHook<LocationsQueryResultData>(GetLocations);

export const useCreateLocationMutation = makeCreateEntityMutationHook<
  CreateLocationMutationResultData,
  CreateLocationInput
>(CreateLocation);

export const useUpdateLocationMutation = makeUpdateEntityMutationHook<
  UpdateLocationMutationResultData,
  UpdateLocationInput
>(UpdateLocation);

export const useDeleteLocationMutation =
  makeDeleteEntityMutationHook<DeleteLocationMutationResultData>(
    DeleteLocation,
  );
