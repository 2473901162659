import type { ApolloQueryResult } from "@kv/apollo-client";
import { Button } from "antd";
import type { FC } from "react";
import { useCallback, useMemo } from "react";
import { useDeleteUploadedFile } from "~/hooks";
import type {
  BaseQueryVars,
  UploadedFile,
  UploadedFilesQueryResultData,
} from "~/types";
import { formatDateTime } from "~/utils";

import {
  DownloadButtonBar,
  DownloadFileCreatedAt,
  DownloadFileInfo,
} from "./StyledComponents";
import DownloadButton from "../DownloadButton";





interface FileInfoProps {
  file: UploadedFile;
  refetch: (
    variables?: Partial<BaseQueryVars> | undefined,
  ) => Promise<ApolloQueryResult<UploadedFilesQueryResultData>>;
}

const FileInfo: FC<FileInfoProps> = ({ file, refetch }) => {
  const [deleteFile] = useDeleteUploadedFile();

  const createdAt = useMemo(
    () => formatDateTime(new Date(file.createdAt + "Z")),
    [file],
  );

  const onDeleteClick = useCallback(() => {
    deleteFile(file.id).then(() => refetch());
  }, [deleteFile, file.id, refetch]);

  return (
    <DownloadFileInfo>
      <span>
        {file.fileName}{" "}
        <DownloadFileCreatedAt>({createdAt})</DownloadFileCreatedAt>
      </span>
      <DownloadButtonBar>
        <DownloadButton fileId={file.id}>
          <Button>Download</Button>
        </DownloadButton>
        <Button onClick={onDeleteClick}>Delete</Button>
      </DownloadButtonBar>
    </DownloadFileInfo>
  );
};

export interface UploadViewerProps {
  uploadedFiles?: UploadedFile[];
  refetch: (
    variables?: Partial<BaseQueryVars> | undefined,
  ) => Promise<ApolloQueryResult<UploadedFilesQueryResultData>>;
}

const UploadViewer: FC<UploadViewerProps> = ({ uploadedFiles, refetch }) => (
  <>
    {(uploadedFiles &&
      uploadedFiles.length > 0 &&
      uploadedFiles.map(file => (
        <FileInfo key={file.id} file={file} refetch={refetch} />
      ))) ||
      "No Uploads"}
  </>
);
export default UploadViewer;
