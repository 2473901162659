import {
  GetPulpFiberTdses,
  CreatePulpFiberTds,
  DeletePulpFiberTds,
  UpdatePulpFiberTds,
  GetPulpFiberTds,
} from "~/graphql";
import type {
  PulpFiberTdsesQueryResultData,
  CreatePulpFiberTdsInput,
  CreatePulpFiberTdsMutationResultData,
  DeletePulpFiberTdsMutationResultData,
  UpdatePulpFiberTdsMutationResultData,
  UpdatePulpFiberTdsInput,
  PulpFiberTdsQueryResultData,
} from "~/types";

import {
  makeCreateEntityMutationHook,
  makeGetEntitiesQueryHook,
  makeDeleteEntityMutationHook,
  makeUpdateEntityMutationHook,
  makeGetEntityByIdQueryHook,
} from "../utils";




export const useGetPulpFiberTdsQuery =
  makeGetEntityByIdQueryHook<PulpFiberTdsQueryResultData>(GetPulpFiberTds);

export const useGetPulpFiberTdsesQuery =
  makeGetEntitiesQueryHook<PulpFiberTdsesQueryResultData>(GetPulpFiberTdses);

export const useCreatePulpFiberTdsMutation = makeCreateEntityMutationHook<
  CreatePulpFiberTdsMutationResultData,
  CreatePulpFiberTdsInput
>(CreatePulpFiberTds);

export const useUpdatePulpFiberTdsMutation = makeUpdateEntityMutationHook<
  UpdatePulpFiberTdsMutationResultData,
  UpdatePulpFiberTdsInput
>(UpdatePulpFiberTds);

export const useDeletePulpFiberTdsMutation =
  makeDeleteEntityMutationHook<DeletePulpFiberTdsMutationResultData>(
    DeletePulpFiberTds,
  );
