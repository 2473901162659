import { gql } from "@kv/apollo-client";



export const FiberFingerprintTestValmetFs5BasicFragment = gql`
  fragment FiberFingerprintTestValmetFs5BasicFragment on FiberFingerprintTestValmetFs5 {
    id
    vesselsMeasuredCountPerMille
    lcN
    lcL
    lcW
    lcNIso
    lcLIso
    lcWIso
    width
    cwt
    coarseness
    curl
    kink
    kinkAngle
    fibrilation
    vesselsMeasuredCount
    vesselsCount
    vesselsMeasuredCountPerMille
  }
`;

const FiberFingerprintTestValmetFs5Fragment = gql`
  fragment FiberFingerprintTestValmetFs5Fragment on FiberFingerprintTestValmetFs5 {
    fiberLot{
      id
    }
    ...FiberFingerprintTestValmetFs5BasicFragment
  }
  ${FiberFingerprintTestValmetFs5BasicFragment}
`;

export const GetFiberFingerprintTestValmetFs5 = gql`
  query GetFiberFingerprintTestValmetFs5($id: ID!) {
    fiberFingerprintTestValmetFs5(id: $id) {
      ...FiberFingerprintTestValmetFs5Fragment
    }
  }
  ${FiberFingerprintTestValmetFs5Fragment}
`;

export const GetFiberFingerprintTestValmetFs5s = gql`
  query GetFiberFingerprintTestValmetFs5s(
    $filters: FiberFingerprintTestValmetFs5Filter
    $sort: [String]
    $pagination: PaginationInput
  ) {
    fiberFingerprintTestValmetFs5s(filters: $filters, sort: $sort, pagination: $pagination) {
      ...FiberFingerprintTestValmetFs5Fragment
    }
    fiberFingerprintTestValmetFs5Count(filters: $filters)
  }
  ${FiberFingerprintTestValmetFs5Fragment}
`;

export const CreateFiberFingerprintTestValmetFs5 = gql`
  mutation CreateFiberFingerprintTestValmetFs5($input: FiberFingerprintTestValmetFs5Input!) {
    createFiberFingerprintTestValmetFs5(input: $input) {
      ...FiberFingerprintTestValmetFs5Fragment
    }
  }
  ${FiberFingerprintTestValmetFs5Fragment}
`;

export const UpdateFiberFingerprintTestValmetFs5 = gql`
  mutation UpdateFiberFingerprintTestValmetFs5($id: ID!, $input: FiberFingerprintTestValmetFs5Input!) {
    updateFiberFingerprintTestValmetFs5(id: $id, input: $input) {
      ...FiberFingerprintTestValmetFs5Fragment
    }
  }
  ${FiberFingerprintTestValmetFs5Fragment}
`;

export const DeleteFiberFingerprintTestValmetFs5 = gql`
  mutation DeleteFiberFingerprintTestValmetFs5($id: ID!) {
    deleteFiberFingerprintTestValmetFs5(id: $id) {
      id
    }
  }
`;
