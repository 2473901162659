import { gql } from "@kv/apollo-client";



export const ResinFilmFragment = gql`
  fragment ResinFilmFragment on ResinFilm {
    id
    resinId
    resin {
      id
      supplier
      tradeName
      resinType
    }
    filmNum
    createdAt
    resinDataPoints {
      id
      category
      name
      testMethod
      unit
      strValue
      value
    }
  }
`;

export const GetResinFilm = gql`
  query GetResinFilm($id: ID!) {
    resinFilm(id: $id) {
      ...ResinFilmFragment
    }
  }
  ${ResinFilmFragment}
`;

export const GetResinFilms = gql`
  query GetResinFilms($filters: ResinFilmFilter, $sort: [String]) {
    resinFilms(filters: $filters, sort: $sort) {
      ...ResinFilmFragment
    }
  }
  ${ResinFilmFragment}
`;

export const CreateResinFilm = gql`
  mutation CreateResinFilm($input: ResinFilmInput!) {
    createResinFilm(input: $input) {
      ...ResinFilmFragment
    }
  }
  ${ResinFilmFragment}
`;

export const UpdateResinFilm = gql`
  mutation UpdateResinFilm($id: ID!, $input: ResinFilmInput!) {
    updateResinFilm(id: $id, input: $input) {
      ...ResinFilmFragment
    }
  }
  ${ResinFilmFragment}
`;

export const DeleteResinFilm = gql`
  mutation DeleteResinFilm($id: ID!) {
    deleteResinFilm(id: $id) {
      id
    }
  }
`;
