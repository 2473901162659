import { gql } from "@kv/apollo-client";



export const CommentFragment = gql`
  fragment CommentFragment on Comment {
    id
    message
    createdAt
    updatedAt
    user {
      firstName
      lastName
    }
  }
`;

export const GetComment = gql`
  query GetComment($id: ID!) {
    comment(id: $id) {
      ...CommentFragment
    }
  }
  ${CommentFragment}
`;

export const GetComments = gql`
  query GetComments(
    $filters: CommentFilter
    $sort: [String]
    $pagination: PaginationInput
  ) {
    comments(filters: $filters, sort: $sort, pagination: $pagination) {
      ...CommentFragment
    }
  }
  ${CommentFragment}
`;

export const CreateComment = gql`
  mutation CreateComment($input: CommentInput!) {
    createComment(input: $input) {
      error
    }
  }
`;
