import {
  GetResinDataPoints,
  CreateResinDataPoint,
  DeleteResinDataPoint,
  UpdateResinDataPoint,
} from "~/graphql";
import type {
  ResinDataPointsQueryResultData,
  CreateResinDataPointInput,
  CreateResinDataPointMutationResultData,
  DeleteResinDataPointMutationResultData,
  UpdateResinDataPointMutationResultData,
  UpdateResinDataPointInput,
  ResinDataPoint,
} from "~/types";

import { makeCreateOrUpdate } from "../../createOrUpdate";
import {
  makeCreateEntityMutationHook,
  makeGetEntitiesQueryHook,
  makeDeleteEntityMutationHook,
  makeUpdateEntityMutationHook,
} from "../utils";





export const useGetResinDataPointsQuery =
  makeGetEntitiesQueryHook<ResinDataPointsQueryResultData>(GetResinDataPoints);

export const useCreateResinDataPointMutation = makeCreateEntityMutationHook<
  CreateResinDataPointMutationResultData,
  CreateResinDataPointInput
>(CreateResinDataPoint);

export const useUpdateResinDataPointMutation = makeUpdateEntityMutationHook<
  UpdateResinDataPointMutationResultData,
  UpdateResinDataPointInput
>(UpdateResinDataPoint);

export const useDeleteResinDataPointMutation =
  makeDeleteEntityMutationHook<DeleteResinDataPointMutationResultData>(
    DeleteResinDataPoint,
  );

export const useResinDataPointCreateOrUpdate = makeCreateOrUpdate<
  CreateResinDataPointInput,
  CreateResinDataPointMutationResultData,
  ResinDataPointsQueryResultData,
  "resinDataPoints",
  UpdateResinDataPointMutationResultData,
  ResinDataPoint
>(
  useCreateResinDataPointMutation,
  useGetResinDataPointsQuery,
  useUpdateResinDataPointMutation,
);
