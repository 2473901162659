import {
  GetFiberPartTestData,
  GetFiberPartTestDatas,
  CreateFiberPartTestData,
  UpdateFiberPartTestData,
  DeleteFiberPartTestData,
} from "~/graphql";
import type {
  CreateFiberPartTestDataInput,
  CreateFiberPartTestDataMutationResultData,
  DeleteFiberPartTestDataMutationResultData,
  FiberPartTestDataQueryResultData,
  FiberPartTestDatasQueryResultData,
  UpdateFiberPartTestDataInput,
  UpdateFiberPartTestDataMutationResultData,
} from "~/types";

import {
  makeCreateEntityMutationHook,
  makeDeleteEntityMutationHook,
  makeGetEntitiesQueryHook,
  makeGetEntityByIdQueryHook,
  makeUpdateEntityMutationHook,
} from "../utils";





export const useGetFiberPartTestDataQuery =
  makeGetEntityByIdQueryHook<FiberPartTestDataQueryResultData>(GetFiberPartTestData);

export const useGetFiberPartTestDatasQuery =
  makeGetEntitiesQueryHook<FiberPartTestDatasQueryResultData>(GetFiberPartTestDatas);

export const useCreateFiberPartTestDataMutation = makeCreateEntityMutationHook<
  CreateFiberPartTestDataMutationResultData,
  CreateFiberPartTestDataInput
>(CreateFiberPartTestData);

export const useUpdateFiberPartTestDataMutation = makeUpdateEntityMutationHook<
  UpdateFiberPartTestDataMutationResultData,
  UpdateFiberPartTestDataInput
>(UpdateFiberPartTestData);

export const useDeleteFiberPartTestDataMutation =
  makeDeleteEntityMutationHook<DeleteFiberPartTestDataMutationResultData>(
    DeleteFiberPartTestData,
  );
