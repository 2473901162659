import { gql } from "@kv/apollo-client";



export const ExecutionDataPointFragment = gql`
  fragment ExecutionDataPointFragment on ExecutionDataPoint {
    id
    category
    name
    strValue
  }
`;

export const GetExecutionDataPoint = gql`
  query GetExecutionDataPoint($id: ID!) {
    executionDataPoint(id: $id) {
      ...ExecutionDataPointFragment
    }
  }
  ${ExecutionDataPointFragment}
`;

export const GetExecutionDataPoints = gql`
  query GetExecutionDataPoints(
    $filters: ExecutionDataPointFilter
    $sort: [String]
  ) {
    executionDataPoints(filters: $filters, sort: $sort) {
      ...ExecutionDataPointFragment
    }
  }
  ${ExecutionDataPointFragment}
`;

export const CreateExecutionDataPoint = gql`
  mutation CreateExecutionDataPoint($input: ExecutionDataPointInput!) {
    createExecutionDataPoint(input: $input) {
      ...ExecutionDataPointFragment
    }
  }
  ${ExecutionDataPointFragment}
`;

export const UpdateExecutionDataPoint = gql`
  mutation UpdateExecutionDataPoint(
    $id: ID!
    $input: ExecutionDataPointInput!
  ) {
    updateExecutionDataPoint(id: $id, input: $input) {
      ...ExecutionDataPointFragment
    }
  }
  ${ExecutionDataPointFragment}
`;

export const DeleteExecutionDataPoint = gql`
  mutation DeleteExecutionDataPoint($id: ID!) {
    deleteExecutionDataPoint(id: $id) {
      id
    }
  }
`;
