import { useQuery } from "@kv/apollo-client";
import { GetAuthTokens, UserLogin, UserLogout } from "~/graphql/auth";
import type {
  AuthTokensQueryResult,
  UserLoginInput,
  UserLoginMutationResult,
  UserLogoutMutationResult,
} from "~/types";

import { makeStandardMutationHook } from "../utils/mutations";




export const useGetAuthTokensQuery = () =>
  useQuery<AuthTokensQueryResult>(GetAuthTokens);

export const useUserLoginMutation = makeStandardMutationHook<
  UserLoginMutationResult,
  UserLoginInput
>(UserLogin);

export const useUserLogoutMutation =
  makeStandardMutationHook<UserLogoutMutationResult>(UserLogout);
