import { gql } from "@kv/apollo-client";

import { FiberGpsSoiClassificationBasicFragment } from "./fiberGpsSoiClassification";
import { FiberPartBlendedCompositionMaterialFragment } from "./fiberPartBlendedCompositionMaterial";



const PulpFiberFragment = gql`
  fragment PulpFiberFragment on PulpFiber {
    id
    mcpNum
    supplier
    tradeName
    fiberType
    partType
    description
    originCountry
    grade
    coated
    site
    marketedUse
    isTemporary
    businessUnit
    cepiFoodContactCompliance
    fdaFoodDrugContactCompliance
    biocideAntiMicrobialPreservationCompliance
    toxinTesting
    forestStewardshipCouncil
    sustainableForestryInitiative
    programmeForTheEndorsementOfForestCertification
    categoryId
    archived
    createdAt
    updatedAt
    category {
      name
    }
    tds {
      id
      pulpFiberId
      biomass
      biomassDetail
      bleachingMethod
      pulpingMethod
      grade
      pulpCharacteristics
      recyclability
      absorptionCapacity
      absorptionTime
      airPermeability
      airResistance
      apparentDensity
      ashContentWeightPercentage
      brightness
      bulk
      burstMullen
      coarseness
      cobb60
      density
      drainageResistance
      elongationCd
      elongationDryCd
      elongationDryMd
      elongationMd
      elongationWetCd
      elongationWetMd
      extractives
      fiberLength
      fiberPopulation
      fiberWidth
      freeness
      grammage
      lcaCarbonFootprint
      lightScatteringCoefficient
      moisture
      nominalBasisWeight
      permeabilityGurleyPorosity
      permeabilitySheffieldBottom
      permeabilitySheffieldTop
      receptivityCobb120Bottom
      receptivityCobb120Top
      refiningPfi
      shreddingEnergy
      stiffnessTaberCd
      stiffnessTaberMd
      teaCd
      teaMd
      teaIndexCd
      teaIndexMd
      tearIndex
      tearIndexCd
      tearIndexMd
      tearMd
      tearCd
      tearStrengthCd
      tearStrengthMd
      tensileIndex
      tensileIndexCd
      tensileIndexMd
      tensileIndexDryCd
      tensileIndexDryMd
      tensileIndexWetCd
      tensileIndexWetMd
      tensileStiffnessIndex
      tensileStrengthCd
      tensileStrengthMd
      tensileStrengthDryCd
      tensileStrengthDryMd
      tensileStrengthWetCd
      tensileStrengthWetMd
      thickness
      treated
      viscosity
      zdt
    }
    regions {
      id
      name
    }
    comments {
      id
      message
      user {
        firstName
        lastName
      }
      createdAt
      updatedAt
    }
    fiberLots {
      kajaaniTest {
        curl
      }
      valmetFs5Test {
        curl
        kink
      }
    }
  }
`;

export const GetPulpFiber = gql`
  query GetPulpFiber($id: ID!) {
    pulpFiber(id: $id) {
      ...PulpFiberFragment
    }
  }
  ${PulpFiberFragment}
`;

export const GetPulpFibers = gql`
  query GetPulpFibers(
    $filters: PulpFiberFilter
    $sort: [String]
    $pagination: PaginationInput
  ) {
    pulpFibers(filters: $filters, sort: $sort, pagination: $pagination) {
      ...PulpFiberFragment
    }
    pulpFibersCount(filters: $filters)
  }
  ${PulpFiberFragment}
`;

const PulpFiberLotFragment = gql`
  fragment PulpFiberLotFragment on FiberLot {
    id
    lotNumber
    createdAt
    updatedAt
    featureEnableFingerprintTesting
    featureEnableChemicalTesting
  }
`;

const PulpFiberLotWithTestsFragment = gql`
  fragment PulpFiberLotWithTestsFragment on FiberLot {
    ...PulpFiberLotFragment
    generalChemTest {
      moisture
      ash
      fines
      dcmExtract
      onePctSodiumHydroxide
      anionicTrash
      cationicDemand
      carboxylNumber
      phStdWater
      phDeionizedWater
      celluloseSolutionViscosity
      alphaCellulose
      kappaNumberUnbleached
      cleanlinessRecycledFiber
    }
    chemicalTestIons {
      extracted
      sodium
      magnesium
      calcium
      iron
      silicon
      aluminum
      chloride
      phosphate
      sulphate
      nitrate
    }
    kajaaniTest {
      lN
      lL
      fibersPerGram
      width
      wall
      coarseness
      curl
      fibrilation
    }
    valmetFs5Test {
      lcN
      lcL
      lcW
      lcNIso
      lcLIso
      lcWIso
      width
      cwt
      coarseness
      curl
      kink
      kinkAngle
      fibrilation
      vesselsMeasuredCount
      vesselsCount
      vesselsMeasuredCountPerMille
    }
    gpsSoiClassification {
      soiClass
      soi4TertPentylPhenol
      galabBpa
      galabIsononylPhenol
    }
  }
  ${PulpFiberLotFragment}
`;

export const GetPulpFibersForDashboard = gql`
  query GetPulpFibers(
    $filters: PulpFiberFilter
    $sort: [String]
    $pagination: PaginationInput
  ) {
    pulpFibers(filters: $filters, sort: $sort, pagination: $pagination) {
      ...PulpFiberFragment
      fiberLots {
        ...PulpFiberLotWithTestsFragment
      }
      newestFiberLot {
        ...PulpFiberLotFragment
      }
      newestFiberPart {
        id
        pulpFiber {
          id
        }
        partNumber
        isWet
        isRefiningTesting
      }
    }
    pulpFibersCount(filters: $filters)
  }
  ${PulpFiberFragment}
  ${PulpFiberLotFragment}
  ${PulpFiberLotWithTestsFragment}
`;

export const PulpFiberForPartsDumpFragment = gql`
  fragment PulpFiberForPartsDumpFragment on PulpFiber {
    id
    fiberType
    partType
    tds {
      nominalBasisWeight
      thickness
    }
    fiberParts {
      id
      partNumber
      isWet
      fiberPartTestData {
        id
        temperature
        pressure
        processingSeconds
        tensileStrengthMd
        tensileStrengthCd
        tensileModulus
        shortSpanCompressionModulus
        threePtBendingStiffnessModulus
        smoothness
        overallRecyclability
      }
      blendedComposition{
        totalBasisWeight
        materials{
          ...FiberPartBlendedCompositionMaterialFragment
        }
      }
      gpsSoiClassification{
        ...FiberGpsSoiClassificationBasicFragment
      }
    }

  }
  ${FiberPartBlendedCompositionMaterialFragment}
  ${FiberGpsSoiClassificationBasicFragment}
`;

export const GetPulpFiberForPartsDump = gql`
  query GetPulpFiber($id: ID!) {
    pulpFiber(id: $id) {
      ...PulpFiberForPartsDumpFragment
    }
  }
  ${PulpFiberForPartsDumpFragment}
`;

export const CreatePulpFiber = gql`
  mutation CreatePulpFiber($input: PulpFiberInput!) {
    createPulpFiber(input: $input) {
      ...PulpFiberFragment
    }
  }
  ${PulpFiberFragment}
`;

export const UpdatePulpFiber = gql`
  mutation UpdatePulpFiber($id: ID!, $input: PulpFiberInput!) {
    updatePulpFiber(id: $id, input: $input) {
      ...PulpFiberFragment
    }
  }
  ${PulpFiberFragment}
`;

export const DeletePulpFiber = gql`
  mutation DeletePulpFiber($id: ID!) {
    deletePulpFiber(id: $id) {
      id
    }
  }
`;
